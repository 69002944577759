/* eslint-disable jsx-a11y/no-onchange */
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DayEnum, DayKrEnum } from '../../../types/reservationType';

// import { getHolidayForMonth } from '@/utils/getHolidayUtils';

const VetFluxDatePickerOld = ({
  handleDateData,
  handleDatePickerCancel = undefined,
  datePickRangeOptions,
  className = 'top-auto left-auto',
  defaultTime = dayjs().valueOf(),
  maxYearRange = null,
  isFirstClick = false,
  availableDays = undefined,
}) => {
  dayjs.extend(toObject);
  dayjs.extend(calendar);
  dayjs.extend(weekday);
  dayjs.extend(duration);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);
  dayjs.tz.setDefault('Asia/Seoul');

  const today = dayjs(defaultTime).toObject();
  const [selectedDate, setSelectedDate] = useState(today);
  const [year, setYear] = useState(today.years);
  const [month, setMonth] = useState(today.months);
  const [holidayForMonthList, setHolidayForMonthList] = useState([]);
  const [disableDayIndexArray, setDisableDayIndexArray] = useState<number[]>(
    [],
  );

  const trArray = new Array(6).fill(1).map((column, columnIndex) =>
    new Array(7).fill(1).map((row, rowIndex) =>
      dayjs(`${selectedDate.years}-${selectedDate.months + 1}-01`)
        .startOf('week')
        .add(row + rowIndex + columnIndex * 7 - 1, 'day')
        .toObject(),
    ),
  );
  const [calendarArray, setCalendarArray] = useState(trArray);
  let isDateBeforeCheck = false;
  let disabledCurrentYear: string;
  let disabledCurrentMonth: string;
  let disabledCurrentDate: string;
  let disabledSumResultDate: number;

  const handleCellClick: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    const { targetYear, targetMonth, targetDay } = event.currentTarget.dataset;
    const numberMonth = Number(targetMonth);
    const monthZero = numberMonth + 1 < 10 ? '0' : '';
    const dayZero = Number(targetDay) < 10 ? '0' : '';
    setSelectedDate(
      dayjs(`${targetYear}-${numberMonth + 1}-${targetDay}`).toObject(),
    );
    handleDateData(
      dayjs(
        `${targetYear}.${monthZero}${numberMonth + 1}.${dayZero}${targetDay}`,
        'YYYY.MM.DD',
      ).valueOf(),
    );
  };

  const handleMonthButton: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    const result = event.currentTarget.value;
    const currentDate = dayjs(
      `${selectedDate.years}-${selectedDate.months + 1}-${selectedDate.date}`,
    );
    const currentYear = dayjs().year();

    if (result === 'next') {
      const nextMonth = currentDate.add(1, 'month').startOf('month');
      // 최대 년도를 초과하는 경우 return
      if (maxYearRange && nextMonth.year() > currentYear + maxYearRange) {
        alert('최대 1년 이후까지 선택 가능합니다.');
        return;
      }

      const newArray = new Array(6).fill(1).map((column, columnIndex) =>
        new Array(7).fill(1).map((row, rowIndex) =>
          nextMonth
            .startOf('week')
            .add(row + rowIndex + columnIndex * 7 - 1, 'day')
            .toObject(),
        ),
      );
      setCalendarArray(newArray);
      setSelectedDate(nextMonth.toObject());
      if (month !== 11) {
        setMonth(month + 1);
      } else {
        setYear(year + 1);
        setMonth(0);
      }
    }
    if (result === 'previous') {
      const previousMonth = currentDate.subtract(1, 'month').startOf('month');
      const newArray = new Array(6).fill(1).map((column, columnIndex) =>
        new Array(7).fill(1).map((row, rowIndex) =>
          previousMonth
            .startOf('week')
            .add(row + rowIndex + columnIndex * 7 - 1, 'day')
            .toObject(),
        ),
      );
      setCalendarArray(newArray);
      setSelectedDate(previousMonth.toObject());
      if (month !== 0) {
        setMonth(month - 1);
      } else {
        setYear(year - 1);
        setMonth(11);
      }
    }
  };
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(
    (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleDatePickerCancel && handleDatePickerCancel();
      }
    },
    [handleDatePickerCancel],
  );

  const afterDateDisabledChecker = (
    year: string,
    month: string,
    date: string,
  ) => {
    disabledSumResultDate = parseInt(year + month + date);

    if (dayjs(disabledSumResultDate.toString()).valueOf() < dayjs().valueOf()) {
      isDateBeforeCheck = true;
    } else {
      isDateBeforeCheck = false;
    }
  };

  const thisWeekDateDisabledChecker = (
    year: string,
    month: string,
    date: string,
  ) => {
    disabledSumResultDate = parseInt(year + month + date);

    const now = dayjs();
    const startOfWeek = now.startOf('week');
    const endOfWeek = now.endOf('week');

    if (
      dayjs(`${year}.${month}.${date}`).valueOf() > dayjs().valueOf() &&
      dayjs(`${year}.${month}.${date}`).isAfter(startOfWeek) &&
      dayjs(`${year}.${month}.${date}`).isBefore(endOfWeek)
    ) {
      isDateBeforeCheck = false;
    } else {
      isDateBeforeCheck = true;
    }
  };

  const beforeDateDisabledChecker = (
    year: string,
    month: string,
    date: string,
  ) => {
    disabledSumResultDate = parseInt(year + month + date);
    isDateBeforeCheck =
      dayjs(disabledSumResultDate.toString()).valueOf() >
      dayjs().subtract(0, 'day').valueOf();
  };

  const isHoliday = (year, month, date) => {
    const filteredHoliday = holidayForMonthList.filter((holiday) => {
      return dayjs(holiday.dateString).isSame(`${year}-${month}-${date}`);
    });
    return filteredHoliday.length > 0;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (defaultTime) {
      setSelectedDate(dayjs(defaultTime).toObject());
      setYear(dayjs(defaultTime).toObject().years);
      setMonth(dayjs(defaultTime).toObject().months);
      setCalendarArray(
        new Array(6).fill(1).map((column, columnIndex) =>
          new Array(7).fill(1).map((row, rowIndex) =>
            dayjs(
              `${dayjs(defaultTime).toObject().years}-${
                dayjs(defaultTime).toObject().months + 1
              }-01`,
            )
              .startOf('week')
              .add(row + rowIndex + columnIndex * 7 - 1, 'day')
              .toObject(),
          ),
        ),
      );
    } else {
      setSelectedDate(dayjs().toObject());
      setYear(dayjs().toObject().years);
      setMonth(dayjs().toObject().months);
    }
  }, [defaultTime]);

  useEffect(() => {
    if (!availableDays) {
      setDisableDayIndexArray([]);
      return;
    }

    const dayIdxArray = [];
    Object.keys(DayEnum).forEach((day, index) => {
      if (!availableDays.includes(DayEnum[day])) {
        dayIdxArray.push(index);
      }
    });
    setDisableDayIndexArray(dayIdxArray);
  }, [availableDays]);

  return (
    <div className='relative flex justify-start '>
      <div className={'bg-white ' + className} ref={ref}>
        <div className='flex justify-between mt-1'>
          <button className='pl-5' onClick={handleMonthButton} value='previous'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='9.771'
              height='14.454'
              viewBox='0 0 9.771 14.454'
            >
              <g>
                <path
                  fill='none'
                  stroke='#808495'
                  strokeWidth='2px'
                  d='M1811.182 4362.342l-7.567 6.731 7.567 6.2'
                  transform='translate(-251.96 -2179.753) translate(-1550.116 -2181.842)'
                />
              </g>
            </svg>
          </button>
          <p className='text-base text-neutral-dark font-bold'>
            {year + '.' + (month + 1)}
          </p>
          <button className='pr-5' onClick={handleMonthButton} value='next'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='9.775'
              height='14.454'
              viewBox='0 0 9.775 14.454'
            >
              <g>
                <path
                  fill='none'
                  stroke='#4d4f5c'
                  strokeWidth='2px'
                  d='M1803.616 4362.342l7.57 6.731-7.57 6.2'
                  transform='translate(-253.402 -2179.753) translate(-1549.549 -2181.842)'
                />
              </g>
            </svg>
          </button>
        </div>
        <table className='mt-6 date-picker w-full'>
          <thead>
            <tr>
              {Object.keys(DayKrEnum).map((day, index) => (
                <th key={`${day}-${index}`}>
                  <div className='flex justify-center w-full'>
                    <p className='text-xs font-semibold text-center dark:text-gray-400 text-neutral-normal'>
                      {DayKrEnum[day]}
                    </p>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {calendarArray?.map((row) => (
              <tr key={Math.random()}>
                {row.map((cellDate, index) => {
                  /* 과거 날짜 선택 금지 규정 추가 */
                  switch (datePickRangeOptions) {
                    case 'normal':
                      break;
                    case 'before':
                      disabledCurrentYear = cellDate.years.toString();
                      disabledCurrentMonth =
                        (cellDate.months + 1).toString().length === 1
                          ? '0' + (cellDate.months + 1).toString()
                          : (cellDate.months + 1).toString();
                      disabledCurrentDate =
                        cellDate.date.toString().length === 1
                          ? '0' + cellDate.date.toString()
                          : cellDate.date.toString();
                      beforeDateDisabledChecker(
                        disabledCurrentYear,
                        disabledCurrentMonth,
                        disabledCurrentDate,
                      );
                      break;
                    case 'after':
                      disabledCurrentYear = cellDate.years.toString();
                      disabledCurrentMonth =
                        (cellDate.months + 1).toString().length === 1
                          ? '0' + (cellDate.months + 1).toString()
                          : (cellDate.months + 1).toString();
                      disabledCurrentDate =
                        cellDate.date.toString().length === 1
                          ? '0' + cellDate.date.toString()
                          : cellDate.date.toString();
                      afterDateDisabledChecker(
                        disabledCurrentYear,
                        disabledCurrentMonth,
                        disabledCurrentDate,
                      );
                      break;
                    case 'week':
                      disabledCurrentYear = cellDate.years.toString();
                      disabledCurrentMonth =
                        (cellDate.months + 1).toString().length === 1
                          ? '0' + (cellDate.months + 1).toString()
                          : (cellDate.months + 1).toString();
                      disabledCurrentDate =
                        cellDate.date.toString().length === 1
                          ? '0' + cellDate.date.toString()
                          : cellDate.date.toString();
                      thisWeekDateDisabledChecker(
                        disabledCurrentYear,
                        disabledCurrentMonth,
                        disabledCurrentDate,
                      );
                      break;
                  }
                  /* 과거 날짜 선택 금지 End */
                  return (
                    <td
                      key={`${cellDate.years}${cellDate.months}${cellDate.date}`}
                      className={'relative'}
                    >
                      {datePickRangeOptions === 'week' && isDateBeforeCheck && (
                        <button
                          onClick={() => {
                            alert(
                              '데모버전에서는 일주일 이내의 예약만을 제공하고 있습니다.\n2주간의 무료 체험판을 신청해주세요.',
                            );
                          }}
                          className='absolute top-0 left-0 w-full h-full'
                        ></button>
                      )}
                      <div
                        className={'w-full flex justify-center items-center'}
                      >
                        <button
                          onClick={handleCellClick}
                          data-target-year={cellDate.years}
                          data-target-month={cellDate.months}
                          data-target-day={cellDate.date}
                          disabled={
                            isDateBeforeCheck ||
                            disableDayIndexArray.includes(index)
                          }
                          className={
                            'transition group cursor-pointer rounded-full flex justify-center text-neutral-dark items-center w-9 h-9 hover:bg-primary disabled:text-secondary-dark disabled:cursor-not-allowed disabled:hover:bg-none ' +
                            (dayjs(
                              `${selectedDate.years}-${
                                selectedDate.months + 1
                              }-${selectedDate.date}`,
                            ).isSame(
                              dayjs(
                                `${cellDate.years}-${cellDate.months + 1}-${
                                  cellDate.date
                                }`,
                              ),
                            ) && !isFirstClick
                              ? 'bg-primary '
                              : '')
                          }
                        >
                          <p
                            className={
                              'dark:text-gray-100 group-hover:text-white text-sm font-semibold ' +
                              (dayjs(
                                `${selectedDate.years}-${
                                  selectedDate.months + 1
                                }-${selectedDate.date}`,
                              ).isSame(
                                dayjs(
                                  `${cellDate.years}-${cellDate.months + 1}-${
                                    cellDate.date
                                  }`,
                                ),
                              ) && !isFirstClick
                                ? 'text-white '
                                : selectedDate.months === cellDate.months
                                ? isHoliday(
                                    cellDate.years,
                                    cellDate.months + 1,
                                    cellDate.date,
                                  ) || index === 0
                                  ? 'text-red-500'
                                  : 'text-neutral-dark'
                                : 'text-secondary-dark')
                            }
                          >
                            {cellDate.date}
                          </p>
                        </button>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VetFluxDatePickerOld;
