import { captureException } from '@sentry/core';
import firebase from 'firebase';
import _ from 'lodash';
import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Lottie from 'react-lottie';
import TextareaAutosize from 'react-textarea-autosize';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  globalReducers,
  selectGlobalConfig,
  selectGlobalInfo,
} from 'src/features/@globalSlices/globalSlice';
import { isA2HSBrowser } from 'src/features/util/userAgentChecker';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import LoadingLottie from '../../../lottie/loading.json';
import {
  addFileMessage,
  addFileMessageWithRef,
  getMessageRef,
} from '../service/ChattingService';
import {
  checkSupportExtension,
  compressImage,
  fileUpload,
  getFileExtension,
} from '../service/FileUploadService';
import {
  selectContentsMessageTemplates,
  selectIsShownTextAreaPopup,
  selectMessages,
  selectWaitingMessage,
  setShowTextareaPopup,
} from '../slices/chattingSlice';
import ChattingChipInput from './ChattingChipInput';
import { MessageDTO } from './messageBox/MessageDTO';

const ChattingTextBox = ({ addChatMessage, onOpenBlockGuideModal }) => {
  const hiddenInputRef = useRef<HTMLInputElement>();
  const [currentChat, setCurrentChat] = useState('');
  const globalConfig = useAppSelector(selectGlobalConfig);
  const globalInfo = useAppSelector(selectGlobalInfo);
  const isShownTextAreaPopup = useAppSelector(selectIsShownTextAreaPopup);
  const messages = useAppSelector(selectMessages);
  const waitMessage = useAppSelector(selectWaitingMessage);
  const textAreaPopupButton = useRef(null);
  const inputFileUpload = useRef(null);
  const [task, setTask] = useState<firebase.storage.UploadTask>(null);
  const [taskTitle, setTaskTitle] = useState('');
  const [taskProgress, setTaskProgress] = useState('');
  const textAreaRef = useRef(null);
  const actionSheetRef = useRef(null);

  const lottieOptions = {
    animationData: LoadingLottie,
    loop: true,
    autoplay: true,
    rendererSettings: {
      className: 'add-class', // svg에 적용
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const dispatch = useAppDispatch();
  const handleChange = (event: { target: { value: string } }) => {
    setCurrentChat(event.target.value);
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (textAreaRef) {
      textAreaRef.current.focus();
    }
    if (currentChat.replaceAll(' ', '')) {
      setCurrentChat('');
      hiddenInputRef.current.focus();
      addChatMessage(currentChat);
    }
    // else {
    //   alert('입력한 메시지가 없습니다.');
    // }
  };

  const togglePopupMenu = useCallback(
    (event) => {
      event.preventDefault();
      if (isA2HSBrowser()) {
        dispatch(setShowTextareaPopup(!isShownTextAreaPopup));
      } else {
        onOpenBlockGuideModal();
      }
    },
    [isShownTextAreaPopup],
  );

  const handleUploadVideo: MouseEventHandler<
    HTMLLIElement | HTMLButtonElement
  > = (event) => {
    event.preventDefault();
    inputFileUpload.current.value = '';
    inputFileUpload.current.setAttribute('accept', 'video/*');
    inputFileUpload.current.click();
  };

  const handleUploadImage: MouseEventHandler<
    HTMLLIElement | HTMLButtonElement
  > = (event) => {
    event.preventDefault();
    inputFileUpload.current.value = '';
    inputFileUpload.current.setAttribute('accept', '.png, .jpeg');
    inputFileUpload.current.click();
  };

  const handleUploadFile: MouseEventHandler<HTMLLIElement | HTMLButtonElement> =
    (event) => {
      event.preventDefault();
      inputFileUpload.current.value = '';
      inputFileUpload.current.setAttribute('accept', '*');
      inputFileUpload.current.click();
    };

  const imageUpload = async (file: File) => {
    console.time('imageUpload Start');
    if (task) handleClickCancelTask();

    const compressedFile = await compressImage(
      inputFileUpload.current.files[0],
    ).catch((error) => {
      alert('지원하지 않는 이미지 파일입니다.');
      captureException(error);
    });
    if (!compressedFile) return;

    const uploadTask = fileUpload(
      `hospital/${globalInfo.hospitalKey}/chat/images/${new Date().valueOf()}_${
        file.name
      }`,
      compressedFile,
      (isSuccess, downloadUrl) => {
        if (!isSuccess) {
          alert('이미지 업로드에 실패하였습니다. 다시 시도해 주세요.');
          setTask(null);
          setTaskTitle(``);
          return;
        }
        const messageDto = new MessageDTO({
          writer: globalInfo.familyInfo.name,
          writerType: 'nok',
          text: '사진',
          images: [
            {
              extension: getFileExtension(file),
              link: downloadUrl,
              name: file.name,
            },
          ],
        });
        addFileMessage(globalInfo, messageDto, messages, waitMessage)
          .then(() => {
            setTask(null);
            setTaskTitle(``);
          })
          .catch((error) => {
            captureException(error);
            setTask(null);
            setTaskTitle(``);
          });
      },
      (progress) => {
        setTaskProgress(progress);
      },
    );
    setTask(uploadTask);
    setTaskTitle(`이미지 업로드`);
  };

  const videoUpload = async (file: File) => {
    if (task) handleClickCancelTask();
    const messageRef = getMessageRef(
      globalInfo.hospitalKey,
      globalInfo.chatKey,
    );
    const metaData = {
      customMetadata: {
        chatKey: globalInfo.chatKey,
        hospitalKey: globalInfo.hospitalKey,
        fileName: file.name,
        messageKey: messageRef.id,
      },
    };
    try {
      const uploadTask = fileUpload(
        `tmp_video/${new Date().valueOf()}_${file.name}`,
        file,
        async (isSuccess, videoUrl) => {
          if (!isSuccess) {
            alert('동영상 업로드에 실패하였습니다. 다시 시도해 주세요.');
            setTask(null);
            setTaskTitle(``);
            return;
          }
          const messageDto = new MessageDTO({
            writer: globalInfo.familyInfo.name,
            writerType: 'nok',
            text: '동영상',
            videos: [
              {
                extension: getFileExtension(file),
                link: videoUrl,
                name: file.name,
                thumbnail: '',
              },
            ],
          });
          await addFileMessageWithRef(
            globalInfo,
            messageRef,
            messageDto,
            messages,
            waitMessage,
          );
          setTask(null);
          setTaskTitle(``);
        },
        (progress) => {
          setTaskProgress(progress);
        },
        metaData,
      );
      setTask(uploadTask);
      setTaskTitle(`비디오 업로드`);
    } catch (e) {
      alert('동영상 업로드에 실패하였습니다. 다시 시도해 주세요.');
      setTaskProgress('');
      captureException(e);
    }
  };

  const filesUpload = async (file: File) => {
    console.time('imageUpload Start');
    if (task) handleClickCancelTask();
    const uploadTask = fileUpload(
      `hospital/${globalInfo.hospitalKey}/files/${new Date().valueOf()}_${
        file.name
      }`,
      file,
      (isSuccess, downloadUrl) => {
        if (!isSuccess) {
          alert('파일 업로드에 실패하였습니다. 다시 시도해 주세요.');
          setTask(null);
          setTaskTitle(``);
          return;
        }
        const messageDto = new MessageDTO({
          writer: globalInfo.familyInfo.name,
          writerType: 'nok',
          text: '파일',
          files: [
            {
              extension: getFileExtension(file),
              link: downloadUrl,
              name: file.name,
            },
          ],
        });
        addFileMessage(globalInfo, messageDto, messages, waitMessage)
          .then(() => {
            setTask(null);
            setTaskTitle(``);
          })
          .catch((error) => {
            captureException(error);
            setTask(null);
            setTaskTitle(``);
          });
      },
      (progress) => {
        setTaskProgress(progress);
      },
    );
    setTask(uploadTask);
    setTaskTitle(`파일 업로드`);
  };

  const handleChangeFiles = (event) => {
    dispatch(setShowTextareaPopup(false));
    if (inputFileUpload.current.files.length === 0) return;
    const file = inputFileUpload.current.files[0];
    const extensionResult = checkSupportExtension(
      inputFileUpload.current.files[0],
    );
    switch (extensionResult.fileType) {
      case 'image':
        dispatch(globalReducers.setInfoLoadingText('이미지 업로드 중...'));
        imageUpload(file);
        break;
      case 'video':
        dispatch(globalReducers.setInfoLoadingText('동영상 업로드 중...'));
        videoUpload(file);
        break;
      case 'file':
        dispatch(globalReducers.setInfoLoadingText('파일 업로드 중...'));
        filesUpload(file);
        break;
      default:
        alert('지원하지 않는 파일 형식입니다.');
    }
  };

  const handleEnterPress: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    if (event.code === 'Enter') {
      if (!event.shiftKey) {
        if (currentChat.replaceAll(' ', '')) {
          event.preventDefault();
          addChatMessage(currentChat);
          setCurrentChat('');
          hiddenInputRef.current.focus();
        } else {
          alert('입력한 메시지가 없습니다.');
        }
      }
    }
  };
  const handleClickCancelTask = () => {
    if (task) {
      task.cancel();
      setTask(null);
      setTaskTitle(``);
    }
  };

  useEffect(() => {
    return () => handleClickCancelTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [actionSheetRef, isShownTextAreaPopup]);

  const handleClickOutside = (event) => {
    if (
      actionSheetRef.current &&
      !actionSheetRef.current.contains(event.target) &&
      textAreaPopupButton &&
      isShownTextAreaPopup &&
      !textAreaPopupButton.current?.contains(event.target)
    ) {
      dispatch(setShowTextareaPopup(false));
    }
  };

  // const isKeyboardOpen = useDetectKeyboardOpen();
  return (
    <div>
      <input
        ref={hiddenInputRef}
        className={'w-0 h-0 opacity-0'}
        onFocus={() => {
          textAreaRef?.current?.focus();
        }}
      />
      <form encType='multipart/form-data'>
        <input
          type='file'
          ref={inputFileUpload}
          className='hidden'
          onChange={handleChangeFiles}
        />
      </form>
      <form
        className={`flex flex-col relative w-full textAreaShadow bg-white py-3 px-1`}
      >
        {taskTitle && (
          <div className='absolute flex top-[-50px] left-[1.3625rem] rounded-md buttonShadow bg-[#ffffffed] py-2 px-4 z-50'>
            <Lottie
              options={lottieOptions}
              isStopped={false}
              isClickToPauseDisabled={true}
              style={{
                width: '60px',
                height: '60px',
                position: 'absolute',
                top: '-9px',
                left: '-2px',
              }} // svg의 부모 div에 적용
            />
            <div className='flex justify-center items-center text-left'>
              <span className='ml-[2.5rem] pr-1'>{`${taskTitle}`}</span>
              <span className='pr-3 inline-block min-w-[3.8rem]'>{`${taskProgress}%`}</span>
            </div>
            {parseInt(taskProgress) < 100 && (
              <button onClick={handleClickCancelTask}>
                <svg
                  ref={textAreaPopupButton}
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 23.04 23.04'
                  className='rotate-45 relative'
                >
                  <path
                    data-name='패스 7045'
                    d='M22.08 10.56h-9.6V.96a.96.96 0 0 0-.96-.96.96.96 0 0 0-.96.96v9.6H.96a.96.96 0 0 0-.96.96.96.96 0 0 0 .96.96h9.6v9.6a.96.96 0 0 0 .96.96.96.96 0 0 0 .96-.96v-9.6h9.6a.96.96 0 0 0 .96-.96.96.96 0 0 0-.96-.96z'
                    className='fill-[#4d4f5c]'
                  />
                </svg>
              </button>
            )}
          </div>
        )}
        <div>
          {isShownTextAreaPopup && (
            <div
              ref={actionSheetRef}
              className='absolute z-[100] bottom-[3.5rem]'
            >
              <ul className='flex bg-white py-[1.5rem] px-[1rem] shadow-lg rounded-xl buttonShadow'>
                <li className='flex flex-1 flex-col justify-center items-center'>
                  <button
                    className='flex flex-col justify-center items-center'
                    onClick={handleUploadImage}
                  >
                    <div className='flex justify-center items-center bg-[#f5f8ff] rounded-full w-[3.5rem] h-[3.5rem]'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 32 33'
                      >
                        <g
                          id='그룹_7883'
                          data-name='그룹 7883'
                          transform='translate(-.13 .248)'
                        >
                          <g id='그룹_7882' data-name='그룹 7882'>
                            <path
                              id='패스_7316'
                              data-name='패스 7316'
                              className='fill-[#6c5ce7]'
                              d='M25.719 0H6.768A6.776 6.776 0 0 0 0 6.768v18.951a6.776 6.776 0 0 0 6.768 6.768h18.951a6.776 6.776 0 0 0 6.768-6.768V6.768A6.776 6.776 0 0 0 25.719 0M6.768 2.707h18.951a4.061 4.061 0 0 1 4.061 4.061v18.951a4 4 0 0 1-.406 1.739l-12.4-12.4a6.768 6.768 0 0 0-9.572 0l-4.69 4.689V6.768a4.061 4.061 0 0 1 4.056-4.061m0 27.073a4.061 4.061 0 0 1-4.061-4.061v-2.147l6.6-6.6a4.061 4.061 0 0 1 5.743 0l12.4 12.4a4 4 0 0 1-1.739.406z'
                              transform='translate(-.348 .446)'
                            />
                            <path
                              id='패스_7317'
                              data-name='패스 7317'
                              className='fill-[#6c5ce7]'
                              d='M271.4 84.142a4.738 4.738 0 1 0-4.738-4.738 4.738 4.738 0 0 0 4.738 4.738m0-6.768a2.03 2.03 0 1 1-2.03 2.03 2.03 2.03 0 0 1 2.03-2.03'
                              transform='translate(-250.093 -69.482)'
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p className='mt-3 text-neutral-dark text-[0.9rem]'>
                      사진 전송
                    </p>
                  </button>
                </li>
                <li className='flex flex-1 flex-col justify-center items-center w-[6.8125rem]'>
                  <button
                    className='flex flex-col justify-center items-center'
                    onClick={handleUploadVideo}
                  >
                    <div className='flex justify-center items-center bg-[#f5f8ff] rounded-full w-[3.5rem] h-[3.5rem]'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 34 33'
                      >
                        <g
                          id='그룹_7888'
                          data-name='그룹 7888'
                          transform='translate(.368 .248)'
                        >
                          <g id='그룹_7887' data-name='그룹 7887'>
                            <path
                              id='패스_7318'
                              data-name='패스 7318'
                              className='fill-[#6c5ce7]'
                              d='M25.726 32.5H6.77A6.778 6.778 0 0 1 0 25.726V6.77A6.778 6.778 0 0 1 6.77 0h18.956A6.778 6.778 0 0 1 32.5 6.77v18.956a6.778 6.778 0 0 1-6.77 6.77M6.77 2.708A4.062 4.062 0 0 0 2.708 6.77v18.956a4.062 4.062 0 0 0 4.062 4.063h18.956a4.062 4.062 0 0 0 4.062-4.062V6.77a4.062 4.062 0 0 0-4.062-4.062zm5.879 20.317a3.206 3.206 0 0 1-1.606-.437 3.132 3.132 0 0 1-1.576-2.736v-7.207A3.164 3.164 0 0 1 14.206 9.9l7.147 3.568A3.163 3.163 0 0 1 21.419 19l-7.279 3.634a2.979 2.979 0 0 1-1.491.391zm-.034-10.832a.426.426 0 0 0-.213.057.443.443 0 0 0-.227.4v7.206a.456.456 0 0 0 .677.4l7.279-3.64a.425.425 0 0 0 .162-.36.44.44 0 0 0-.229-.4l-7.141-3.568a.626.626 0 0 0-.309-.092'
                              transform='translate(.522 .437)'
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p className='mt-3 text-[0.9rem] text-neutral-dark'>
                      동영상 전송
                    </p>
                  </button>
                </li>
                <li className='flex flex-1 flex-col justify-center items-center w-[6.8125rem]'>
                  <button
                    className='flex flex-col justify-center items-center'
                    onClick={handleUploadFile}
                  >
                    <div className='flex justify-center items-center bg-[#f5f8ff] rounded-full w-[3.5rem] h-[3.5rem]'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        className='fill-[#6c5ce7]'
                        viewBox='0 0 27.5 33'
                      >
                        <defs>
                          <clipPath id='4ml69sqhra'>
                            <path data-name='사각형 1288' d='M0 0h27.5v33H0z' />
                          </clipPath>
                        </defs>
                        <g data-name='그룹 8239'>
                          <g data-name='그룹 8238'>
                            <path
                              data-name='패스 7394'
                              d='M20.625 19.251a1.375 1.375 0 0 1-1.375 1.375h-11a1.375 1.375 0 0 1 0-2.75h11a1.375 1.375 0 0 1 1.375 1.375m-5.5 4.125H8.25a1.375 1.375 0 0 0 0 2.75h6.875a1.375 1.375 0 0 0 0-2.75M27.5 14.417v11.709A6.883 6.883 0 0 1 20.625 33H6.875A6.883 6.883 0 0 1 0 26.126V6.876A6.883 6.883 0 0 1 6.875 0h6.208a9.568 9.568 0 0 1 6.806 2.819l4.79 4.793a9.558 9.558 0 0 1 2.82 6.8m-9.554-9.648A6.886 6.886 0 0 0 16.5 3.691v5.935A1.375 1.375 0 0 0 17.875 11h5.935a6.851 6.851 0 0 0-1.074-1.444zm6.8 9.654c0-.227-.044-.444-.065-.667h-6.81a4.125 4.125 0 0 1-4.12-4.125V2.815c-.223-.021-.441-.065-.667-.065H6.875A4.125 4.125 0 0 0 2.75 6.876v19.25a4.125 4.125 0 0 0 4.125 4.125h13.75a4.125 4.125 0 0 0 4.125-4.125z'
                              transform='translate(0 -.001)'
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p className='mt-3 text-neutral-dark text-[0.9rem]'>
                      파일 전송
                    </p>
                  </button>
                </li>
              </ul>
            </div>
          )}
          <div className={`flex flex-row items-start pb-3`}>
            <button
              className='text-center bg-white p-2 w-[2.4rem] h-[2.4rem] removeHighlight'
              onClick={togglePopupMenu}
              disabled={globalConfig.isMessageTextAreaReadOnly}
            >
              <svg
                ref={textAreaPopupButton}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 23.04 23.04'
                className={
                  isShownTextAreaPopup
                    ? 'transition duration-200 ease-in-out rotate-45'
                    : 'transition duration-200 ease-in-out rotate-0'
                }
              >
                <path
                  data-name='패스 7045'
                  d='M22.08 10.56h-9.6V.96a.96.96 0 0 0-.96-.96.96.96 0 0 0-.96.96v9.6H.96a.96.96 0 0 0-.96.96.96.96 0 0 0 .96.96h9.6v9.6a.96.96 0 0 0 .96.96.96.96 0 0 0 .96-.96v-9.6h9.6a.96.96 0 0 0 .96-.96.96.96 0 0 0-.96-.96z'
                  className='fill-[#6C5CE7]'
                />
              </svg>
            </button>
            <TextareaAutosize
              ref={textAreaRef}
              className=' w-full bg-secondary-light caret-neutral-dark text-base text-neutral-dark mx-1 px-3 py-2 rounded-lg border-none outline-none resize-none'
              placeholder={
                globalConfig.isMessageTextAreaReadOnly
                  ? '대화가 종료되어 병원 방문 후 메시지 입력이 가능합니다.'
                  : '메시지를 작성해주세요'
              }
              onChange={handleChange}
              value={currentChat}
              onKeyPress={handleEnterPress}
              readOnly={globalConfig.isMessageTextAreaReadOnly}
              maxRows={4}
            />
            <button
              onClick={handleSubmit}
              disabled={globalConfig.isMessageTextAreaReadOnly}
              className='w-[2.4rem] h-[2.4rem] removeHighlight p-1.5'
            >
              <svg
                className='cursor-pointer'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
              >
                <path
                  fill='#6c5ce7'
                  d='M1.532.131A1 1 0 0 0 .065 1.33L2 5.995l7.332 2L2 9.992.065 14.657a.975.975 0 0 0 1.4 1.2L16 7.994z'
                  transform='translate(.004 .006)'
                />
              </svg>
            </button>
          </div>
        </div>
        {/*{globalInfo.chatBotActive && (*/}
        {/*  <ChattingChipInput*/}
        {/*    isKeyboardOpen={isKeyboardOpen}*/}
        {/*    requestReservationInfo={requestReservationInfo}*/}
        {/*    addChatMessage={addChatMessage}*/}
        {/*  />*/}
        {/*)}*/}
      </form>
    </div>
  );
};

export default ChattingTextBox;
