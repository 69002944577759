import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import {
  selectNotificationMessages,
  setMessageData,
  setShowViewer,
} from 'src/features/chatting/slices/chattingSlice';
import { downloadURL } from 'src/features/util/downloadURL';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  globalReducers,
  selectGlobalConfig,
  selectGlobalInfo,
} from '../../@globalSlices/globalSlice';
import { getVaccineData, getVisitData } from '../service/InformationService';
import FileIconByType from './FileIconByType';

export const InformationModal = ({ startIndex }) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef(null);
  const globalInfo = useAppSelector(selectGlobalInfo);
  const notificationMessages = useAppSelector(selectNotificationMessages);
  const [vaccineList, setVaccineList] = useState([]);

  const onClickCloseModal = () => {
    dispatch(globalReducers.setConfigSelectedRegisterModalIndex(1));
    dispatch(globalReducers.setConfigIsShownInformationModal(false));
  };
  const globalConfig = useAppSelector(selectGlobalConfig);
  useEffect(() => {
    if (!globalInfo.hospitalKey || !globalInfo.petInfo.key) return;
    getVaccineData(globalInfo.hospitalKey, globalInfo.petInfo.key).then(
      (querySnapshot) => {
        if (!querySnapshot.empty) {
          const vaccineData = {};
          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            if (data.name !== '심장사상충') {
              const previousVaccine = vaccineData[data.name];
              if (previousVaccine) {
                if (previousVaccine.order < data.order) {
                  vaccineData[data.name] = data;
                }
              } else {
                vaccineData[data.name] = data;
              }
            } else {
              const previousVaccine = vaccineData[data.productName];
              if (previousVaccine) {
                if (previousVaccine.confirmationDate < data.confirmationDate) {
                  vaccineData[data.productName] = data;
                }
              } else {
                vaccineData[data.productName] = data;
              }
            }
          });
          const result = Object.keys(vaccineData).map((key) => {
            return vaccineData[key];
          });
          if (result) {
            result.sort((pre, next) => {
              return next.confirmationDate - pre.confirmationDate;
            });
          }
          if (result) {
            result.sort((pre, next) => {
              if (next.name === '심장사상충') {
                return -1;
              }
              return 0;
            });
          }
          if (result) {
            result.sort((pre, next) => {
              if (next.name === '심장사상충') {
                return next.confirmationDate - pre.confirmationDate;
              }
              return 0;
            });
          }
          setVaccineList(result);
        }
      },
    );
  }, [globalInfo]);

  const [visitList, setVisitList] = useState([]);

  useEffect(() => {
    if (!globalInfo.hospitalKey || !globalInfo.chatKey) return;
    getVisitData(globalInfo.hospitalKey, globalInfo.chatKey).then(
      (querySnapshot) => {
        setVisitList([]);
        if (!querySnapshot.empty) {
          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            setVisitList((list) => [...list, data]);
          });
        } else {
          console.log('no contents');
        }
      },
    );
  }, [globalInfo]);

  console.log(notificationMessages);
  const [selectedMenu, setSelectedMenu] = useState(1);
  useEffect(() => {
    setSelectedMenu(startIndex);
  }, [startIndex]);

  return (
    <div>
      <div
        className={
          'absolute top-0 z-30 flex flex-row item-end justify-end w-full h-full bg-black opacity-50'
        }
      >
        <button
          className={'top-0 px-5 h-[4.5rem] removeHighlight'}
          onClick={onClickCloseModal}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
              fill='#ffffff'
            />
          </svg>
        </button>
      </div>
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        ref={containerRef}
        className='fixed bottom-0 w-full h-[calc(100%-4.5rem)] z-50'
      >
        <div className='flex flex-col w-full h-full bg-white '>
          <div className='w-full h-[3.125rem] min-h-[3.125rem] flex text-sm border-b'>
            <button
              className={`flex-1 h-[3.125rem]  ${
                selectedMenu === 1 && 'border-b-2 border-primary text-primary'
              }`}
              onClick={() => setSelectedMenu(1)}
              disabled={globalConfig.selectedRegisterModalIndex === 4}
            >
              공지 자료
            </button>
            {globalConfig.selectedRegisterModalIndex !== 4 && (
              <button
                className={`flex-1 h-[3.125rem]  ${
                  selectedMenu === 2 && 'border-b-2 border-primary text-primary'
                }`}
                onClick={() => setSelectedMenu(2)}
              >
                방문 기록
              </button>
            )}
            {/* <button
              className={`flex-1  h-[3.125rem]  ${
                selectedMenu === 3 && 'border-b-2 border-primary text-primary'
              }`}
              onClick={() => setSelectedMenu(3)}
            >
              접종 기록
            </button> */}
          </div>
          {selectedMenu === 2 ? (
            <div className='flex flex-col w-full h-full  p-5'>
              {visitList.length > 0 && (
                <>
                  <div className='flex w-full text-neutral-dark'>
                    <span className='flex-1 text-left'>날짜</span>
                    <span className='flex-1 text-center'>시간</span>
                    <span className='flex-1 text-right'>목적</span>
                  </div>
                  <div className='bg-neutral-normal mt-2 w-full h-[1px]' />
                </>
              )}

              {visitList.length > 0 ? (
                visitList.map((val, index) => {
                  let purposeType = '기타';
                  if (val.visit?.purpose) {
                    purposeType =
                      val.visit.purpose === 'beauty'
                        ? '미용'
                        : val.visit.purpose === 'vaccine'
                        ? '접종'
                        : val.visit.purpose === 'operation'
                        ? '수술'
                        : val.visit.purpose === 'consulting'
                        ? '진료'
                        : '기타';
                  }
                  return (
                    <>
                      <div
                        key={index}
                        className='flex w-full text-neutral-dark mt-2'
                      >
                        <span className='flex-1 text-left'>
                          {dayjs(val.createdDate).format('YYYY.MM.DD')}
                        </span>
                        <span className='flex-1 text-center'>
                          {dayjs(val.createdDate).format('HH:mm')}
                        </span>
                        <span className='flex-1 text-right'>{purposeType}</span>
                      </div>
                      <hr className='mt-2' />
                    </>
                  );
                })
              ) : (
                <div className='w-full h-full flex flex-col justify-center items-center text-base text-neutral-dark font-semibold leading-7'>
                  <p>방문 기록이 없습니다.</p>
                  <p>
                    <span className='text-primary'>동물병원의 늘펫 QR코드</span>
                    를
                  </p>
                  <p>인식하여 방문등록을 하시면</p>
                  <p>방문 정보가 이곳에 기록됩니다.</p>
                </div>
              )}
            </div>
          ) : (
            // : selectedMenu === 3 ? (
            //   <div className='flex flex-col w-full h-full bg-white opacity-100 p-5'>
            //     <div
            //       className={
            //         'w-full h-full border border-neutral-light rounded-lg overflow-auto'
            //       }
            //     >
            //       <p className={'w-full text-center font-bold py-3'}>
            //         예방 접종 내역
            //       </p>
            //       <div className={'h-[1px] bg-neutral-light mx-5'} />
            //       <div
            //         className={
            //           'w-full flex flex-row py-3 px-5 text-neutral-normal'
            //         }
            //       >
            //         <span className={'flex-1 text-sm '}>반려동물</span>
            //         <span className={'flex-1 font-bold text-neutral-dark'}>
            //           {globalInfo.petInfo.name}
            //         </span>
            //         <span className={'flex-1 text-sm '}>보호자</span>
            //         <span className={'flex-1 font-bold text-neutral-dark'}>
            //           {globalInfo.familyInfo.name}
            //         </span>
            //       </div>
            //       <div className={'h-[1px] bg-secondary-dark mx-5'} />
            //       <div className={'h-[1px] bg-secondary-dark mx-5'} />
            //       <div
            //         className={
            //           'w-full flex flex-row py-3 px-5 text-neutral-normal'
            //         }
            //       >
            //         <span className={'flex-1 text-sm text-left mr-2'}>
            //           백신 종류 / 차수
            //         </span>
            //         <span className={'flex-1 text-sm text-center mr-2'}>
            //           접종일
            //         </span>
            //         <span className={'flex-1 text-sm text-right'}>접종 병원</span>
            //       </div>
            //       <div className={'h-[1px] bg-neutral-light mx-5'} />
            //       <div className={'w-full overflow-y-auto'}>
            //         {vaccineList.length > 0 ? (
            //           vaccineList.map((vaccine, index) => {
            //             const firstLine =
            //               index ===
            //               vaccineList.findIndex((v) => v.name === '심장사상충');
            //             return (
            //               <>
            //                 {firstLine ? <hr className='mx-5 mb-2' /> : ''}
            //                 <div
            //                   key={`vaccine_${index}`}
            //                   className={
            //                     'w-full flex flex-row py-3 px-5 text-neutral-dark text-sm'
            //                   }
            //                 >
            //                   <span className={'flex-1 text-left mr-2'}>
            //                     {vaccine.name === '심장사상충'
            //                       ? vaccine.productName
            //                       : `${vaccine.name} / ${vaccine.order}`}
            //                   </span>
            //                   <span className={'flex-1  text-center mr-2'}>
            //                     {dayjs(vaccine.confirmationDate).format(
            //                       'YYYY.MM.DD',
            //                     )}
            //                   </span>
            //                   <span className={'flex-1 text-right'}>
            //                     {vaccine.hospitalInfo.name}
            //                   </span>
            //                 </div>
            //               </>
            //             );
            //           })
            //         ) : (
            //           <div className='w-full h-full flex flex-col justify-center items-center text-base text-neutral-dark font-semibold leading-7'>
            //             <p className='mt-4'>접종 내역이 없습니다.</p>
            //           </div>
            //         )}
            //       </div>
            //     </div>
            //   </div>
            // )
            <div className='flex flex-col w-full h-full px-6'>
              {notificationMessages.length > 0 ? (
                notificationMessages.map((val, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className='flex flex-col w-full text-neutral-dark mt-2 py-4'
                      >
                        <div className='flex flex-col font-normal'>
                          <span className='text-sm'>
                            {dayjs(val.createdDate).format('YYYY.MM.DD HH:mm')}
                          </span>
                          <span className='text-base mt-2'>
                            {val.actions[0]?.data?.message}
                          </span>
                          <span className='text-sm mt-[.375rem]'>
                            {val.actions[0]?.data?.files &&
                              val.actions[0]?.data?.files.length > 0 &&
                              val.actions[0]?.data?.files.map((val, index) => {
                                return (
                                  <div
                                    key={`file_${index}`}
                                    className='flex mt-[.375rem]'
                                  >
                                    <button
                                      className={
                                        'flex items-center bg-secondary-normal py-[.375rem] px-3 rounded-[3.125rem]'
                                      }
                                      onClick={(event) => {
                                        event.preventDefault();
                                        if (val.file.type.includes('image/')) {
                                          dispatch(setShowViewer(true));
                                          dispatch(
                                            setMessageData({
                                              link: val.link,
                                              mediaType: 0,
                                            }),
                                          );
                                        } else if (
                                          val.file.type.includes('video/')
                                        ) {
                                          dispatch(setShowViewer(true));
                                          dispatch(
                                            setMessageData({
                                              link: val.link,
                                              mediaType: 1,
                                            }),
                                          );
                                        } else {
                                          downloadURL(val.link, val.file.name);
                                        }
                                      }}
                                    >
                                      <div className='mr-2'>
                                        {val.file.type.includes('image/') ? (
                                          <FileIconByType type='image' />
                                        ) : val.file.type.includes('video/') ? (
                                          <FileIconByType type='video' />
                                        ) : (
                                          <FileIconByType type='file' />
                                        )}
                                      </div>
                                      <span className='text-left'>
                                        {val.file.name?.length > 40
                                          ? `${val.file.name.slice(0, 40)}..`
                                          : val.file.name}
                                      </span>
                                    </button>
                                  </div>
                                );
                              })}
                          </span>
                        </div>
                      </div>
                      <hr className='mt-1 h-[1px] w-full' />
                    </>
                  );
                })
              ) : (
                <div className='w-full h-full flex flex-col justify-center items-center text-base text-neutral-dark font-semibold leading-7'>
                  <p className='mb-0'>등록된 공지 자료가 없습니다.</p>
                  <p>병원에서 공지자료를 보낼 경우</p>
                  <p>이곳에서 확인이 가능합니다.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};
