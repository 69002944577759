import { captureException } from '@sentry/core';
import axios from 'axios';

export const subscribeTokenToTopic = (topic, token) => {
  console.log('subsribe', token, topic);
  axios
    .post('fcm/subscribe', {
      topic: topic,
      token: token,
    })
    .then((response) => {});
  // fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
  //   method: 'POST',
  //   headers: new Headers({
  //     Authorization:
  //       'key=AAAA2mTRSBg:APA91bFfbQpfqZId73INCIf7u99XwawouIrHaajNnBsu1Yzw1nIv31mdvRql9-g1xQa-7gBnm4Zq59OKSgj2oVhPy61MkkogKpGv_RIYwbP6erg7HOAmOcjt8h7a5nUY17-EjjVmaohw',
  //   }),
  // })
  //   .then((response) => {
  //     if (response.status < 200 || response.status >= 400) {
  //       throw (
  //         'Error subscribing to topic: ' +
  //         response.status +
  //         ' - ' +
  //         response.text()
  //       );
  //     }
  //     response.json().then((json) => {
  //       console.log('subscribe', json);
  //     });
  //   })
  //   .catch((error) => {
  //     captureException(error);
  //     console.error(error);
  //   });
};
export const getSubscribeByToken = (token) => {
  fetch('https://iid.googleapis.com/iid/info/' + token + '?detail/=true', {
    method: 'GET',
    headers: new Headers({
      Authorization:
        'key=AAAA2mTRSBg:APA91bFfbQpfqZId73INCIf7u99XwawouIrHaajNnBsu1Yzw1nIv31mdvRql9-g1xQa-7gBnm4Zq59OKSgj2oVhPy61MkkogKpGv_RIYwbP6erg7HOAmOcjt8h7a5nUY17-EjjVmaohw',
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw (
          'Error subscribing to topic: ' +
          response.status +
          ' - ' +
          response.text()
        );
      } else {
        response.json().then((json) => {
          console.log('getSubscribe', json);
        });
      }
    })
    .catch((error) => {
      captureException(error);
      console.error(error);
    });
};
export const unSubscribeTopic = (topic: string, token: string) => {
  axios
    .post('fcm/unSubscribe', {
      topic: topic,
      token: token,
    })
    .then((response) => {});
};
