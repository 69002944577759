import dayjs from 'dayjs';
import localeKo from 'dayjs/locale/ko';
import React from 'react';

export const Time = ({ isLeft, preMsgCard, msgCard, nextMsgCard }) => {
  const getTime = (createdDate) => {
    return dayjs(createdDate).isToday()
      ? dayjs(createdDate).locale(localeKo).format('HH:mm')
      : createdDate >= dayjs().valueOf()
      ? dayjs(createdDate).locale(localeKo).format('YYYY.MM.DD 발송 예정')
      : dayjs(createdDate).locale(localeKo).format('MM.DD HH:mm');
  };
  const TimeSpan = ({ createdDate }: { createdDate: number }) => {
    return (
      <div
        className={
          'flex justify-end mx-1 mt-1 ' +
          (isLeft ? 'ml-[2rem]' : 'items-center')
        }
      >
        <span className='text-xs text-spun-pearl-500'>
          {getTime(createdDate)}
        </span>
      </div>
    );
  };
  const isSameMinutes = (preMsgCard, msgCard) => {
    return (
      dayjs(preMsgCard.createdDate).format('a hh시 mm분') !==
      dayjs(msgCard.createdDate).format('a hh시 mm분')
    );
  };
  return (
    <>
      {preMsgCard ? (
        preMsgCard.writerType !== msgCard.writerType ? (
          nextMsgCard ? (
            nextMsgCard.writerType !== msgCard.writerType && (
              <TimeSpan createdDate={msgCard.createdDate} />
            )
          ) : (
            <TimeSpan createdDate={msgCard.createdDate} />
          )
        ) : isSameMinutes(preMsgCard, msgCard) ? (
          <TimeSpan createdDate={msgCard.createdDate} />
        ) : nextMsgCard ? (
          msgCard.writerType !== nextMsgCard.writerType ? (
            <TimeSpan createdDate={msgCard.createdDate} />
          ) : isSameMinutes(msgCard, nextMsgCard) ? (
            <TimeSpan createdDate={msgCard.createdDate} />
          ) : (
            ''
          )
        ) : (
          <TimeSpan createdDate={msgCard.createdDate} />
        )
      ) : (
        <TimeSpan createdDate={msgCard.createdDate} />
      )}
    </>
  );
};
