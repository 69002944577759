import React from 'react';

const CheckIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334ZM14.3396 8.08934C14.665 7.7639 14.665 7.23626 14.3396 6.91083C14.0141 6.58539 13.4865 6.58539 13.1611 6.91083L8.75033 11.3216L6.83958 9.41083C6.51414 9.08539 5.98651 9.08539 5.66107 9.41083C5.33563 9.73626 5.33563 10.2639 5.66107 10.5893L8.16107 13.0893C8.48651 13.4148 9.01414 13.4148 9.33958 13.0893L14.3396 8.08934Z'
        fill='#95E1D3'
      />
    </svg>
  );
};

export default CheckIcon;
