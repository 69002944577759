import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { createRipples } from 'react-ripples';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
const MyRipples = createRipples({
  color: 'rgba(0, 0, 0, .2)',
  during: 600,
});

import { addQNAInfo, selectQNAInfoList } from '../slice/QNASlice';
import { QNA } from './QNADataType';
export const SelectQna = ({
  itemList,
  selectedList,
  onItemClick,
}: {
  itemList: any[];
  selectedList?: any[];
  onItemClick: (index, item) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleClickEvent = {
    itemClick: (event) => {
      const index = event.currentTarget.dataset.index;
      if (_.isString(index)) {
        setSelectedIndex(parseInt(index));
        onItemClick(index, itemList[parseInt(index)]);
      }
    },
  };
  useEffect(() => {
    if (selectedList) {
      const index = itemList.indexOf(selectedList[0]);
      setSelectedIndex(index);
    } else {
      setSelectedIndex(-1);
    }
  }, [selectedList]);
  return (
    <div className='h-full mt-3 overflow-y-auto onScrollbar'>
      <ul className='pr-5 pl-5'>
        {itemList &&
          itemList.length !== 0 &&
          itemList.map((purpose, index) => {
            return (
              <li
                className={`rounded-[.5rem] border mt-4 mb-4 text-base overflow-hidden ${
                  selectedIndex === index
                    ? 'text-primary border-primary border-2'
                    : 'text-neutral-normal border-neutral-normal'
                }`}
                key={index.toString()}
              >
                <MyRipples className='w-full h-full'>
                  <button
                    className='relative p-4 text-left w-full h-full removeHighlight'
                    onClick={handleClickEvent.itemClick}
                    data-index={index}
                  >
                    {purpose}
                  </button>
                </MyRipples>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SelectQna;
