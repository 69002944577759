export const doCopy = async () => {
  const currentURLWithQuery = window.location.href;

  if (navigator.clipboard) {
    // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
    return navigator.clipboard
      .writeText(currentURLWithQuery)
      .then(() => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  } else {
    if (!document.queryCommandSupported('copy')) {
      return false;
    }
    const textarea = document.createElement('textarea');
    textarea.value = currentURLWithQuery;
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    return true;
  }
};
