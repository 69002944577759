export const AgreementModal = ({ onClickAgreement }) => {
  return (
    <>
      <div
        className={
          'absolute top-0 z-40 flex flex-row item-end justify-end w-full h-full bg-black opacity-50 font-Nanum'
        }
      />
      <div className={'fixed z-50 bottom-0 w-full rounded-t-2xl bg-white p-6'}>
        <p className={'text-neutral-dark text-base font-semibold pb-6'}>
          개인정보 제공 동의
        </p>

        <button
          onClick={() => {
            window.open(
              'https://neulpet.notion.site/a441fd883c794a3c9ce56fb80812c021',
            );
          }}
          className='flex items-center justify-between bg-secondary-light px-3 w-full py-[.625rem]'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10'
            height='7'
            viewBox='0 0 10 7'
            fill='none'
          >
            <path
              id='Icon'
              d='M1 3.5L3.5 6L8.5 1'
              stroke='#808495'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p className='text-neutral-normal text-sm font-semibold'>
            (필수) 개인정보 수집과 제 3자 제공 동의
          </p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <g id='icon / arrow / left'>
              <path
                id='Icon (Stroke)'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.79289 4.29289C6.40237 4.68342 6.40237 5.31658 6.79289 5.70711L11.0858 10L6.79289 14.2929C6.40237 14.6834 6.40237 15.3166 6.79289 15.7071C7.18342 16.0976 7.81658 16.0976 8.20711 15.7071L13.2071 10.7071C13.5976 10.3166 13.5976 9.68342 13.2071 9.29289L8.20711 4.29289C7.81658 3.90237 7.18342 3.90237 6.79289 4.29289Z'
                fill='#808495'
              />
            </g>
          </svg>
        </button>
        <p className={'text-neutral-normal text-sm pt-[.625rem] font-light'}>
          해당 정보는 동물병원, 늘펫 서비스에서만 사용됩니다.
        </p>
        <div className={'flex flex-row gap-2 pt-6 pb-2'}>
          <button
            className={
              'flex-1 py-2 rounded-lg bg-primary border-primary border text-white removeHighlight'
            }
            onClick={() => {
              onClickAgreement(true);
            }}
          >
            동의
          </button>
        </div>
      </div>
    </>
  );
};
