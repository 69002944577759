/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { motion } from 'framer-motion';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Ripples from 'react-ripples';
import { Slide, toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  globalReducers,
  selectGlobalInfo,
} from '../../@globalSlices/globalSlice';
import { ArrowSvg } from '../../chatting/components/ArrowSvg';
import { getQNATemplate } from '../service/QNAService';
import initialState, {
  defaultQnaInfoList,
  extraQnaInfoList,
  registerOperationQnaInfoList,
  reserveOperationQnaInfoList,
} from '../slice/QNAInitialData';
import {
  selectQNAInfoList,
  setQNAInfoList,
  setQNAShow,
} from '../slice/QNASlice';
import DateQna from './DateQna';
import { DescriptionQna } from './DescriptionQna';
import { PointQna } from './PointQna';
import ReservationShareQna from './ReservationShareQna';
import SelectQna from './SelectQna';
import SymptomsQna from './SymptomsQna';
import { TextQna } from './TextQna';
import TimeQna from './TimeQna';
type QNAModalProps = {
  hospitalName: string;
  qnaTemplateKey?: string;
  qnaTemplateType?: string;
  cycleKey?: string;
  eventKey?: string;
  onUploadClick: (qnaData: any) => void;
};
const QNAModal: React.FC<QNAModalProps> = ({
  hospitalName = '동물병원',
  cycleKey,
  eventKey,
  qnaTemplateKey = '',
  qnaTemplateType = '',
  onUploadClick,
}) => {
  dayjs.extend(customParseFormat);
  const dispatch = useAppDispatch();
  const qnaInfoList = useAppSelector(selectQNAInfoList);
  const [nextBtnText, setNextBtnText] = useState('다음');
  const [pageIndex, setPageIndex] = useState(0);
  const [qnaInfoListInPages, setQnaInfoListInPages] = useState(null);
  const globalInfo = useAppSelector(selectGlobalInfo);
  const containerRef = useRef(null);
  const toastId = useRef(null);
  const [isNextActive, setNextActive] = useState(false);
  const [qnaTemplate, setQnaTemplate] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const toastOption: ToastOptions = {
    position: 'top-center',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    closeButton: false,
    draggable: true,
    transition: Slide,
  };
  const setDefaultQnaDirectly = () => {
    const qnaTemplate = {} as any;
    if (globalInfo.qnaType === 'follow') {
      qnaTemplate.name = '기본 팔로업 문진';
      qnaTemplate.type = 'follow';
      dispatch(setQNAInfoList(extraQnaInfoList));
    } else if (globalInfo.qnaType === 'visit') {
      qnaTemplate.name = '기본 방문등록 문진';
      qnaTemplate.type = 'chat';
      dispatch(setQNAInfoList(defaultQnaInfoList));
    } else if (globalInfo.qnaType === 'operation') {
      qnaTemplate.name = '기본 방문등록 문진';
      qnaTemplate.type = 'reservation';
      if (globalInfo.lastEventInfo.type === 'reservation') {
        qnaTemplate.name = '기본 예약 문진';
        dispatch(setQNAInfoList(reserveOperationQnaInfoList));
      } else {
        dispatch(setQNAInfoList(registerOperationQnaInfoList));
      }
    }
    setQnaTemplate(qnaTemplate);
  };

  useEffect(() => {
    setIsFirstLoad(false);
    if (qnaTemplateKey) {
      getQNATemplate(globalInfo.hospitalKey, qnaTemplateKey).then(
        (qnaTemplate) => {
          setIsFirstLoad(true);
          if (qnaTemplate) {
            dispatch(setQNAInfoList(qnaTemplate.qnaList));
            setQnaTemplate(qnaTemplate);
          } else {
            setDefaultQnaDirectly();
          }
        },
      );
    } else {
      setTimeout(() => {
        setDefaultQnaDirectly();
        setIsFirstLoad(true);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalInfo.qnaList,
    globalInfo.qnaType,
    globalInfo.lastEventInfo,
    globalInfo.hospitalKey,
    qnaTemplateKey,
  ]);
  useEffect(() => {
    if (qnaInfoListInPages) {
      if (pageIndex === qnaInfoListInPages.length - 1) {
        setNextBtnText('완료');
      } else {
        setNextBtnText('다음');
      }
    }
  }, [qnaInfoListInPages, pageIndex]);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     console.log('click outside', event.target);
  //     console.log('current', containerRef.current);
  //     if (
  //       containerRef.current &&
  //       !containerRef.current.contains(event.target) &&
  //       !toast.isActive(toastId.current)
  //     ) {
  //       dispatch(setQNAInfoList(null));
  //       dispatch(setQNAShow(false));
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [containerRef]);
  const handleClickEvent = {
    previousClick: () => {
      if (pageIndex === 0) {
        console.log('page 0');
        dispatch(setQNAShow(false));
        dispatch(setQNAInfoList(initialState.qnaInfoList));
      } else {
        setPageIndex(pageIndex - 1);
      }
    },
    nextClick: () => {
      const noAnswerIndex = qnaInfoListInPages[pageIndex].findIndex((qna) => {
        if (qna.required) {
          if (qna.selectedAnswerList) {
            return (
              qna.selectedAnswerList.length === 0 ||
              qna.selectedAnswerList.every(
                (selectedAnswer) => selectedAnswer === '',
              )
            );
          } else {
            return false;
          }
        }
        return false;
      });
      if (noAnswerIndex !== -1) {
        const qnaInfoList = qnaInfoListInPages[pageIndex];
        if (
          qnaInfoList.length === 1 &&
          (qnaInfoList[0].type === 'symptoms' ||
            qnaInfoList[0].type === 'select')
        ) {
          toastId.current = toast(
            `아래 선택지중 하나 이상을 선택 해주세요`,
            toastOption,
          );
        } else {
          toastId.current = toast(`아래 질문에 답을 해주세요.`, toastOption);
        }
      } else {
        if (pageIndex < qnaInfoListInPages.length - 2) {
          setPageIndex(pageIndex + 1);
        } else if (pageIndex === qnaInfoListInPages.length - 2) {
          setPageIndex(pageIndex + 1);
        } else {
          upload();
        }
      }
    },
  };
  //api 통신 하기
  const upload = () => {
    dispatch(
      globalReducers.setInfoLoadingText(`답변을 수의사님께 보내고 있어요!`),
    );
    dispatch(globalReducers.setConfigIsLoaded(false));
    console.log('qnaUpload', makeQnaData());
    onUploadClick(makeQnaData());
  };
  const makeQnaData = () => {
    return {
      type: qnaTemplateType,
      name: qnaTemplate.name,
      chatKey: globalInfo.chatKey,
      hospitalInfo: {
        key: globalInfo.hospitalKey,
        name: globalInfo.hospitalName,
      },
      qnaTemplateKey: qnaTemplateKey,
      cycleKey: cycleKey ? cycleKey : globalInfo.cycleKey,
      eventKey: eventKey ? eventKey : globalInfo.eventKey,
      petInfo: globalInfo.petInfo,
      familyInfo: globalInfo.familyInfo,
      qnaInfoList: qnaInfoListInPages.reduce((arr, qnaInfoList) => {
        const resultList = qnaInfoList.map((qnaInfo) => {
          const cloneQnaInfo = _.cloneDeep(qnaInfo);
          cloneQnaInfo.selectedAnswerList = cloneQnaInfo.selectedAnswerList
            ? cloneQnaInfo.selectedAnswerList
            : [];
          return cloneQnaInfo;
        });
        return arr.concat(resultList);
      }, []),
      createdDate: dayjs().valueOf(),
    };
  };
  useEffect(() => {
    if (qnaInfoList && qnaInfoList.length > 0) {
      const newQnaInfoListInPages = [];
      qnaInfoList.map((qna) => {
        if (newQnaInfoListInPages[qna.page] === undefined) {
          newQnaInfoListInPages[qna.page] = [qna];
        } else {
          newQnaInfoListInPages[qna.page].push(qna);
        }
      });
      setQnaInfoListInPages(newQnaInfoListInPages);
    }
  }, [qnaInfoList]);
  const onSymptomsItemClick = (page, index, itemList) => {
    const newQnaInfoListInPages = _.cloneDeep(qnaInfoListInPages);
    newQnaInfoListInPages[page][index].selectedAnswerList = itemList;
    setQnaInfoListInPages(newQnaInfoListInPages);
  };
  const onPointItemClick = (page, index, point) => {
    const newQnaInfoListInPages = _.cloneDeep(qnaInfoListInPages);
    newQnaInfoListInPages[page][index].selectedAnswerList = [point];
    setQnaInfoListInPages(newQnaInfoListInPages);
    // if (page < newQnaInfoListInPages.length - 1) {
    //   setTimeout(() => {
    //     setPageIndex(page + 1);
    //   }, 300);
    // }
  };
  const onSelectItemClick = (page, index, item) => {
    const newQnaInfoListInPages = _.cloneDeep(qnaInfoListInPages);
    newQnaInfoListInPages[page][index].selectedAnswerList = [item];
    setQnaInfoListInPages(newQnaInfoListInPages);
    // if (page < newQnaInfoListInPages.length - 1) {
    //   setTimeout(() => {
    //     setPageIndex(page + 1);
    //   }, 300);
    // }
  };
  const onTextChange = (page, index, text) => {
    const newQnaInfoListInPages = _.cloneDeep(qnaInfoListInPages);
    newQnaInfoListInPages[page][index].selectedAnswerList = [text];
    setQnaInfoListInPages(newQnaInfoListInPages);
  };
  const currentProgress = () => {
    return qnaInfoListInPages
      ? (pageIndex / (qnaInfoListInPages.length - 1)) * 100
      : 0;
  };

  const onDateClick = (page, index, date) => {
    const newQnaInfoListInPages = _.cloneDeep(qnaInfoListInPages);
    newQnaInfoListInPages[page][index].selectedAnswerList = [date];
    setQnaInfoListInPages(newQnaInfoListInPages);
  };

  const onTimeClick = (page, index, time) => {
    const newQnaInfoListInPages = _.cloneDeep(qnaInfoListInPages);
    newQnaInfoListInPages[page][index].selectedAnswerList = [time];
    setQnaInfoListInPages(newQnaInfoListInPages);
  };

  useEffect(() => {
    if (qnaInfoListInPages) {
      const qnaInfoList = qnaInfoListInPages[pageIndex];
      if (!qnaInfoList) {
        return;
      }
      const noAnswerIndex = qnaInfoList.findIndex((qna) => {
        if (qna.required) {
          if (qna.selectedAnswerList) {
            return (
              qna.selectedAnswerList.length === 0 ||
              qna.selectedAnswerList.every(
                (selectedAnswer) =>
                  selectedAnswer === '' || selectedAnswer === null,
              )
            );
          } else {
            return true;
          }
        }
        return false;
      });
      console.log('noAnswer', noAnswerIndex);
      setNextActive(noAnswerIndex !== -1);
    }
  }, [pageIndex, qnaInfoListInPages]);
  const closeModal = () => {
    dispatch(setQNAInfoList(null));
    dispatch(setQNAShow(false));
  };

  console.log(qnaInfoListInPages);

  return (
    <div>
      <div
        className={
          'absolute top-0 z-20 flex flex-row item-end justify-end w-full h-full bg-black opacity-50'
        }
      >
        <button
          className={'top-0 px-5 h-[4.5rem] removeHighlight'}
          onClick={closeModal}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
              fill='#ffffff'
            />
          </svg>
        </button>
      </div>
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        ref={containerRef}
        className='fixed bottom-0 w-full h-[calc(100%-4.5rem)] z-50'
      >
        <div className='flex flex-col w-full h-full bg-white opacity-100 qnaModalShadow'>
          <div className={'w-full px-5 mt-10'}>
            <div className={'w-full'}>
              <div
                className={
                  'w-full h-[.75rem] bg-secondary-dark rounded-full overflow-hidden'
                }
              >
                <div
                  className={`h-[.75rem] bg-primary transition-all rounded-full`}
                  style={{ width: `${currentProgress()}%` }}
                ></div>
              </div>
            </div>
            <p className={'text-right text-sm text-neutral-dark mt-2'}>
              {qnaInfoListInPages &&
                `${pageIndex + 1} / ${qnaInfoListInPages.length}`}
            </p>
          </div>
          {isFirstLoad ? (
            <div className={'w-full flex-1 overflow-y-auto onScrollbar'}>
              <p className={`text-xl text-neutral-dark font-bold my-5 mx-8`}>
                {qnaInfoListInPages &&
                  qnaInfoListInPages[pageIndex] &&
                  qnaInfoListInPages[pageIndex][0].question}
              </p>
              {qnaInfoListInPages &&
                qnaInfoListInPages[pageIndex] &&
                qnaInfoListInPages[pageIndex].map((qnaInfo) => {
                  return qnaInfo.type === 'symptoms' ? (
                    <SymptomsQna
                      itemList={qnaInfo.answerList}
                      selectedList={qnaInfo.selectedAnswerList}
                      onItemClick={(itemList) => {
                        onSymptomsItemClick(
                          qnaInfo.page,
                          qnaInfo.index,
                          itemList,
                        );
                      }}
                    />
                  ) : qnaInfo.type === 'select' ? (
                    <SelectQna
                      itemList={qnaInfo.answerList}
                      selectedList={qnaInfo.selectedAnswerList}
                      onItemClick={(index, item) => {
                        onSelectItemClick(qnaInfo.page, qnaInfo.index, item);
                      }}
                    />
                  ) : qnaInfo.type === 'point' ? (
                    <PointQna
                      itemList={qnaInfo.answerList}
                      selectedList={qnaInfo.selectedAnswerList}
                      onItemClick={(point) => {
                        onPointItemClick(qnaInfo.page, qnaInfo.index, point);
                      }}
                    />
                  ) : qnaInfo.type === 'text' ? (
                    <TextQna
                      answer={
                        qnaInfo.selectedAnswerList
                          ? qnaInfo.selectedAnswerList[0]
                          : ''
                      }
                      onChange={(text) => {
                        onTextChange(qnaInfo.page, qnaInfo.index, text);
                      }}
                    />
                  ) : qnaInfo.type === 'date' ? (
                    <DateQna
                      date={
                        qnaInfo.selectedAnswerList
                          ? qnaInfo.selectedAnswerList[0]
                          : null
                      }
                      onDateClick={(date) => {
                        onDateClick(qnaInfo.page, qnaInfo.index, date);
                      }}
                    />
                  ) : qnaInfo.type === 'time' ? (
                    <TimeQna
                      time={
                        qnaInfo.selectedAnswerList
                          ? qnaInfo.selectedAnswerList[0]
                          : '선택해주세요.'
                      }
                      onTimeClick={(time) => {
                        onTimeClick(qnaInfo.page, qnaInfo.index, time);
                      }}
                    />
                  ) : qnaInfo.type === 'reservation' ? (
                    <ReservationShareQna
                      hospitalKey={globalInfo.hospitalKey}
                      date={
                        qnaInfo.selectedAnswerList
                          ? qnaInfo.selectedAnswerList[0]
                          : null
                      }
                      onDateClick={(date) => {
                        onDateClick(qnaInfo.page, qnaInfo.index, date);
                      }}
                      availableTimeSlots={qnaInfo.availableTimeSlots}
                      timeSplit={qnaInfo.timeSplit}
                    />
                  ) : (
                    <DescriptionQna
                      description={qnaInfo.description}
                      imageUrl={qnaInfo.imageUrl}
                    />
                  );
                })}
            </div>
          ) : (
            <div className='w-full h-full flex justify-center items-center'>
              <div className='loader' />
            </div>
          )}

          <div className='w-full flex px-5 pb-10 gap-3'>
            <Ripples>
              <button
                className='flex flex-row w-[3.5rem] h-[3rem] text-[.875rem] bg-neutral-light rounded-md removeHighlight items-center justify-center'
                onClick={handleClickEvent.previousClick}
              >
                <div className={'mr-1'}>
                  <ArrowSvg rotate={'rotate-0'} color={'text-white'} />
                </div>
              </button>
            </Ripples>
            <Ripples className={'flex-1'}>
              <button
                className='flex flex-row w-full h-[3rem] text-lg bg-primary rounded-md text-white pl-5 pr-5 removeHighlight font-medium items-center justify-center disabled:bg-neutral-light'
                onClick={handleClickEvent.nextClick}
                disabled={isNextActive}
              >
                <div className={'flex flex-row items-center gap-3'}>
                  <p>{nextBtnText}</p>
                  <div className={'mr-1'}>
                    <ArrowSvg rotate={'rotate-180'} color={'text-white'} />
                  </div>
                </div>
              </button>
            </Ripples>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
export default QNAModal;
