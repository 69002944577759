import { DocumentReference } from '@firebase/firestore-types';
import { captureException } from '@sentry/core';
import axios from 'axios';
import dayjs from 'dayjs';
import firebase from 'firebase';
import _, { now } from 'lodash';

import { replaceVariable } from '../../util/replaceVariable';
import { MessageDTO } from '../components/messageBox/MessageDTO';
import { messageDataType } from '../components/messageBox/messageType';
import firestore = firebase.firestore;

export const getMessages = (
  chatKey: string,
  hospitalKey: string,
  snapshot: (messages: messageDataType[], notificationMessages: any[]) => void,
) => {
  const queryString = `Hospital/${hospitalKey}/Chat/${chatKey}/Message`;
  const db = firebase.firestore();
  const messageQuery = db.collection(queryString);
  return messageQuery.onSnapshot((querySnapshot) => {
    const messages: messageDataType[] = [];
    const notificationMessages = [];
    querySnapshot.forEach((doc) => {
      if (
        doc.data().actions &&
        doc.data().actions[0]?.name === '공지자료 확인하기'
      ) {
        notificationMessages.push(doc.data());
      }
      messages.push({
        createdDate: doc.data().createdDate,
        key: doc.data().key,
        text: doc.data().text,
        writer: doc.data().writer,
        writerType: doc.data().writerType,
        actions: doc.data().actions,
        files: doc.data().files,
        images: doc.data().images,
        videos: doc.data().videos,
        recognizedWordList: doc.data().recognizedWordList,
        qnaInfos: doc.data().qnaInfos,
      });
    });
    messages.sort((a, b) => a.createdDate - b.createdDate);
    notificationMessages.sort((a, b) => b.createdDate - a.createdDate);
    snapshot(messages, notificationMessages);
  });
};
export const updateA2HS = (hospitalKey: string, chatKey: string) => {
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey)
    .update({
      isInstalledHomeScreen: true,
    });
};
export const updateSubscribe = (familyKey: string, uuid: string) => {
  return firebase
    .firestore()
    .collection('Family')
    .doc(familyKey)
    .update({
      subscribeInfoList: firestore.FieldValue.arrayUnion(uuid),
    });
};
export const getChatDataRealTime = (
  hospitalKey: string,
  chatKey: string,
  snapshot: (chatInfo) => void,
) => {
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey)
    .onSnapshot(
      (querySnapshot) => {
        snapshot(querySnapshot.data());
      },
      (error) => {
        console.log(error);
      },
    );
};

export const updateChatLastRead = (hospitalKey: string, chatKey: string) => {
  firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey)
    .set(
      {
        lastReadTimeInfo: {
          nok: dayjs().valueOf(),
        },
        recentMessage: {
          read: true,
        },
      },
      { merge: true },
    );
};

export const addMessage = async (
  messages,
  waitMessage,
  globalInfo,
  messageDtoList: MessageDTO[],
  isSkipActivityLog?: boolean,
  writerType?: string,
) => {
  const isSkipLog = isSkipActivityLog ? isSkipActivityLog : false;
  const messageWriterType = writerType ? writerType : 'nok';
  const familyName = globalInfo.familyInfo.name;
  const familyKey = globalInfo.familyInfo.key;
  const hospitalKey = globalInfo.hospitalKey;
  const hospitalName = globalInfo.hospitalName;
  const chatKey = globalInfo.chatKey;
  const db = firebase.firestore();
  const chatRef = db
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey);
  const waitingMessages = messages.filter((message) => {
    return (
      message.writerType === 'waiting' || message.writerType === 'bot_waiting'
    );
  });
  const result = await db.runTransaction(async (transaction) => {
    for (const waitingMessage of waitingMessages) {
      const waitingMessageRef = chatRef
        .collection('Message')
        .doc(waitingMessage.key);
      await transaction.delete(waitingMessageRef);
    }
    messageDtoList.forEach((messageDto) => {
      const messageRef = chatRef.collection('Message').doc();
      messageDto.key = messageRef.id;
      transaction.set(messageRef, messageDto.toPlainObj());
    });
    const lastMessageDto = _.last(messageDtoList);
    await transaction.set(
      chatRef,
      {
        lastReadTimeInfo: {
          nok: dayjs().valueOf(),
        },
        recentMessage: {
          createdDate: new Date().valueOf(),
          key: lastMessageDto.key,
          read: false,
          remind: false,
          text: lastMessageDto.text,
          writer: lastMessageDto.writer,
          writerType: messageWriterType,
        },
      },
      { merge: true },
    );

    if (!isSkipLog) {
      const hospitalRef = firebase
        .firestore()
        .collection('Hospital')
        .doc(globalInfo.hospitalKey);
      const activityRef = hospitalRef.collection('ActivityLog').doc();
      const activityData = {
        createdDate: now(),
        familyInfo: globalInfo.familyInfo,
        hospitalInfo: { name: hospitalName, key: hospitalKey },
        petInfo: globalInfo.petInfo.key ? globalInfo.petInfo : null,
        key: activityRef.id,
        message: {
          status: 'receive',
          text: lastMessageDto.text,
          key: lastMessageDto.key,
          chatKey: chatKey,
        },
        writer: hospitalName,
        writerKey: hospitalKey,
        writerType: 'noticeLog',
      };

      await transaction.set(activityRef, activityData);
    }

    if (waitMessage) {
      const waitMessageInfo = getWaitMessageInfo(
        hospitalName,
        hospitalKey,
        chatKey,
        waitMessage,
        3,
      );
      await transaction.set(
        waitMessageInfo.ref,
        waitMessageInfo.dto.toPlainObj(),
      );
    }
    return lastMessageDto.key;
  });
  messageDtoList.forEach((messageDto) => {
    if (messageDto?.writerType === 'nok') {
      sendFcm(hospitalKey, chatKey, familyKey, familyName, messageDto.text);
    }
  });
  return result;
};

export const addFileMessage = async (
  globalInfo,
  messageDto: MessageDTO,
  messages,
  waitMessage,
) => {
  const hospitalName = globalInfo.hospitalName;
  const familyName = globalInfo.familyInfo.name;
  const familyKey = globalInfo.familyInfo.key;
  const hospitalKey = globalInfo.hospitalKey;
  const chatKey = globalInfo.chatKey;
  const db = firebase.firestore();
  const chatRef = db
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey);
  const messageRef = chatRef.collection('Message').doc();
  const newWaitingMessageRef = chatRef.collection('Message').doc();
  messageDto.key = messageRef.id;
  const waitingMessages = messages.filter((message) => {
    return message.writerType === 'waiting';
  });
  const result = await db.runTransaction(async (transaction) => {
    await transaction.set(messageRef, messageDto.toPlainObj());
    await transaction.set(
      chatRef,
      {
        lastReadTimeInfo: {
          nok: dayjs().valueOf(),
        },
        recentMessage: {
          createdDate: new Date().valueOf(),
          key: messageRef.id,
          read: false,
          remind: false,
          text: messageDto.text,
          writer: messageDto.writer,
          writerType: 'nok',
        },
      },
      { merge: true },
    );
    // for (const waitingMessage of waitingMessages) {
    //   const waitingMessageRef = chatRef
    //     .collection('Message')
    //     .doc(waitingMessage.key);
    //   await transaction.delete(waitingMessageRef);
    // }
    if (waitMessage) {
      const waitMessageInfo = getWaitMessageInfo(
        hospitalName,
        hospitalKey,
        chatKey,
        waitMessage,
        10,
      );
      await transaction.set(
        waitMessageInfo.ref,
        waitMessageInfo.dto.toPlainObj(),
      );
    }
    return messageRef;
  });
  sendFcm(hospitalKey, chatKey, familyKey, familyName, messageDto.text);
  return result;
};
export const getPetData = (petKey: string) => {
  return firebase.firestore().collection('Pet').doc(petKey).get();
};
export const addFileMessageWithRef = async (
  globalInfo,
  messageRef: DocumentReference,
  messageDto: MessageDTO,
  messages,
  waitMessage,
) => {
  const hospitalName = globalInfo.hospitalName;
  const familyName = globalInfo.familyInfo.name;
  const familyKey = globalInfo.familyInfo.key;
  const hospitalKey = globalInfo.hospitalKey;
  const chatKey = globalInfo.chatKey;
  const db = firebase.firestore();
  const chatRef = db
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey);
  messageDto.key = messageRef.id;
  const waitingMessages = messages.filter((message) => {
    return message.writerType === 'waiting';
  });
  const result = await db.runTransaction(async (transaction) => {
    await transaction.set(messageRef, messageDto.toPlainObj());
    await transaction.set(
      chatRef,
      {
        lastReadTimeInfo: {
          nok: dayjs().valueOf(),
        },
        recentMessage: {
          createdDate: new Date().valueOf(),
          key: messageRef.id,
          read: false,
          remind: false,
          text: messageDto.text,
          writer: messageDto.writer,
          writerType: 'nok',
        },
      },
      { merge: true },
    );
    // for (const waitingMessage of waitingMessages) {
    //   const waitingMessageRef = chatRef
    //     .collection('Message')
    //     .doc(waitingMessage.key);
    //   await transaction.delete(waitingMessageRef);
    // }
    const waitMessageInfo = getWaitMessageInfo(
      hospitalName,
      hospitalKey,
      chatKey,
      waitMessage,
      3,
    );
    await transaction.set(
      waitMessageInfo.ref,
      waitMessageInfo.dto.toPlainObj(),
    );
    return messageRef;
  });
  sendFcm(hospitalKey, chatKey, familyKey, familyName, messageDto.text);
  return result;
};

export const updateMessage = async (globalInfo, messageData) => {
  const hospitalKey = globalInfo.hospitalKey;
  const chatKey = globalInfo.chatKey;
  return await firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey)
    .collection('Message')
    .doc(messageData.key)
    .set(messageData);
};

export const getWaitMessageInfo = (
  hospitalName,
  hospitalKey,
  chatKey,
  waitMessage,
  waitSeconds,
) => {
  const chatRef = firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey);
  const newWaitingMessageRef = chatRef.collection('Message').doc();
  const messageDto = new MessageDTO({
    writer: `${hospitalName}`,
    writerType: 'bot_waiting',
    text: waitMessage.text
      .replaceAll('[병원 이름]', hospitalName)
      .replaceAll('[병원이름]', hospitalName),
    key: newWaitingMessageRef.id,
    createdDate: dayjs().add(waitSeconds, 'second').valueOf(),
  });
  if (waitMessage.qnaTemplateKey) {
    messageDto.actions = [
      {
        name: '더보기',
        link: 'internal://reply_request',
        type: 'button',
        data: {
          requestType: 'request_no_contents',
          ...waitMessage,
        },
      },
    ];
  }
  return {
    ref: newWaitingMessageRef,
    dto: messageDto,
  };
};
const sendFcm = (
  hospitalKey: string,
  chatKey: string,
  familyKey: string,
  familyName: string,
  message: string,
) => {
  const sendFcmData = {
    topic: hospitalKey,
    data: {
      channel: 'chat',
      message: message,
      title: '보호자로부터 메시지가 도착하였습니다.',
      value: {
        chatKey: chatKey,
        hospitalKey: hospitalKey,
        familyKey: familyKey,
        writer: familyName,
        writerType: 'neulPet',
        createdDate: new Date().valueOf(),
      },
    },
  };
  axios
    .post('fcm/send', sendFcmData)
    .then((response) => console.info(`complete sendFCM`))
    .catch((error) => captureException(error));
};
export const updateCommunicationRead = async (
  hospitalKey: string,
  communicationKey: string,
) => {
  const communicationRef = firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Communication')
    .doc(communicationKey);
  return await firebase.firestore().runTransaction(async (transaction) => {
    const communicationData = await transaction.get(communicationRef);
    if (communicationData.exists) {
      const readTime = communicationData.data().readTime;
      if (!readTime) {
        await transaction.update(communicationRef, {
          readTime: dayjs().valueOf(),
        });
      } else {
        if (readTime === 0) {
          await transaction.update(communicationRef, {
            readTime: dayjs().valueOf(),
          });
        }
      }
    }
  });
};

export const getMessageRef = (hospitalKey: string, chatKey: string) => {
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey)
    .collection('Message')
    .doc();
};
export const createQnaMessage = (qnaInfos) => {
  let messageTxt = '';
  qnaInfos.map((qna, index) => {
    messageTxt += '\n';
    messageTxt += `${index + 1}. ${qna.question}`;
    messageTxt += '\n';
    messageTxt += qna.selectedAnswerList.join(' / ');
    messageTxt += '\n';
  });
  return messageTxt;
};

export const getWaitingMessage = (globalInfo) => {
  if (!globalInfo.hospitalKey) {
    return;
  }
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(globalInfo.hospitalKey)
    .collection('MessageTemplate')
    .where('keyword', 'in', ['자동 응답 메시지', '메시지 수신'])
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        return replaceVariable(querySnapshot.docs[0].data().text, {
          hospitalName: globalInfo.hospitalName,
          petName: globalInfo.petInfo.name,
        });
      }
      return undefined;
    })
    .catch((error) => {
      captureException(error);
      return undefined;
    });
};

export const getLastCycleInfo = (globalInfo, onSnapshot) => {
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(globalInfo.hospitalKey)
    .collection('Cycle')
    .where('chatKey', '==', globalInfo.chatKey)
    .orderBy('createdDate', 'desc')
    .limit(1)
    .onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        onSnapshot(snapshot.docs[0].data());
      }
    });
};
export const getContentsMessageTemplate = (globalInfo, onSnapshot) => {
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(globalInfo.hospitalKey)
    .collection('MessageTemplate')
    .where('action', '==', 'contents')
    .onSnapshot(
      (querySnapshot) => {
        if (!querySnapshot.empty) {
          const result = querySnapshot.docs
            .map((doc) => {
              return doc.data();
            })
            .filter((data) => {
              if (data.publish !== undefined) {
                return data.publish;
              } else {
                return true;
              }
            });
          onSnapshot(result);
        }
      },
      (error) => {},
    );
};

export const updateFamilyName = async (
  name,
  hospitalKey,
  chatKey,
  familyKey,
) => {
  if (hospitalKey && chatKey) {
    const chatRef = await firebase
      .firestore()
      .collection('Hospital')
      .doc(hospitalKey)
      .collection('Chat')
      .doc(chatKey);

    await chatRef
      .update({
        'familyInfo.name': name,
      })
      .catch((e) => {
        console.log('chatRefError', e);
      });

    chatRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          doc.data().familyInfo.name = name;
          axios.patch('/chat', doc.data()).catch((error) => {
            console.log('error', error);
          });
        }
      })
      .catch((e) => {
        console.log('/chatError', e);
      });
  }
  if (familyKey) {
    const familyRef = await firebase
      .firestore()
      .collection('Family')
      .doc(familyKey);

    familyRef
      .get()
      .then(async (doc) => {
        if (doc.data().memberInfoList[0]) {
          const newMemberInfoList = doc
            .data()
            .memberInfoList.map((memberInfo, index) => {
              if (index === 0) {
                return { ...memberInfo, name: name };
              }
              return memberInfo;
            });

          await familyRef.update({ memberInfoList: newMemberInfoList });
        }
      })
      .catch((error) => {
        console.log('familyError', error);
      });
  }
};

export const sendFcmToMe = async (
  chatKey,
  hospitalInfo: any,
  familyKey: string,
) => {
  console.log('sendFcmToMe', chatKey, hospitalInfo, familyKey);
  try {
    const sendFcmData = {
      topic: `${hospitalInfo.key}_${familyKey}`,
      data: {
        channel: 'chat',
        title: `${hospitalInfo.name}에서 메시지가 도착했습니다.`,
        message: `해당 내용을 원장님께 전달했습니다.`,
        value: {
          chatKey: chatKey,
          hospitalKey: hospitalInfo.key,
          familyKey: familyKey,
          writer: hospitalInfo.name,
          writerType: 'vetflux',
          createdDate: dayjs().valueOf(),
        },
      },
    };
    axios
      .post('fcm/send', sendFcmData)
      .then((response) => console.info(`complete sendFCM TO ME!`))
      .catch((error) => captureException(error));
  } catch (error) {
    captureException(error);
  }
};
