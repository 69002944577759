import 'react-toastify/dist/ReactToastify.css';

import { captureException } from '@sentry/core';
import axios, { AxiosError } from 'axios';
import firebase from 'firebase';
import { AnimatePresence } from 'framer-motion';
import _ from 'lodash';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  globalReducers,
  selectGlobalAuth,
  selectGlobalConfig,
  selectGlobalInfo,
} from 'src/features/@globalSlices/globalSlice';
import { A2HSModal } from 'src/features/chatting/components/A2HSModal';
import { FunctionBlockModal } from 'src/features/chatting/components/FunctionBlockModal';
import { MobileAlert } from 'src/features/chatting/components/MobileAlert';
import { NoticeOnGuideModal } from 'src/features/chatting/components/NoticeOnGuideModal';
import RedirectingPage from 'src/features/chatting/components/RedirectingPage';
import Loading from 'src/features/loading/Loading';

import { useAppDispatch, useAppSelector, useFirebase } from '../app/hooks';
import MessageTemplate from '../features/chatting/components';
import {
  updateA2HS,
  updateSubscribe,
} from '../features/chatting/service/ChattingService';
import { setLoaded } from '../features/chatting/slices/chattingSlice';
import {
  getMobileOS,
  isAppInstalled,
  isInAppBrowser,
  isIOSInstalled,
  isSafariBrowser,
} from '../features/util/userAgentChecker';
import {
  subscribeTokenToTopic,
  unSubscribeTopic,
} from '../notification/fcmService';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const HomePage: NextPage = ({ manifest, favicon }) => {
  const dispatch = useAppDispatch();
  const globalConfig = useAppSelector(selectGlobalConfig);
  const globalAuth = useAppSelector(selectGlobalAuth);
  const globalInfo = useAppSelector(selectGlobalInfo);
  const { app } = useFirebase();
  const [isSubscribePush, setSubscribePush] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isShownNoticeOnGuideModal, setShownNoticeOnGuideModal] =
    useState(false);
  const [isShownAlarmAlert, setShownAlarmAlert] = useState(false);

  const setMobileWebVhCalc = _.debounce((vh: string | number) => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + `px`);
  }, 500);

  const unloadEvent = (currentUser: { delete: () => Promise<unknown> }) => {
    const deleteCurrentUser = () => {
      currentUser
        .delete()
        .then((result) => {
          console.log(`result`, result);
        })
        .catch((error) => {
          captureException(error);
          console.log(`error`, error);
        });
    };
    window.addEventListener('beforeunload', deleteCurrentUser);
  };
  const axiosInterceptorsTokenRefresh = () => {
    let refreshAttempts = 0;

    axios.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error: AxiosError) => {
        if (
          (error.response?.status === 401 || error.response?.status === 403) &&
          refreshAttempts < 3
        ) {
          refreshAttempts++;
          const originalRequest = error.config;
          try {
            refreshAttempts = 0;
            const accessToken = await setDefaultAccessToken(
              firebase.auth().currentUser,
            );
            axios.defaults.headers.common['Authorization'] = accessToken;
            originalRequest.headers.Authorization = accessToken;
            return axios(originalRequest);
          } catch (e) {
            captureException(e);
            return error;
          }
        }
        return Promise.reject(error);
      },
    );
  };
  const setDefaultAccessToken = async (currentUser: firebase.User) => {
    return await currentUser
      .getIdToken(true)
      .then(function (idToken) {
        dispatch(globalReducers.setAccessToken(idToken));
        return idToken;
      })
      .catch(function (error) {
        captureException(error);
        console.error(`인증 토큰 재갱신에 실패하였습니다.\n${error}`);
        return undefined;
      });
  };

  // 알림 허용 여부 체크 함수
  const onTrackFcmMessage = () => {
    try {
      const messaging = firebase.messaging();
      messaging.onMessage((onMessage) => {
        navigator.serviceWorker
          .getRegistration('/firebase-cloud-messaging-push-scope')
          .then((registration) => {
            if (Notification.permission === 'granted') {
              const parsedValue = JSON.parse(onMessage.data.value);
              const option = {
                body: onMessage.data.message,
                icon: onMessage.data?.favicon
                  ? onMessage.data.favicon
                  : './favicon/icon-512x512.png',
                data: {
                  hospitalKey: parsedValue.hospitalKey,
                  chatKey: parsedValue.chatKey,
                },
              };
              registration.showNotification(onMessage.data.title, option);
            } else {
              //Notification.requestPermission();
              // toast.show({
              //   title: '알람이 꺼져 있습니다. 알람을 허용해주세요',
              //   content: '알람을 허용하면 벳플럭스의 알람을 받을 수 있습니다.',
              //   buttonOption: {
              //     value: '알림 허용',
              //     onClick: () => {
              //       Notification.requestPermission();
              //     },
              //   },
              // });}
            }
          });
      });
    } catch (e) {
      captureException(e);
      console.log(e);
    }
  };

  // 알림 버튼 클릭 시, 알림 설정
  const onAlarmClick = async () => {
    const isMobileAlertShow = window.localStorage.getItem('mobileAlertShow');
    if (getMobileOS() === 'iOS') {
      if (isIOSInstalled()) {
        if (isSubscribePush) {
          alert('설정에서 직접 알림을 꺼야합니다.');
          dispatch(setLoaded(true));
          subscribeFcm(globalInfo.familyInfo.key, globalInfo.hospitalKey);
        } else {
          if (isMobileAlertShow === null) {
            subscribeFcm(globalInfo.familyInfo.key, globalInfo.hospitalKey);
            window.localStorage.setItem('mobileAlertShow', String(true));
          } else {
            setShownNoticeOnGuideModal(true);
          }
        }
      } else {
        // if (isSafariBrowser()) {
        //   dispatch(globalReducers.setConfigIsShownA2HSModal('safari'));
        // } else if (isInAppBrowser()) {
        //   dispatch(globalReducers.setConfigIsShownA2HSModal('inApp'));
        // } else {
        //   dispatch(globalReducers.setConfigIsShownA2HSModal('other'));
        // }
        setIsShownA2HSNoticeModal(true);
      }
    } else {
      const isStandalone = window.matchMedia(
        '(display-mode: standalone)',
      ).matches;
      if (isStandalone) {
        if (isSubscribePush) {
          alert('설정에서 직접 알림을 꺼야합니다.');
          dispatch(setLoaded(true));
          subscribeFcm(globalInfo.familyInfo.key, globalInfo.hospitalKey);
        } else {
          if (isMobileAlertShow === null) {
            subscribeFcm(globalInfo.familyInfo.key, globalInfo.hospitalKey);
            window.localStorage.setItem('mobileAlertShow', String(true));
          } else {
            setShownNoticeOnGuideModal(true);
          }
        }
      } else {
        setIsShownA2HSNoticeModal(true);
      }
    }
  };

  const onOpenRegisterModal = () => {
    if (getMobileOS() === 'iOS') {
      if (isIOSInstalled()) {
        dispatch(globalReducers.setConfigIsShownInformationModal(true));
        dispatch(globalReducers.setConfigSelectedRegisterModalIndex(1));
      } else {
        // if (isSafariBrowser()) {
        //   dispatch(globalReducers.setConfigIsShownA2HSModal('safari'));
        // } else if (isInAppBrowser()) {
        //   dispatch(globalReducers.setConfigIsShownA2HSModal('inApp'));
        // } else {
        //   dispatch(globalReducers.setConfigIsShownA2HSModal('other'));
        // }
        setIsShownA2HSNoticeModal(true);
      }
    } else {
      const isStandalone = window.matchMedia(
        '(display-mode: standalone)',
      ).matches;
      if (isStandalone) {
        dispatch(globalReducers.setConfigIsShownInformationModal(true));
        dispatch(globalReducers.setConfigSelectedRegisterModalIndex(1));
      } else {
        setIsShownA2HSNoticeModal(true);
      }
    }
  };

  const subscribeFcm = (familyKey: string, hospitalKey: string) => {
    if (!('Notification' in window)) {
      console.log('This browser does not support system notifications');
      return;
    }
    console.log('api', process.env.FIREBASE_PUSH_API_KEY);
    setSubscribePush(false); // 내 독단 판단
    try {
      app
        .messaging()
        .getToken({
          vapidKey: process.env.FIREBASE_PUSH_API_KEY,
        })
        .then((currentToken) => {
          window.localStorage.setItem('token', currentToken);
          dispatch(globalReducers.setConfigUUID(currentToken));
          updateSubscribe(familyKey, currentToken);
          setSubscribePush(true);
          subscribeTokenToTopic(`${familyKey}_${hospitalKey}`, currentToken);
          unSubscribeTopic(familyKey, currentToken);
          onTrackFcmMessage();
        })
        .catch((error) => {
          setSubscribePush(false); // 내 독단 판단
          console.log('fcm error', error.code);
          const storageToken = window.localStorage.getItem('token');
          if (storageToken) {
            dispatch(globalReducers.setConfigUUID(storageToken));
          }
          captureException(error);
        });
    } catch (e) {
      setSubscribePush(false); // 내 독단 판단
      console.log(e);
      if (getMobileOS() === 'iOS') {
        alert(
          '웹푸시를 지원하지 않습니다. IOS를 16.4버전으로 업데이트 이후 다시 알림설정을 해주세요.',
        );
      }
      captureException(e);
      console.log(e);
    }
  };

  const onHomeInstallClick = async (isInstall: boolean) => {
    if (isInstall) {
      const mobileOs = getMobileOS();
      if (mobileOs === 'Android') {
        if (!deferredPrompt) {
          alert('이미 설치되었거나 설치가 불가능합니다.');
          // return;
        }
        window.localStorage.removeItem('mobileAlertShow');
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        setDeferredPrompt(null);
      } else if (mobileOs === 'iOS') {
        if (isIOSInstalled()) {
          alert('이미 설치되었습니다.');
        } else if (isSafariBrowser()) {
          dispatch(globalReducers.setConfigIsShownA2HSModal('safari'));
        } else if (isInAppBrowser()) {
          dispatch(globalReducers.setConfigIsShownA2HSModal('inApp'));
        } else {
          dispatch(globalReducers.setConfigIsShownA2HSModal('other'));
        }
      } else {
        alert('즐겨찾기는 모바일 환경에서만 가능합니다.');
      }
    } else {
      console.log('경우 없음');
    }
  };

  // 리사이즈 및 익명 인증 토큰 갱신
  useEffect(() => {
    let vh: string | number;
    if (window) {
      setMobileWebVhCalc(vh);
      window.addEventListener('resize', () => {
        setMobileWebVhCalc(vh);
      });
    }
    app
      .auth()
      .setPersistence(app.auth.Auth.Persistence.LOCAL)
      .then(() => {
        app
          .auth()
          .signInAnonymously()
          .then((result) => {
            const currentUser = app.auth().currentUser;
            setDefaultAccessToken(currentUser);
            dispatch(globalReducers.setCurrentUser(currentUser.uid));
            axiosInterceptorsTokenRefresh();
            unloadEvent(currentUser);
          })
          .catch((error) => {
            console.error(error);
            captureException(error);
          });
      })
      .catch((error) => {
        captureException(error);
      });
  }, []);

  // 서비스워커 업데이트
  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(async (registrations) => {
        if (registrations && registrations.length > 0) {
          registrations.forEach((registration) => {
            registration.update();
          });
        }
      });
    }
  }, []);

  // 서버 url 설정
  useEffect(() => {
    // axios.defaults.baseURL = 'http://localhost:8080/';
    axios.defaults.baseURL = process.env.BASE_URL;
  }, []);

  // 알림 허용 여부 체크
  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support system notifications');
      return;
    }
    if (Notification.permission === 'granted') {
      onTrackFcmMessage();
    }
  }, []);

  // ios에서 설치 시, 설치 데이터 업데이트
  useEffect(() => {
    if (isIOSInstalled()) {
      if (globalInfo.hospitalKey && globalInfo.chatKey) {
        updateA2HS(globalInfo.hospitalKey, globalInfo.chatKey);
      }
    }
  }, [globalInfo.hospitalKey, globalInfo.chatKey]);

  useEffect(() => {
    if (globalInfo.familyInfo.key && globalInfo.hospitalKey) {
      if (isAppInstalled()) {
        const isMobileAlertShow =
          window.localStorage.getItem('mobileAlertShow');
        if (isMobileAlertShow === null) {
          setShownAlarmAlert(true);
        } else {
          subscribeFcm(globalInfo.familyInfo.key, globalInfo.hospitalKey);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalInfo.familyInfo.key, globalInfo.hospitalKey]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/a2hs-sw.js');
    }
    const installPromptListener = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', installPromptListener);

    return () => {
      if (installPromptListener) {
        window.removeEventListener(
          'beforeinstallprompt',
          installPromptListener,
        );
      }
    };
  }, [
    globalInfo.hospitalKey,
    globalInfo.chatKey,
    globalInfo.hospitalName,
    globalInfo.petInfo,
  ]);

  const [isShownA2HSNoticeModal, setIsShownA2HSNoticeModal] = useState(false);
  const [isNeedRedirecting, setIsNeedRedirecting] = useState(false);
  const [isRedirectingLoading, setIsRedirectingLoading] = useState(false);

  // 아이폰, 카카오 웹일때 기본 브라우저로 강제 이동
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const target_url = location.href;

    if (userAgent.match(/kakaotalk/i)) {
      try {
        if (/iphone|ipad/i.test(userAgent)) {
          // const safariUrl = 'https://' + target_url.replace(/https?:\/\//i, '');
          // window.location.href =
          //   'kakaotalk://web/openExternal?url=' + encodeURIComponent(safariUrl);
          setIsNeedRedirecting(true);
          setTimeout(() => {
            location.href =
              'kakaotalk://web/openExternal?url=' +
              encodeURIComponent(target_url);
          }, 1500);
          setTimeout(() => {
            setIsRedirectingLoading(true);
          }, 2000);
        } else {
          location.href =
            'intent://' +
            target_url.replace(/https?:\/\//i, '') +
            '#Intent;scheme=http;package=com.android.chrome;end';
        }
      } catch (error) {
        console.error('Redirection failed', error);
        alert(error);
      }
    } else if (!userAgent.match(/chrome|safari/i)) {
      console.log('크롬이나 사파리가 아닐떄');
    } else {
      console.log('크롬이나 사파리일떄');
    }
  }, []);

  return (
    <>
      <Head>
        <title>
          {globalInfo.hospitalName ? globalInfo.hospitalName : '늘펫 메신저'}
        </title>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
        />
        {/* <link rel='icon' type='image/png' sizes='32x32' href='/ic_phone.png' />

        <link rel='apple-touch-icon' sizes='180x180' href={favicon} />

        <link rel='manifest' href={manifest} /> */}

        <link
          rel='apple-touch-icon'
          sizes='57x57'
          href={favicon ? favicon : '/favicon/apple-icon-57x57.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='60x60'
          href={favicon ? favicon : '/favicon/apple-icon-60x60.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='72x72'
          href={favicon ? favicon : '/favicon/apple-icon-72x72.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='76x76'
          href={favicon ? favicon : '/favicon/apple-icon-76x76.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='114x114'
          href={favicon ? favicon : '/favicon/apple-icon-114x114.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='120x120'
          href={favicon ? favicon : '/favicon/apple-icon-120x120.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='144x144'
          href={favicon ? favicon : '/favicon/apple-icon-144x144.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='152x152'
          href={favicon ? favicon : '/favicon/apple-icon-152x152.png'}
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={favicon ? favicon : '/favicon/apple-icon-180x180.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='192x192'
          href={favicon ? favicon : '/favicon/android-icon-192x192.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={favicon ? favicon : '/favicon/favicon-32x32.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='96x96'
          href={favicon ? favicon : '/favicon/favicon-96x96.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={favicon ? favicon : '/favicon/favicon-16x16.png'}
        />
        <link
          rel='manifest'
          id='manifest'
          href={
            'data:application/json;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(manifest))
          }
        />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta
          name='msapplication-TileImage'
          content={favicon ? favicon : '/favicon/ms-icon-144x144.png'}
        />
        <meta name='theme-color' content='#ffffff' />
      </Head>
      {!globalConfig.isLoaded && <Loading text={globalInfo.loadingText} />}
      {isShownAlarmAlert && (
        <MobileAlert
          nextClickButton={{
            text: '알림 받기',
            onClick: (event) => {
              event.preventDefault();
              subscribeFcm(globalInfo.familyInfo.key, globalInfo.hospitalKey);
              setShownAlarmAlert(false);
              window.localStorage.setItem('mobileAlertShow', String(true));
            },
          }}
          cancelClickButton={{
            text: '알림 안 받기',
            onClick: (event) => {
              event.preventDefault();
              setShownAlarmAlert(false);
              // window.localStorage.setItem('mobileAlertShow', String(true));
            },
          }}
        >
          <div className='text-center text-sm'>
            <p className='whitespace- wrap'>
              수의사로부터 실시간 알람을 받아보세요.
            </p>
            <p className='wh ace-nowrap'>
              알림 거부 시 실시간 알림을 받을 수 없어요.
            </p>
          </div>
        </MobileAlert>
      )}
      {isShownA2HSNoticeModal && (
        <FunctionBlockModal
          isShownA2HSModal={globalConfig.isShownA2HSModal}
          onNextClick={() => {
            const mobileOs = getMobileOS();
            setIsShownA2HSNoticeModal(false);
            if (mobileOs === 'Android') {
              if (!deferredPrompt) {
                alert('이미 설치되었거나 설치가 불가능합니다.');
                // return;
              }
              window.localStorage.removeItem('mobileAlertShow');
              deferredPrompt.prompt();
              setDeferredPrompt(null);
            } else if (mobileOs === 'iOS') {
              if (globalConfig.isShownA2HSModal) {
                setIsShownA2HSNoticeModal(false);
              } else {
                if (isIOSInstalled()) {
                  alert('이미 설치되었습니다.');
                } else if (isSafariBrowser()) {
                  dispatch(globalReducers.setConfigIsShownA2HSModal('safari'));
                } else if (isInAppBrowser()) {
                  dispatch(globalReducers.setConfigIsShownA2HSModal('inApp'));
                } else {
                  dispatch(globalReducers.setConfigIsShownA2HSModal('other'));
                }
              }
            } else {
              alert('즐겨찾기는 모바일 환경에서만 가능합니다.');
            }
          }}
          onCancelClick={() => {
            setIsShownA2HSNoticeModal(false);
            dispatch(globalReducers.setConfigIsShownA2HSModal(''));
          }}
        />
      )}
      <AnimatePresence>
        {globalConfig.isShownA2HSModal && (
          <A2HSModal
            hospitalName={globalInfo.hospitalName}
            location={globalConfig.isShownA2HSModal}
            onCancelClick={() => {
              // dispatch(globalReducers.setConfigIsShownA2HSModal(''));
              setIsShownA2HSNoticeModal(true);
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isShownNoticeOnGuideModal && (
          <NoticeOnGuideModal
            onCancelClick={() => setShownNoticeOnGuideModal(false)}
          />
        )}
      </AnimatePresence>

      {isNeedRedirecting && (
        <RedirectingPage
          isRedirectingLoading={isRedirectingLoading}
          hospitalName={globalInfo?.hospitalName}
        />
      )}
      {globalAuth.currentUser && (
        <MessageTemplate
          onAlarmClick={onAlarmClick}
          isSubscribePush={isSubscribePush}
          onHomeInstallClick={onHomeInstallClick}
          onOpenRegisterModal={onOpenRegisterModal}
          onOpenBlockGuideModal={() => {
            if (getMobileOS() === 'iOS') {
              // if (isSafariBrowser()) {
              //   dispatch(globalReducers.setConfigIsShownA2HSModal('safari'));
              // } else if (isInAppBrowser()) {
              //   dispatch(globalReducers.setConfigIsShownA2HSModal('inApp'));
              // } else {
              //   dispatch(globalReducers.setConfigIsShownA2HSModal('other'));
              // }
              setIsShownA2HSNoticeModal(true);
            } else {
              setIsShownA2HSNoticeModal(true);
            }
          }}
        />
      )}

      <ToastContainer
        limit={1}
        toastClassName={() =>
          'mr-3 ml-3 mt-4 bg-neutral-dark rounded-lg shadow-md'
        }
        bodyClassName={() => 'text-sm font-white py-3 px-3'}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  console.log('ctx', ctx.query);
  console.log('ctx', ctx.req.headers);
  console.log('ctx', process.env.BASE_URL);
  try {
    const res = await fetch(
      `${process.env.BASE_URL}/message/manifest.json?hospitalKey=${ctx.query.hospital}&chatKey=${ctx.query.chat}`,
      {
        method: 'GET',
        headers: {
          referer: `https://${ctx.req.headers.host}/`,
        },
      },
    );

    const json = await res.json();
    if (!json) {
      console.error(`Not found response`);
      return {
        redirect: {
          destination: '/service-unavailable',
          permanent: false,
        },
      };
    }
    return {
      props: {
        manifest: json,
        favicon: '',
      },
    };
  } catch (e) {
    console.error(`ServerSideProps Error | ${JSON.stringify(e)}`);
    return {
      redirect: {
        destination: '/service-unavailable',
        permanent: false,
      },
    };
  }
};
export default HomePage;
