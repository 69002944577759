import dayjs from 'dayjs';
import localeKo from 'dayjs/locale/ko';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { selectGlobalInfo } from '../../@globalSlices/globalSlice';
import { getQNAData } from '../service/QNAService';
export const QnaResultModal = ({
  qnaKey,
  onCloseClick,
}: {
  qnaKey: string;
  onCloseClick;
}) => {
  const globalInfo = useAppSelector(selectGlobalInfo);
  const containerRef = useRef(null);
  const [qnaData, setQnaData] = useState(null);
  useEffect(() => {
    if (qnaKey) {
      getQNAData(globalInfo.hospitalKey, globalInfo.chatKey, qnaKey).then(
        (qnaData) => {
          setQnaData(qnaData);
        },
      );
    }
  }, [qnaKey, globalInfo.chatKey, globalInfo.hospitalKey]);
  return (
    <div>
      <div
        className={
          'absolute top-0 z-20 flex flex-row item-end justify-end w-full h-full bg-black opacity-50'
        }
      >
        <button
          className={'top-0 px-5 h-[4.5rem] removeHighlight'}
          onClick={onCloseClick}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
              fill='#ffffff'
            />
          </svg>
        </button>
      </div>
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        ref={containerRef}
        className='fixed bottom-0 w-full h-[calc(100%-4.5rem)] z-50'
      >
        <div className='flex flex-col w-full h-full bg-white opacity-100 qnaModalShadow'>
          <p className={'py-3 px-6 w-full text-left shadow'}>
            {qnaData &&
              (qnaData?.type === 'request_reservation'
                ? '예약 확인'
                : '답변 확인')}
          </p>
          <div className={'w-full flex-1 overflow-y-auto px-6'}>
            {qnaData && (
              <div>
                <div
                  className={
                    'flex flex-row items-center py-3 border-b border-secondary-normal'
                  }
                >
                  <span className={'flex-1 text-left text-neutral-normal'}>
                    반려동물
                  </span>
                  <span className={'flex-1 text-left text-neutral-dark'}>
                    {qnaData.petInfo?.name || '미등록'}
                  </span>
                  <span className={'flex-1 text-left text-neutral-normal'}>
                    보호자
                  </span>
                  <span className={'flex-1 text-left text-neutral-dark'}>
                    {qnaData.familyInfo.name}
                  </span>
                </div>
                <div
                  className={
                    'flex flex-row items-center py-3 border-b border-secondary-normal'
                  }
                >
                  <span className={'flex-1 text-left text-neutral-normal'}>
                    작성시간
                  </span>
                  <span className={'flex-3 text-left text-neutral-dark'}>
                    {dayjs(qnaData.createdDate)
                      .locale(localeKo)
                      .format('YYYY.MM.DD HH:mm')}
                  </span>
                </div>
              </div>
            )}
            {qnaData &&
              qnaData.qnaInfoList &&
              qnaData.qnaInfoList.map((qnaInfo, index) => {
                return (
                  <>
                    <div className={'w-full flex flex-col text-base'}>
                      <p className={'text-neutral-dark mt-7 mb-3'}>{`Q${
                        index + 1
                      }.${qnaInfo.question}`}</p>
                      {qnaInfo.type === 'description' ? (
                        <div className={'flex flex-col gap-3'}>
                          {qnaInfo.description && (
                            <p className={'text-neutral-normal'}>
                              {qnaInfo.description}
                            </p>
                          )}
                          {qnaInfo.imageUrl && (
                            <img
                              alt={`description_img_${index}`}
                              className={'w-full'}
                              src={qnaInfo.imageUrl}
                              style={{ objectFit: 'cover' }}
                            />
                          )}
                        </div>
                      ) : (
                        <p className={'text-primary'}>
                          {qnaInfo.type === 'point'
                            ? `5점중 ${
                                Number(qnaInfo.selectedAnswerList.join(' / ')) +
                                1
                              }점`
                            : `${qnaInfo.selectedAnswerList.join(' / ')}`}
                        </p>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </motion.div>
    </div>
  );
};
