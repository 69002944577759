import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const getIsSameOrAfterTime = (comparison: string, reference: string) => {
  const now = dayjs();
  return now
    .set('hour', Number(comparison.split(':')[0]))
    .set('minute', Number(comparison.split(':')[1]))
    .isSameOrAfter(
      now
        .set('hour', Number(reference.split(':')[0]))
        .set('minute', Number(reference.split(':')[1])),
    );
};

export const getIsSameOrBeforeTime = (
  comparison: string,
  reference: string,
) => {
  const now = dayjs();
  return now
    .set('hour', Number(comparison.split(':')[0]))
    .set('minute', Number(comparison.split(':')[1]))
    .isBefore(
      now
        .set('hour', Number(reference.split(':')[0]))
        .set('minute', Number(reference.split(':')[1])),
    );
};

export const isValidReservationHourForTimeSplitHour = (
  hour: string,
  disableStartTime: string,
  disableEndTime: string,
) => {
  if (disableStartTime === '' || !disableStartTime) return false;

  const [startHour, startMinute] = disableStartTime
    .split(':')
    .map((value) => Number(value));
  const [endHour, endMinute] = disableEndTime
    .split(':')
    .map((value) => Number(value));

  if (endMinute === 0 && startMinute === 0) {
    return startHour <= Number(hour) && endHour >= Number(hour);
  } else if (startMinute === 0) {
    return startHour <= Number(hour) && endHour > Number(hour);
  } else if (endMinute !== 0) {
    return startHour < Number(hour) && endHour >= Number(hour);
  }
  return startHour < Number(hour) && endHour > Number(hour);
};

export const isValidReservationHour = (
  hour: string,
  disableStartTime: string,
  disableEndTime: string,
) => {
  if (disableStartTime === '' || !disableStartTime) return false;

  const [startHour, startMinute] = disableStartTime
    .split(':')
    .map((value) => Number(value));
  const [endHour, endMinute] = disableEndTime
    .split(':')
    .map((value) => Number(value));

  if (endMinute === 0 && startMinute === 0) {
    return startHour <= Number(hour) && endHour >= Number(hour);
  }
  return startHour < Number(hour) && endHour > Number(hour);
};
