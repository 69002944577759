import React from 'react';

interface DropdownItemProps {
  text: string;
  value: string;
  isDisable: boolean;
  onClickItem?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function DropdownItem({
  text,
  value,
  isDisable,
  onClickItem,
}: DropdownItemProps) {
  return (
    <div
      onClick={onClickItem}
      className={'flex py-2 px-4 items-center hover:bg-secondary-normal w-full'}
      id={value}
    >
      <p className={'font-normal text-sm text-neutral-dark'}>{text}</p>
    </div>
  );
}

export default DropdownItem;
