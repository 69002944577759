export const DescriptionQna = ({
  description,
  imageUrl,
}: {
  description: string;
  imageUrl?: string;
}) => {
  return (
    <>
      <div className={'flex flex-col'}>
        <p className={'mx-5 text-base text-neutral-dark whitespace-pre-line'}>
          {description}
        </p>
        {imageUrl && (
          <img
            alt={'description_img'}
            src={imageUrl}
            className={'w-full mt-3'}
            style={{ objectFit: 'cover' }}
          />
        )}
      </div>
    </>
  );
};
