import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { getMobileOS } from 'src/features/util/userAgentChecker';

import { isSamsungBrowser } from '../../util/userAgentChecker';
export const NoticeOnGuideModal = ({ onCancelClick }) => {
  const containerRef = useRef(null);

  const [selectedMenu, setSelectedMenu] = useState(0);
  useEffect(() => {
    if (getMobileOS() === 'Android') {
      setSelectedMenu(1);
    } else {
      setSelectedMenu(0);
    }
  }, []);
  const guideRef = useRef(null);
  useEffect(() => {
    if (guideRef && guideRef.current) {
      guideRef.current.scrollTo(0, 0);
    }
  }, [selectedMenu]);
  return (
    <div>
      <div
        className={
          'absolute top-0 z-20 flex flex-row item-end justify-end w-full h-full bg-black opacity-50'
        }
      >
        <button
          className={'top-0 px-5 h-[4.5rem] removeHighlight'}
          onClick={onCancelClick}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
              fill='#ffffff'
            />
          </svg>
        </button>
      </div>
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        ref={containerRef}
        className='fixed bottom-0 w-full h-[calc(100%-4.5rem)] z-50'
      >
        <div className='relative flex flex-col items-center  w-full h-full bg-white text-neutral-dark'>
          <div className='flex w-full text-sm fixed top-[0.1rem] bg-white'>
            <button
              className={`flex-1 py-5 border border-t-0 border-l-0 border-r-0 border-b-2 ${
                selectedMenu === 0
                  ? ' border-b-neutral-dark'
                  : ' border-b-secondary-dark text-neutral-light'
              }`}
              onClick={() => setSelectedMenu(0)}
            >
              iOS 가이드
            </button>
            <button
              className={`flex-1 py-5 border border-t-0 border-l-0 border-r-0 border-b-2 ${
                selectedMenu === 1
                  ? 'border-b-neutral-dark'
                  : 'border-b-secondary-dark text-neutral-light'
              }`}
              onClick={() => setSelectedMenu(1)}
            >
              안드로이드 가이드
            </button>
          </div>
          <div
            ref={guideRef}
            className='mt-[4rem] overflow-y-auto overflow-x-hidden'
          >
            {selectedMenu === 0 ? (
              <div className='relative flex flex-col items-center px-[3rem] font-semibold text-center'>
                <div className='relative'>
                  <div className='absolute top-8 left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    01
                  </div>
                  <img
                    className='min-w-[10rem] mt-[2.5rem] shadow-md'
                    src={'/notice_guide/ios_guide_1.png'}
                    alt={'ios_image'}
                  />
                </div>

                <p className='pt-2 pb-8'>
                  아이폰 설정 앱에서 <br />
                  알림버튼을 눌러주세요.
                </p>
                <div className='relative'>
                  <div className='absolute top-[-0.5rem] left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    02
                  </div>
                  <img
                    className='min-w-[10rem]  shadow-md'
                    src={'/notice_guide/ios_guide_2.png'}
                    alt={'ios_image'}
                  />
                </div>
                <p className='pt-2 pb-8'>해당 동물병원을 클릭 해주세요.</p>
                <div className='relative'>
                  <div className='absolute top-[-0.5rem] left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    03
                  </div>
                  <img
                    className='min-w-[10rem] shadow-md'
                    src={'/notice_guide/ios_guide_3.png'}
                    alt={'ios_image'}
                  />
                </div>
                <p className='pt-6 pb-8'>알림설정을 활성화 해주세요.</p>
                <div className='relative'>
                  <div className='absolute top-[-0.5rem] left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    04
                  </div>
                  <img
                    className='min-w-[10rem] shadow-md'
                    src={'/notice_guide/ios_guide_4.png'}
                    alt={'ios_image'}
                  />
                </div>
                <p className='pt-6 pb-10'>
                  알림설정 후 앱을 끄고 재접속해주세요.
                </p>
              </div>
            ) : (
              <div className='flex flex-col items-center px-[3rem] font-semibold text-center'>
                <div className='relative'>
                  <div className='absolute top-8 left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    01
                  </div>
                  <img
                    className='min-w-[10rem] mt-[2.5rem] shadow-md'
                    src={'/notice_guide/android_guide_1.png'}
                    alt={'android_image'}
                  />
                </div>

                <p className='pt-2 pb-8'>
                  바탕화면에서 늘펫플러스를 <br />
                  길게 눌러 i로 이동해주세요
                </p>
                <div className='relative'>
                  <div className='absolute top-[-0.5rem] left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    02
                  </div>
                  <img
                    className='min-w-[10rem] shadow-md'
                    src={'/notice_guide/android_guide_2.png'}
                    alt={'android_image'}
                  />
                </div>
                <p className='pt-2 pb-8'>
                  애플리케이션 정보에서 알림을 눌러주세요.
                </p>
                <div className='relative'>
                  <div className='absolute top-[-0.5rem] left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    03
                  </div>
                  <img
                    className='min-w-[10rem] shadow-md'
                    src={'/notice_guide/android_guide_3.png'}
                    alt={'android_image'}
                  />
                </div>
                <p className='pt-2 pb-8'>알림표시를 활성화해주세요.</p>
                <div className='relative'>
                  <div className='absolute top-[-0.5rem] left-[-0.75rem] rounded-full text-xs w-7 h-7 bg-neutral-dark text-white flex justify-center items-center'>
                    04
                  </div>
                  <img
                    className='min-w-[10rem] shadow-md'
                    src={'/notice_guide/android_guide_4.png'}
                    alt={'android_image'}
                  />
                </div>
                <p className='pt-2 pb-8'>
                  알림설정 후 앱을 끄고 재접속해주세요.
                </p>
                <div className='pt-2 pb-8 flex justify-center w-full flex-col items-center'>
                  <div className='bg-primary rounded-full w-[1.5rem] h-[1.5rem] flex justify-center items-center pr-[1px] mb-4'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='3'
                      height='13'
                      viewBox='0 0 3 13'
                      fill='none'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M3 12C3 12.5523 2.55228 13 2 13L1.99 13C1.43772 13 0.99 12.5523 0.99 12C0.99 11.4477 1.43772 11 1.99 11L2 11C2.55228 11 3 11.4477 3 12ZM2 9C1.44772 9 1 8.55228 1 8L1 1C1 0.447716 1.44772 -1.35705e-07 2 -8.74228e-08C2.55229 -3.91405e-08 3 0.447715 3 1L3 8C3 8.55228 2.55229 9 2 9Z'
                        fill='white'
                      />
                    </svg>
                  </div>
                  <div className='mb-4 text-center text-sm font-normal'>
                    <b className='font-semibold'>
                      알림이 활성화 되어 있는데 알림이 안 온다면
                    </b>
                    <br />
                    <p className='text-xs'>
                      상단 주소 창의 자물쇠 모양 클릭 -{'>'} 권한 -{'>'} 알림
                      허용
                    </p>
                  </div>
                  <p className='text-sm'>
                    {isSamsungBrowser() ? '도움: ' : ''}
                    <button
                      onClick={() => {
                        if (isSamsungBrowser()) {
                          window.open(
                            'https://neulpet.channel.io/lounge',
                            '_blank',
                          );
                        } else {
                          window.open(
                            window.location.href,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }
                      }}
                      className='text-[#4B73FF] underline'
                    >
                      {isSamsungBrowser()
                        ? 'https://neulpet.channel.io/lounge'
                        : '브라우저로 이동'}
                    </button>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};
