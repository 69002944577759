import React from 'react';

const LoadingMessageBox = () => {
  return (
    <div
      data-drag-type='chat'
      className='mt-2.5 mb-2.5 text-left removeHighlight'
    >
      <div data-drag-type='chat' className={'pb-1 ml-4'}>
        <div
          data-drag-type='chat'
          className={'flex flex-col pt-1 items-start '}
        >
          <div className='flex items-center mb-1'>
            <div
              className={'w-[2rem] h-[2rem] rounded-full bg-secondary-light'}
            >
              <img src={'./vetty_small.png'} alt='profile_icon' />
            </div>
            <span className='ml-2 text-sm text-[#808495]'>{'챗봇 베티'}</span>
          </div>
          <div className={'ml-[2rem] max-w-3/4'}>
            <div
              className={
                'rounded-b-2xl rounded-r-2xl text-base bg-secondary-light text-neutral-dark'
              }
            >
              <ul
                data-drag-type='chat'
                className={'break-all whitespace-pre-wrap p-3 overflow-hidden'}
              >
                <div className={'px-2'}>
                  <div className={'dot-flashing'} />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const MemoizedLoadingMessageBox = React.memo(LoadingMessageBox);
