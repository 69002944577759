import { motion } from 'framer-motion';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import SelectRadioButton from 'src/features/SelectRadioButton';
import { getAgeFromDate, getDateFromAge } from 'src/utils/getAge';

export const InputDataModal = ({
  onNextClick,
  onCancelClick,
  selectedPetData,
  familyNames,
}) => {
  const containerRef = useRef(null);

  const petTypeList = ['개', '고양이', '기타'];
  const petGenderList = [
    '수컷',
    '중성화된 수컷',
    '암컷',
    '중성화된 암컷',
    '모름',
  ];
  const [isActiveButton, setActiveButton] = useState(false);
  const [familyName, setFamilyName] = useState('');
  const [inputPetName, setInputPetName] = useState('');
  const [petTypeNumber, setPetTypeNumber] = useState(-1);
  const [petGender, setPetGender] = useState('');
  const [petYear, setPetYear] = useState(0);
  const [petMonth, setPetMonth] = useState(0);
  const [petSpecies, setPetSpecies] = useState('');

  const handleChangeInputPetName = (event: {
    currentTarget: { value: string };
  }): void => {
    if (event.currentTarget.value.length > 10) {
      return;
    }
    setInputPetName(event.currentTarget.value);
  };
  const handleChangeFamilyName = (event: {
    currentTarget: { value: string };
  }): void => {
    if (event.currentTarget.value.length > 10) {
      return;
    }
    setFamilyName(event.currentTarget.value);
  };
  const handleChangeSpecies = (event: {
    currentTarget: { value: string };
  }): void => {
    if (event.currentTarget.value.length > 20) {
      return;
    }
    setPetSpecies(event.currentTarget.value);
  };
  const handleChangeYear = (event: {
    currentTarget: { value: string };
  }): void => {
    const inputValue = event.currentTarget.value;
    if (inputValue === '') {
      setPetYear(0);
    } else {
      const parsedValue = parseInt(inputValue, 10);
      if (isNaN(parsedValue)) {
        setPetYear(0);
      } else if (parsedValue > 20) {
        setPetYear(20);
      } else {
        setPetYear(parsedValue);
      }
    }
  };

  const handleChangeMonth = (event: {
    currentTarget: { value: string };
  }): void => {
    const inputValue = event.currentTarget.value;
    if (inputValue === '') {
      setPetMonth(0);
    } else {
      const parsedValue = parseInt(inputValue, 10);
      if (isNaN(parsedValue)) {
        setPetMonth(0);
      } else if (parsedValue > 11) {
        setPetMonth(11);
      } else {
        setPetMonth(parsedValue);
      }
    }
  };
  const handleClickBottomButton: MouseEventHandler<HTMLButtonElement> = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const petBirthday = getDateFromAge(petYear, petMonth);

    const petData = {
      petKey: '',
      petName: inputPetName,
      petType: petTypeNumber,
      petGender: petGender,
      petBirthday: petBirthday,
      petSpecies: petSpecies,
    };
    onNextClick(familyName, petData);
  };
  const onPetTypeClick = (typeNumber: number) => {
    setPetTypeNumber(typeNumber);
  };
  const onPetGenderClick = (gender: string) => {
    setPetGender(gender);
  };

  useEffect(() => {
    if (familyName && inputPetName && petTypeNumber !== -1 && petGender) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  }, [familyName, inputPetName, petTypeNumber, petGender]);

  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    setFirstLoad(true);
    if (firstLoad) {
      return;
    }
    if (familyNames && !/\d/.test(familyNames)) {
      setFamilyName(familyNames);
    } else {
      setFamilyName('');
    }
    if (selectedPetData) {
      setInputPetName(selectedPetData.petName || '');
      setPetTypeNumber(
        selectedPetData.petType !== undefined ? selectedPetData.petType : -1,
      );
      setPetGender(selectedPetData.petGender || '');
      setPetSpecies(selectedPetData.petSpecies || '');
      if (selectedPetData.petBirthday) {
        const { age, month } = getAgeFromDate(selectedPetData.petBirthday);
        setPetYear(age);
        setPetMonth(month);
      }
    } else {
      setInputPetName('');
      setPetTypeNumber(-1);
      setPetGender('');
      setPetYear(0);
      setPetMonth(0);
      setPetSpecies('');
    }
  }, [familyNames, selectedPetData]);

  return (
    <div>
      <div
        className={
          'absolute top-0 z-20 flex flex-row item-end justify-end w-full h-full bg-black opacity-50'
        }
      >
        <button
          className={'top-0 px-5 h-[4.5rem] removeHighlight'}
          onClick={onCancelClick}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
              fill='#ffffff'
            />
          </svg>
        </button>
      </div>
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        ref={containerRef}
        className='fixed bottom-0 w-full h-[calc(100%-4.5rem)] z-50'
      >
        <div className='flex flex-col w-full h-full bg-white text-neutral-dark overflow-y-auto'>
          {/* 1 */}
          <form className='w-full h-full flex flex-col px-6 overflow-y-auto text-neutral-dark'>
            <h1
              className={`text-xl whitespace-pre-line mt-8`}
            >{`진료정보를 입력해주세요.`}</h1>
            <div className='text-[#f38181] text-xs mt-[.375rem] font-normal leading-3 tracking-tight	'>
              * 필수항목
            </div>
            <p className={'w-full mt-6 text-base text-neutral-dark font-bold'}>
              <span className='text-[#f38181] mr-2'>*</span>보호자의 이름
            </p>
            <input
              type='text'
              className={`w-full mt-3 border-[1px] border-secondary-dark rounded-lg p-3 shadow-sm text-base text-neutral-dark placeholder:text-sm  placeholder:font-light read-only:bg-[#f6f8fe] ${
                familyNames && !/\d/.test(familyNames)
                  ? 'focus:outline-none'
                  : 'focus:border-primary '
              }`}
              value={familyName}
              onChange={handleChangeFamilyName}
              placeholder='정보를 입력해주세요'
              readOnly={familyNames && !/\d/.test(familyNames)}
            />
            <p className={'w-full mt-6 text-base text-neutral-dark font-bold'}>
              <span className='text-[#f38181] mr-2'>*</span>반려동물의 이름
            </p>
            <input
              type='text'
              className={`w-full mt-3 border-[1px] border-secondary-dark rounded-lg p-3 shadow-sm text-base text-neutral-dark placeholder:text-sm  placeholder:font-light read-only:bg-[#f6f8fe] ${
                selectedPetData?.petName
                  ? 'focus:outline-none'
                  : 'focus:border-primary '
              }`}
              value={inputPetName}
              onChange={handleChangeInputPetName}
              placeholder='정보를 입력해주세요'
              readOnly={selectedPetData?.petName}
            />
            <p className={'w-full mt-6 text-base text-neutral-dark font-bold'}>
              <span className='text-[#f38181] mr-2'>*</span>반려동물의 유형
            </p>
            <SelectRadioButton
              list={petTypeList}
              selectedValue={petTypeList[petTypeNumber]}
              onClickEvent={(event) => {
                event.preventDefault();
                const index = Number(event.currentTarget.dataset.index);
                onPetTypeClick(index);
              }}
              className={'mt-3 text-sm font-normal'}
              readOnly={selectedPetData?.petType !== undefined}
            />
            <p className={'w-full mt-2 text-base text-neutral-dark font-bold'}>
              <span className='text-[#f38181] mr-2'>*</span>반려동물의 성별
            </p>
            <SelectRadioButton
              list={petGenderList}
              selectedValue={petGender}
              onClickEvent={(event) => {
                event.preventDefault();
                const value = event.currentTarget.dataset.value || '';
                onPetGenderClick(value);
              }}
              className={'mt-3 text-sm font-normal'}
              readOnly={selectedPetData?.petGender}
            />
            <p className={'w-full mt-2 text-base text-neutral-dark font-bold'}>
              반려동물의 나이
            </p>
            <div className='flex items-center mt-3'>
              <input
                type='text'
                className={`w-full border-[1px] border-secondary-dark rounded-lg p-3 shadow-sm text-base text-neutral-dark placeholder:text-sm read-only:bg-[#f6f8fe]  ${
                  selectedPetData?.petBirthday
                    ? 'focus:outline-none'
                    : 'focus:border-primary '
                }`}
                placeholder='00'
                value={petYear}
                onChange={handleChangeYear}
                readOnly={selectedPetData?.petBirthday}
              />
              <div className='px-4 whitespace-nowrap'>년</div>
              <input
                type='text'
                className={`w-full border-[1px] border-secondary-dark rounded-lg p-3 shadow-sm text-base text-neutral-dark placeholder:text-sm read-only:bg-[#f6f8fe]  ${
                  selectedPetData?.petBirthday
                    ? 'focus:outline-none'
                    : 'focus:border-primary '
                }`}
                placeholder='00'
                value={petMonth}
                onChange={handleChangeMonth}
                readOnly={selectedPetData?.petBirthday}
              />
              <div className='px-4 whitespace-nowrap'>개월</div>
            </div>

            <p className={'w-full mt-6 text-base text-neutral-dark font-bold'}>
              반려동물의 품종
            </p>
            <input
              type='text'
              value={petSpecies}
              onChange={handleChangeSpecies}
              className={`w-full mt-3 border-[1px] border-secondary-dark rounded-lg p-3 shadow-sm text-base text-neutral-dark placeholder:text-sm read-only:bg-[#f6f8fe] ${
                selectedPetData?.petSpecies
                  ? 'focus:outline-none'
                  : 'focus:border-primary '
              }`}
              placeholder='정보를 입력해주세요'
              readOnly={selectedPetData?.petSpecies}
            />
            <div className={'w-full max-w-lg mt-8 mb-6 z-0'}>
              <button
                className='w-full bg-primary text-white py-3 rounded-lg shadow-sm disabled:bg-neutral-normal'
                onClick={handleClickBottomButton}
                disabled={!isActiveButton}
              >
                <span>
                  {isActiveButton
                    ? '저장 후 제출'
                    : '필수 항목을 입력해주세요.'}
                </span>
              </button>
            </div>
          </form>
          {/* 1 */}
        </div>
      </motion.div>
    </div>
  );
};
