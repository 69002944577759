import { useRef } from 'react';
import { Slide, toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import useLongPress from '../../app/hooks';
import { doCopy } from './doCopy';

const Linkify = ({ text, msgCard, position }): any => {
  const toastId = useRef(null);
  const toastOption: ToastOptions = {
    position: 'top-center',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    closeButton: false,
    draggable: true,
    transition: Slide,
  };
  const onLongPress = () => {
    doCopy(document, msgCard.text).then(() => {
      toastId.current = toast(`메시지가 복사 되었습니다.`, toastOption);
    });
  };
  const onClick = () => {
    console.log('click');
  };
  const longPressEvent = useLongPress(onLongPress, onClick, {
    shouldPreventDefault: true,
    delay: 500,
  });

  const RE_URL =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let match;
  const results = [];
  let lastIndex = 0;
  while ((match = RE_URL.exec(text))) {
    const link = match[0];
    if (lastIndex !== match.index) {
      const str = text.substring(lastIndex, match.index);
      results.push(<span key={results.length}>{str}</span>);
    }
    results.push(
      <a
        key={results.length}
        href={link.includes('http') ? link : `//${link}`}
        target='_blank'
        rel='noreferrer'
        style={{
          color: position === 'left' ? '' : '#4430E0',
          textDecoration: 'underline',
        }}
      >
        {link}
      </a>,
    );
    lastIndex = match.index + link.length;
  }
  if (results.length === 0) {
    return (
      <button className={'text-left'} {...longPressEvent}>
        <span key={results.length}>{text}</span>
      </button>
    );
  }
  if (lastIndex !== text.length) {
    results.push(<span key={results.length}>{text.substring(lastIndex)}</span>);
  }
  return results;
};

export default Linkify;
