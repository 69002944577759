import React, { useState } from 'react';

import DropdownItem from './DropdownItem';

interface DropdownProps {
  value: string;
  isDisable: boolean;
  items?: {
    text: string;
    value: string;
    isDisable: boolean;
  }[];
  onSelectItem?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function Dropdown({ value, isDisable, items, onSelectItem }: DropdownProps) {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

  const handleClickDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isDisable || !items.length) return;
    setIsOpenDropdown((prevState) => !prevState);
  };

  return (
    <div
      className={`relative flex h-12 items-center justify-between rounded-lg border border-solid border-secondary-dark ${
        isDisable || !items.length
          ? 'cursor-not-allowed bg-secondary-light'
          : 'cursor-pointer bg-white'
      }`}
      style={{
        boxShadow: '0px 2px 4px 0px rgba(233, 233, 240, 0.50)',
        padding: '0.75rem 0.6875rem 0.75rem 1rem',
      }}
      onClick={handleClickDropdown}
    >
      <p
        className={`font-normal ${
          isDisable || !items.length
            ? 'text-neutral-light'
            : 'text-neutral-dark'
        }`}
      >
        {value}
      </p>
      {isOpenDropdown ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.5893 13.0893C15.2639 13.4147 14.7362 13.4147 14.4108 13.0893L10 8.67855L5.5893 13.0893C5.26386 13.4147 4.73622 13.4147 4.41078 13.0893C4.08535 12.7639 4.08535 12.2362 4.41078 11.9108L9.41079 6.91078C9.73622 6.58535 10.2639 6.58535 10.5893 6.91078L15.5893 11.9108C15.9147 12.2362 15.9147 12.7639 15.5893 13.0893Z'
            fill='#B0AEBC'
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z'
            fill='#B0AEBC'
          />
        </svg>
      )}
      {isOpenDropdown && items.length && (
        <div
          className={
            'absolute left-0 overflow-y-auto w-full max-h-[11.75rem] flex flex-col items-start rounded-lg border border-solid border-secondary-dark bg-white'
          }
          style={{
            padding: '0.25rem 0.1875rem 0.25rem 0rem',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
            top: '2.75rem',
          }}
        >
          {items.map((item, index) => (
            <DropdownItem
              key={`${item.text}-${index}`}
              text={item.text}
              value={item.value}
              isDisable={item.isDisable}
              onClickItem={onSelectItem}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
