import dayjs from 'dayjs';

export const replaceVariable = (
  text: string,
  variable: {
    hospitalName?: string;
    petName?: string;
    vaccineName?: string;
    date?: number;
    timeFormat?: string;
  },
) => {
  const vaccineName = variable.vaccineName;
  const hospitalName = variable.hospitalName;
  const petName = variable.petName;
  const scheduledDate = dayjs(variable.date)
    .tz('Asia/Seoul')
    .format(variable.timeFormat);
  return text
    .replace(/\[병원 이름.*?\]/gi, hospitalName ? hospitalName : '')
    .replace(/\[병원이름.*?\]/gi, hospitalName ? hospitalName : '')
    .replace(/\[펫 이름.*?\]/gi, petName ? petName : '')
    .replace(/\[펫이름.*?\]/gi, petName ? petName : '')
    .replace(/\[백신 이름.*?\]/gi, vaccineName ? vaccineName : '')
    .replace(/\[백신이름.*?\]/gi, vaccineName ? vaccineName : '')
    .replace(/\[예약 날짜.*?\]/gi, scheduledDate ? scheduledDate : '')
    .replace(/\[예약날짜.*?\]/gi, scheduledDate ? scheduledDate : '');
};
