export enum DayEnum {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}
export enum DayKrEnum {
  SUNDAY = '일',
  MONDAY = '월',
  TUESDAY = '화',
  WEDNESDAY = '수',
  THURSDAY = '목',
  FRIDAY = '금',
  SATURDAY = '토',
}

export interface BusinessHourTime {
  day: DayEnum;
  startTime: string;
  endTime: string;
}

export enum BusinessHourTypeEnum {
  BUSINESS_HOURS = 'BusinessHour',
  OFF_HOURS = 'OffHour',
  BASIC_GUIDE = '',
  BREAK_TIME = 'BreakTime',
  LUNCH_BREAK = 'LunchBreak',
}
