import Image from 'next/image';
import React from 'react';

const VideoBox = ({ video, onClick }) => {
  return (
    <>
      <li key={video.link}>
        <button className='relative block w-full' onClick={onClick}>
          <div className='absolute z-10 w-full h-full bg-[#0000006e] flex justify-center items-center text-[3.75rem] text-white rounded-2xl overflow-hidden'>
            <p>{'▶'}</p>
          </div>
          {video.thumbnail ? (
            <Image
              loading='lazy'
              src={video.thumbnail}
              width={400}
              height={300}
              alt={video.name}
              layout='responsive'
              objectFit={'cover'}
              quality={1}
              unoptimized={true}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video className='w-full h-[300px]' preload='metadata'>
              <source src={video?.link} type={'video/' + video?.extension} />
            </video>
          )}
        </button>
      </li>
    </>
  );
};

export default VideoBox;
