import React from 'react';

const XIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001ZM8.08958 6.91083C7.76414 6.58539 7.23651 6.58539 6.91107 6.91083C6.58563 7.23626 6.58563 7.7639 6.91107 8.08934L8.82181 10.0001L6.91107 11.9108C6.58563 12.2363 6.58563 12.7639 6.91107 13.0893C7.23651 13.4148 7.76414 13.4148 8.08958 13.0893L10.0003 11.1786L11.9111 13.0893C12.2365 13.4148 12.7641 13.4148 13.0896 13.0893C13.415 12.7639 13.415 12.2363 13.0896 11.9108L11.1788 10.0001L13.0896 8.08934C13.415 7.7639 13.415 7.23626 13.0896 6.91083C12.7641 6.58539 12.2365 6.58539 11.9111 6.91083L10.0003 8.82157L8.08958 6.91083Z'
        fill='#F38181'
      />
    </svg>
  );
};

export default XIcon;
