import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { doCopy } from 'src/utils/copy';

export const A2HSModal = ({ hospitalName, location, onCancelClick }) => {
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      <div
        className={
          'absolute top-0 z-20 flex flex-row item-end justify-end w-full h-full bg-black opacity-50'
        }
      >
        <button
          className={'top-0 px-5 h-[4.5rem] removeHighlight'}
          onClick={onCancelClick}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M20 1.32074L18.6792 0L10 8.6792L1.32085 0L0 1.32074L8.67921 10L0 18.6793L1.32085 20L10 11.3208L18.6792 20L20 18.6793L11.3208 10L20 1.32074Z'
              fill='#ffffff'
            />
          </svg>
        </button>
      </div>
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        ref={containerRef}
        className='fixed bottom-0 w-full h-[calc(100%-4.5rem)] z-50'
      >
        <div className='relative flex flex-col items-center justify-start mb-10 w-full h-full bg-white text-neutral-dark overflow-y-auto overflow-x-hidden min-w-[15rem]'>
          <div className='flex items-center flex-col text-neutral-dark text-sm'>
            {/* <img className='w-10 h-full' src={'/logo.png'} alt={'ic_logo'} /> */}
            <p className='font-bold mt-4 text-lg'>
              {hospitalName} 즐겨찾기 가이드
            </p>
            {location === 'other' ? (
              <>
                <p className='mt-2 text-center text-sm'>
                  해당 브라우저에서 현재 URL 주소를
                  <br />
                  복사하여 사파리 브라우저로 이동해주세요.
                </p>
              </>
            ) : (
              <>
                <p className='mt-2 text-center text-sm'>
                  홈 화면에 늘펫 메신저 즐겨찾기를 추가하여
                  <br />
                  수의사의 메시지를 실시간 알림으로 확인하세요.
                </p>
              </>
            )}
          </div>
          {location === 'other' ? (
            <div className='flex-0.5 mt-4 w-full flex justify-center items-start'>
              <button
                className='w-[15rem] h-[3rem] text-white bg-primary rounded-lg'
                onClick={async () => {
                  const success = await doCopy();
                  if (success) {
                    alert(
                      '복사되었습니다.\n사파리 앱을 켜서 다시 접속해주세요.',
                    );
                  } else {
                    alert(
                      '복사에 실패하였습니다.\n주소창을 클릭 후 복사 해주세요.',
                    );
                  }
                }}
              >
                주소 복사하기
              </button>
            </div>
          ) : (
            <div
              className={`flex mt-4 justify-center w-[calc(100%-6rem)] pb-8 rounded-lg overflow-hidden`}
            >
              {isLoading && <div className='loader'></div>}
              <img
                className={`max-h-full object-contain ${
                  isLoading ? 'hidden' : 'border rounded-lg'
                }`}
                src='/a2hs_video.gif'
                alt='a2hs'
                onLoad={() => setIsLoading(false)}
              />
              {/* <ImageSlider location={location} /> */}
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};
