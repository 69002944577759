import React from 'react';

const HomeLogo = ({ className = '' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='155'
      height='70'
      viewBox='0 0 130 60'
      fill='none'
      className={className}
    >
      <g filter='url(#filter0_d_173_1621)'>
        <path
          d='M39.4023 24.3972H5.53804C4.13595 24.3972 3 25.5773 3 27.0312C3 28.4851 4.13595 29.5739 5.53804 29.5739H39.4023C40.8238 29.5739 41.9793 28.4329 41.9793 27.0312C41.9793 25.5512 40.8498 24.3972 39.4023 24.3972Z'
          fill='white'
        />
        <path
          d='M39.0129 45.2409H11.8669V43.1285H38.4157C39.5581 43.1285 40.4799 42.1766 40.4799 41.0096V34.5746C40.4799 33.3163 39.5192 32.3318 38.2859 32.3318H6.47281C5.2395 32.3318 4.27881 33.3163 4.27881 34.5746C4.27881 35.8329 5.2395 36.8239 6.47281 36.8239H33.1059V38.8907H6.5572C5.42125 38.8907 4.49302 39.8425 4.49302 41.0096V47.6206C4.49302 48.9506 5.51212 49.9938 6.81684 49.9938H39.0194C40.3241 49.9938 41.3432 48.9506 41.3432 47.6206C41.3432 46.2906 40.3241 45.2409 39.0194 45.2409H39.0129Z'
          fill='white'
        />
        <path
          d='M6.98554 21.639H38.4156C39.8112 21.639 40.9082 20.5372 40.9082 19.1354C40.9082 17.7337 39.8112 16.6319 38.4156 16.6319H11.8669V13.5154C11.8669 11.5269 10.283 10.0273 8.17342 10.0273C6.0638 10.0273 4.48645 11.5269 4.48645 13.5154V19.0963C4.48645 20.5502 5.55749 21.6456 6.97904 21.6456L6.98554 21.639Z'
          fill='white'
        />
        <path
          d='M68.9565 33.5246C70.5922 33.5246 71.3192 32.1163 71.3192 30.7211C71.3192 29.3259 70.5857 27.9176 68.9565 27.9176H68.2359V27.7546L68.6319 20.7198C68.6838 19.8527 68.4891 19.1811 68.0542 18.7182C67.5349 18.1575 66.6261 17.8772 65.3669 17.8772C63.1534 17.8772 62.1408 18.7574 62.1083 20.7263L61.933 27.9176H60.0961L59.9662 20.7263C59.9273 18.7574 58.9212 17.8772 56.7012 17.8772C55.4419 17.8772 54.5396 18.1575 54.0139 18.7182C53.579 19.1811 53.3907 19.8527 53.4426 20.7198L53.7932 27.9176H53.1181C51.4823 27.9176 50.7553 29.3259 50.7553 30.7211C50.7553 32.1163 51.4888 33.5246 53.1181 33.5246H68.963H68.9565Z'
          fill='white'
        />
        <path
          d='M53.54 16.7037H68.528C70.1638 16.7037 70.8908 15.2954 70.8908 13.9002C70.8908 12.5049 70.1638 11.0967 68.528 11.0967H53.54C51.9042 11.0967 51.1772 12.5049 51.1772 13.9002C51.1772 15.2954 51.9107 16.7037 53.54 16.7037Z'
          fill='white'
        />
        <path
          d='M85.6971 37.5994C87.7353 37.5994 89.2153 36.152 89.2153 34.1504V13.4763C89.2153 11.4747 87.7353 10.0273 85.6971 10.0273C83.6589 10.0273 82.2244 11.4747 82.2244 13.4763V34.1504C82.2244 36.152 83.6849 37.5994 85.6971 37.5994Z'
          fill='white'
        />
        <path
          d='M87.3199 43.9563C80.5431 43.9563 75.5579 41.7983 75.4671 38.8318V38.5254C75.4671 36.9541 73.8767 35.761 71.7801 35.761C69.6835 35.761 68.0931 36.9476 68.0931 38.5254V38.7405C68.0931 41.7657 63.1079 43.9628 56.2403 43.9628C54.65 43.9628 53.4037 45.2864 53.4037 46.9815C53.4037 48.6766 54.65 50.0001 56.2403 50.0001C63.4779 50.0001 68.8072 48.1942 71.6633 44.7778L71.7801 44.6409L71.8969 44.7843C74.7076 48.1942 80.0433 50.0001 87.3199 50.0001C88.9102 50.0001 90.1565 48.6701 90.1565 46.9815C90.1565 45.2929 88.9102 43.9628 87.3199 43.9628V43.9563Z'
          fill='white'
        />
        <path
          d='M71.9424 25.4987H73.4223V31.8293C73.4223 33.8048 74.8634 35.2327 76.8562 35.2327C78.8489 35.2327 80.3289 33.8309 80.3289 31.8293V13.6457C80.3289 11.6703 78.8684 10.2424 76.8562 10.2424C74.8439 10.2424 73.4223 11.7029 73.4223 13.6457V19.8069H71.9424C69.9626 19.8069 69.7484 21.7954 69.7484 22.656C69.7484 23.5167 69.9626 25.5052 71.9424 25.5052V25.4987Z'
          fill='white'
        />
        <path
          opacity='0.9'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M96.706 11.3311C96.706 13.9651 98.8546 16.1231 101.477 16.1231H103.217V17.8704C103.217 20.5044 105.365 22.6624 107.988 22.6624C110.61 22.6624 112.759 20.5044 112.759 17.8704V6.53906H101.477C98.8546 6.53906 96.706 8.6971 96.706 11.3311Z'
          fill='white'
        />
        <path
          opacity='0.8'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M119.269 11.3313C119.269 8.69734 117.121 6.53931 114.498 6.53931H112.759V4.79201C112.759 2.15803 110.61 0 107.988 0C105.365 0 103.217 2.15803 103.217 4.79201V16.1233H114.498C117.121 16.1233 119.269 13.9653 119.269 11.3313Z'
          fill='white'
        />
        <path
          d='M96.9137 25.6554V27.8982H94.9858V21.8088C94.9858 20.7265 95.3234 20.3223 96.4463 20.3223H98.0756C99.6984 20.3223 100.886 21.1307 100.886 22.9106V23.0736C100.886 24.8535 99.6984 25.6619 98.0756 25.6619H96.9137V25.6554ZM96.9137 24.019H97.9847C98.6144 24.019 98.9714 23.5495 98.9714 23.0214V22.9562C98.9714 22.4281 98.6144 21.9587 97.9847 21.9587H97.1863C96.9721 21.9587 96.9137 22.0044 96.9137 22.2325V24.019Z'
          fill='white'
        />
        <path
          d='M103.619 32.9312C102.496 32.9312 102.159 32.527 102.159 31.4447V26.2746C102.159 25.6422 102.626 25.3162 103.132 25.3162C103.639 25.3162 104.106 25.6487 104.106 26.2746V31.0079C104.106 31.2361 104.164 31.2817 104.379 31.2817H106.462C107.014 31.2817 107.222 31.7055 107.222 32.0902C107.222 32.4749 107.008 32.9312 106.462 32.9312H103.626H103.619Z'
          fill='white'
        />
        <path
          d='M113.667 25.1271C113.667 24.4882 114.115 24.1948 114.635 24.1948C115.154 24.1948 115.602 24.4882 115.602 25.1271V29.202C115.602 31.3014 113.933 31.9664 112.583 31.9664C111.233 31.9664 109.565 31.2948 109.565 29.202V25.1271C109.565 24.4882 110.013 24.1948 110.532 24.1948C111.051 24.1948 111.499 24.4882 111.499 25.1271V29.2802C111.499 29.9844 112.09 30.3364 112.583 30.3364C113.077 30.3364 113.667 29.9909 113.667 29.2802V25.1271Z'
          fill='white'
        />
        <path
          d='M120.788 21.2088L121.249 21.3718C122.346 21.7695 123.067 22.5845 122.995 23.8036C122.911 25.1402 121.859 25.9226 120.158 25.9226C119.328 25.9226 118.49 25.753 117.984 25.3488C117.627 25.0554 117.458 24.5469 117.737 24.1166C117.88 23.908 118.153 23.7841 118.406 23.7776C118.737 23.7711 118.893 23.921 119.074 24.0123C119.289 24.1427 119.581 24.26 120.217 24.26C120.73 24.26 121.041 24.084 121.048 23.6667C121.054 23.3081 120.438 23.0343 119.659 22.7931C119.509 22.7474 119.321 22.6692 119.211 22.6236C117.796 22.0303 117.484 20.7394 117.802 19.7549C118.133 18.7508 119.048 18.1641 120.334 18.1641C121.307 18.1641 121.768 18.3531 122.112 18.5422C122.541 18.7769 122.846 19.2463 122.703 19.7027C122.58 20.0874 122.255 20.2373 121.898 20.2373C121.755 20.2373 121.554 20.1787 121.47 20.1135C121.242 19.9635 120.918 19.8331 120.353 19.8331C119.905 19.8331 119.633 20.0091 119.62 20.4003C119.613 20.7459 120.042 20.9741 120.788 21.2283V21.2088Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_173_1621'
          x='0'
          y='0'
          width='130'
          height='60.0001'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='5' />
          <feGaussianBlur stdDeviation='2.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_173_1621'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_173_1621'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HomeLogo;
