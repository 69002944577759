export const ArrowSvg = ({
  rotate = 'rotate-0',
  color = 'text-trout-500',
  width = 16,
  height = 16,
}: {
  rotate?: string;
  color?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 10 16'
      className={`transition transform fill-current ${rotate}`}
    >
      <path
        d='M8.75556 16L10 14.6667L3.42222 8L10 1.33333L8.75556 -1.08793e-07L0.755557 8L8.75556 16Z'
        className={`fill-current ${color}`}
      />
    </svg>
  );
};
