import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { createRipples } from 'react-ripples';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
const MyRipples = createRipples({
  color: 'rgba(0, 0, 0, .2)',
  during: 600,
});

import { CheckBox } from '../../chatting/components/CheckBox';
import { addQNAInfo, selectQNAInfoList } from '../slice/QNASlice';
import { QNA } from './QNADataType';
const SymptomsQna = ({
  itemList,
  selectedList,
  onItemClick,
}: {
  itemList: any[];
  selectedList?: any[];
  onItemClick: (itemList) => void;
}) => {
  const [innerSelectedList, setInnerSelectedList] = useState([]);
  const handleClickEvent = {
    itemClick: (event) => {
      const index = event.currentTarget.dataset.index;
      if (_.isString(index)) {
        const selectedIndex = innerSelectedList.indexOf(parseInt(index));
        if (selectedIndex === -1) {
          innerSelectedList.push(parseInt(index));
        } else {
          innerSelectedList.splice(selectedIndex, 1);
        }
        onItemClick(
          innerSelectedList.map((selectedIndex) => {
            return itemList[selectedIndex];
          }),
        );
        setInnerSelectedList(_.cloneDeep(innerSelectedList));
      }
    },
  };
  useEffect(() => {
    if (selectedList) {
      const list = selectedList.reduce((arr, selected) => {
        const index = itemList.indexOf(selected);
        if (index !== -1) {
          arr.push(index);
        }
        return arr;
      }, []);
      setInnerSelectedList(list);
    } else {
      setInnerSelectedList([]);
    }
  }, [selectedList]);
  // useEffect(() => {
  //   if (qnaInfoList.length !== 0) {
  //     const qna = qnaInfoList[0];
  //     qna.selectedAnswerList.map((answer) => {
  //       const index = qna.answerList.indexOf(answer);
  //       if (index !== -1 && selectedList[index] === undefined) {
  //         selectedList.push(index);
  //       }
  //     });
  //     setSelectedList(_.cloneDeep(selectedList));
  //   }
  // }, []);
  return (
    <div className='onScrollbar'>
      {/*<div className='text-[1rem] font-semibold text-neutral-dark pr-5 pl-5'>*/}
      {/*  <p className='break-all whitespace-pre-wrap'>*/}
      {/*    {qnaInfoList.length !== 0 && `${qnaInfoList[0].question}`}*/}
      {/*  </p>*/}
      {/*</div>*/}
      <ul className='pr-5 pl-5'>
        {itemList &&
          itemList.length !== 0 &&
          itemList.map((item, index) => {
            return (
              <li
                className={`overflow-hidden border-b border-secondary-light`}
                key={index.toString()}
              >
                <MyRipples className='w-full h-full'>
                  <button
                    className='relative p-5 text-left w-full h-full removeHighlight'
                    onClick={handleClickEvent.itemClick}
                    data-index={index}
                  >
                    <div className={'w-full flex flex-row items-center gap-3'}>
                      <CheckBox
                        checked={innerSelectedList.indexOf(index) !== -1}
                      />
                      <span
                        className={`text-base ${
                          innerSelectedList.indexOf(index) !== -1
                            ? 'text-primary'
                            : 'text-neutral-normal'
                        }`}
                      >
                        {item}
                      </span>
                    </div>
                  </button>
                </MyRipples>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SymptomsQna;
