import React from 'react';
import HomeLogo from 'src/features/icons/HomeLogo';

const RedirectingPage = ({ isRedirectingLoading, hospitalName }) => {
  return (
    <div className='fixed top-0 bg-primary w-full h-full overflow-hidden z-50'>
      <div className='relative w-full h-full flex flex-col justify-center items-center pb-[12.5rem]'>
        <div
          className={`font-bold mb-3 px-6 text-center text-white ${
            hospitalName ? 'text-2xl' : 'text-lg '
          }`}
        >
          {hospitalName ? hospitalName : '동물병원 메신저로 이동 중입니다..'}
        </div>
        <HomeLogo />
        {isRedirectingLoading ? (
          <div className='absolute bottom-14 w-full flex justify-center'>
            <button
              onClick={() => {
                const userAgent = navigator.userAgent.toLowerCase();
                const target_url = location.href;
                if (
                  userAgent.match(/kakaotalk/i) &&
                  /iphone|ipad/i.test(userAgent)
                ) {
                  try {
                    location.href =
                      'kakaotalk://web/openExternal?url=' +
                      encodeURIComponent(target_url);
                  } catch (error) {
                    console.error('Redirection failed', error);
                    location.reload();
                  }
                } else {
                  location.reload();
                }
              }}
              className='w-full mx-10 bg-white text-neutral-dark text-center py-3 font-bold rounded-lg'
            >
              메신저로 이동하기
            </button>
          </div>
        ) : (
          <div className='loader-white'></div>
        )}
      </div>
    </div>
  );
};

export default RedirectingPage;
