import { captureException } from '@sentry/core';
import axios from 'axios';
import dayjs from 'dayjs';
import firebase from 'firebase';
import _ from 'lodash';
import { replaceVariable } from 'src/features/util/replaceVariable';

import {
  getDayOfWeekName,
  getHolidayForMonth,
} from '../../../utils/getHolidayUtils';
import { addSuffix, checkBatchimEnding } from '../../util/nameFormatter';
import { MessageDTO } from '../components/messageBox/MessageDTO';
import { messageDataType } from '../components/messageBox/messageType';
import { ChatBotData, MessageDataActions } from '../slices/chattingTypes';
import {
  addMessage,
  getWaitMessageInfo,
  updateMessage,
} from './ChattingService';
export const addRecommendGuideMessage = async (
  stackedMessages: MessageDTO[],
  recommendContents,
  globalInfo,
) => {
  const hospitalName = globalInfo.hospitalName;
  const hospitalKey = globalInfo.hospitalKey;
  const chatKey = globalInfo.chatKey;
  const hospitalRef = firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey);
  const chatRef = hospitalRef.collection('Chat').doc(chatKey);
  return await firebase.firestore().runTransaction(async (transaction) => {
    const chatBotRef = chatRef.collection('ChatBot').doc();
    const messageRef = firebase
      .firestore()
      .collection('Hospital')
      .doc(hospitalKey)
      .collection('Chat')
      .doc(chatKey)
      .collection('Message');
    const message = stackedMessages
      .map((message) => {
        return message.text;
      })
      .join(' ');
    const firstMessageRef = messageRef.doc();
    const firstMessageDto = new MessageDTO({
      key: firstMessageRef.id,
      writer: hospitalName,
      writerType: 'bot_recommend_guide',
      text:
        '해당 내용을 원장님께 전달했습니다.\n' +
        '그동안 증상 관련하여 챗봇 베티가 준비한 답변을 보시겠습니까? 베티를 이용해서 78%의 보호자님들이 응급상황을 예방했습니다.\n',
      actions: [
        {
          name: '네',
          type: 'button',
          link: 'internal://bot_guide_action',
          data: {
            read: false,
            message: message,
            recommendContents: recommendContents,
          },
        },
        {
          name: '아니요',
          type: 'button',
          link: 'internal://bot_guide_action',
          data: {
            read: false,
            message: message,
            recommendContents: recommendContents,
          },
        },
      ],
    });
    await transaction.set(firstMessageRef, firstMessageDto.toPlainObj());
    const recognizedWords = recommendContents.reduce((arr, contents) => {
      const recognizedWordResult = contents.recognizedWordResult;
      return _.uniq(arr.concat(recognizedWordResult));
    }, []);
    const chatBotData = createChatBotData(
      chatBotRef.id,
      globalInfo,
      messageRef.id,
      recognizedWords,
      message,
      firstMessageDto.text,
      recommendContents,
    );
    await transaction.set(chatBotRef, chatBotData);
    if (recognizedWords) {
      for (const stackedMessage of stackedMessages) {
        const originMessageRef = chatRef
          .collection('Message')
          .doc(stackedMessage.key);
        await transaction.update(originMessageRef, {
          recognizedWordList: recognizedWords,
        });
      }
    }
  });
};
export const addRecommendContentsMessage = async (
  message: string,
  globalInfo,
  recommendContents,
  reactMessage,
  isQna,
  chatBotKey?: string,
) => {
  const hospitalName = globalInfo.hospitalName;
  const hospitalKey = globalInfo.hospitalKey;
  const chatKey = globalInfo.chatKey;

  const hospitalRef = firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey);
  const chatRef = hospitalRef.collection('Chat').doc(chatKey);
  return await firebase.firestore().runTransaction(async (transaction) => {
    const messageRef = firebase
      .firestore()
      .collection('Hospital')
      .doc(hospitalKey)
      .collection('Chat')
      .doc(chatKey)
      .collection('Message');
    const firstMessageRef = messageRef.doc();
    const secondMessageRef = messageRef.doc();
    const thirdMessageRef = messageRef.doc();

    // await transaction.set(firstMessageRef, firstMessageDto.toPlainObj());
    if (recommendContents && recommendContents.length > 0) {
      const recognizedWords = recommendContents.reduce((arr, contents) => {
        const recognizedWordResult = contents.recognizedWordResult;
        return _.uniq(arr.concat(recognizedWordResult));
      }, []);
      const contentsMessageRef = messageRef.doc();
      const chatBotRef = chatRef.collection('ChatBot').doc();
      let firstMessageDto;
      if (isQna) {
        firstMessageDto = new MessageDTO({
          writer: `${hospitalName}`,
          key: firstMessageRef.id,
          writerType: 'bot_react',
          text: `문진 내용과 관련된 콘텐츠들을 찾아봤어요.`,
          actions: [
            ...getActions(
              recommendContents,
              chatBotKey ? chatBotKey : chatBotRef.id,
            ),
          ],
          createdDate: dayjs().add(2, 's').valueOf(),
        });
      } else {
        firstMessageDto = new MessageDTO({
          writer: `${hospitalName}`,
          writerType: 'bot_contents',
          key: firstMessageRef.id,
          text: recommendContents[0].description
            ? recommendContents[0].description
            : `"${recommendContents[0].text}"에 대한 콘텐츠를 가져왔어요.`,
          createdDate: dayjs().add(2, 'second').valueOf(),
        });
        if (recommendContents[0].link) {
          firstMessageDto.actions = [
            {
              name: '자세히 알아보기',
              type: 'button',
              link: recommendContents[0].link,
              data: {
                read: false,
                contentsName: recommendContents[0].text,
                templateKey: recommendContents[0].key,
                chatBotKey: chatBotKey ? chatBotKey : chatBotRef.id,
              },
            },
          ];
        }
        const extraContentList = _.cloneDeep(recommendContents);
        extraContentList.shift();
        const secondMessageDto = new MessageDTO({
          writer: `${hospitalName}`,
          writerType: 'bot_contents',
          key: secondMessageRef.id,
          text: `위의 내용이 문제 해결에 도움이 되었나요?`,
          actions: [
            {
              name: '네😄',
              type: 'button',
              link: 'internal://bot_recommend_action',
              data: {
                isHelpful: true,
                messageKey: secondMessageRef.id,
                chatBotKey: chatBotKey ? chatBotKey : chatBotRef.id,
                templateKey: secondMessageRef.id,
              },
            },
            {
              name: '아니요😥',
              type: 'button',
              link: 'internal://bot_recommend_action',
              data: {
                isHelpful: false,
                messageKey: secondMessageRef.id,
                chatBotKey: chatBotKey ? chatBotKey : chatBotRef.id,
                templateKey: secondMessageRef.id,
                contentList: extraContentList,
              },
            },
          ],
          createdDate: dayjs().add(3, 'second').valueOf(),
        });
        await transaction.set(
          chatRef,
          {
            lastReadTimeInfo: {
              nok: dayjs().valueOf(),
            },
            recentMessage: {
              createdDate: new Date().valueOf(),
              key: contentsMessageRef.id,
              read: false,
              text: secondMessageDto.text,
              writer: secondMessageDto.writer,
              writerType: 'chat_bot',
            },
          },
          { merge: true },
        );
        // await transaction.set(thirdMessageRef, thirdMessageDto.toPlainObj());
        await transaction.set(secondMessageRef, secondMessageDto.toPlainObj());
      }
      await transaction.set(firstMessageRef, firstMessageDto.toPlainObj());
      if (!chatBotKey) {
        const chatBotData = createChatBotData(
          chatBotRef.id,
          globalInfo,
          messageRef.id,
          recognizedWords,
          message,
          firstMessageDto.text,
          recommendContents,
        );
        await transaction.set(chatBotRef, chatBotData);
      }
      const updateChatBotCount = {} as any;
      updateChatBotCount[dayjs().format('YYYY-MM')] =
        firebase.firestore.FieldValue.increment(1);
      transaction.set(
        hospitalRef,
        {
          chatBotCount: updateChatBotCount,
        },
        { merge: true },
      );
    } else {
      const waitMessageInfo = getWaitMessageInfo(
        hospitalName,
        hospitalKey,
        chatKey,
        reactMessage,
        3,
      );
      await transaction.set(
        waitMessageInfo.ref,
        waitMessageInfo.dto.toPlainObj(),
      );
      // const secondMessageDto = new MessageDTO({
      //   writer: `${hospitalName} 챗봇`,
      //   writerType: 'bot_contents',
      //   key: secondMessageRef.id,
      //   text: `말씀하신 내용을 이해하지 못했어요😢 더 열심히 공부해올게요!`,
      //   createdDate: dayjs().add(2, 'second').valueOf(),
      // });
      // await transaction.set(secondMessageRef, secondMessageDto.toPlainObj());
    }
    return !(recommendContents && recommendContents.length > 0);
  });
};

export const isActiveChatBotTime = async (
  chatBotFullTimeActive,
  activeChatBotOnPublicHoliday,
  chatBotActiveHoursInfo,
) => {
  if (chatBotFullTimeActive) {
    return true;
  }
  const today = dayjs();
  const holidayInThisMonth = await getHolidayForMonth(
    today.year(),
    today.month() + 1,
  );
  const filteredHoliday = holidayInThisMonth?.filter((holiday) => {
    return holiday.dayOfMonth === today.date();
  });
  if (filteredHoliday?.length > 0) {
    return false;
  }
  const dayOfWeekToday = today.day();
  if (!chatBotActiveHoursInfo) {
    return false;
  }
  const todayActiveHoursInfo =
    chatBotActiveHoursInfo[getDayOfWeekName(dayOfWeekToday)];
  if (!todayActiveHoursInfo) {
    return false;
  }
  const openTime = dayjs(
    `${today.year()}-${today.month() + 1}-${today.date()} ${
      todayActiveHoursInfo.openTime
    }`,
  ).valueOf();
  const closeTime = dayjs(
    `${today.year()}-${today.month() + 1}-${today.date()} ${
      todayActiveHoursInfo.closeTime
    }`,
  ).valueOf();
  return today.valueOf() >= openTime && today.valueOf() <= closeTime;
};

export const getRecommendContents = async (
  hospitalKey: string,
  message: string,
  contentsMessageTemplates: any[],
) => {
  if (message.trim().length === 0) {
    return [];
  }
  console.log('version', process.env.CHAT_BOT_VERSION);
  const response = await axios.post('message/recommendContentsWithTemplate', {
    hospitalKey: hospitalKey,
    chatMessage: message,
    version: process.env.CHAT_BOT_VERSION,
    contentsMessageTemplates: contentsMessageTemplates,
  });
  return [...response.data];
};

export const addNextActionBotMessage = async (
  globalInfo,
  messages,
  messageData,
  messageAction: MessageDataActions,
  contentsMessageTemplates,
) => {
  //Todo 챗봇 데이터 업데이트
  const actionName = messageAction.name;
  const contentsName = messageAction.data.contentsName;
  const chatBotKey = messageAction.data.chatBotKey;
  const templateKey = messageAction.data.templateKey;
  const nextActionBotMessage = getNextActionBotMessage(
    globalInfo.hospitalName,
    globalInfo.petInfo.name,
    messageData,
    contentsName,
    templateKey,
    contentsMessageTemplates,
    chatBotKey,
  );
  const nokMessageDto = new MessageDTO({
    writer: globalInfo.familyInfo.name,
    writerType: 'nok',
    text: actionName,
  });
  if (nextActionBotMessage) {
    updateChatBotData(globalInfo, messageAction);
    await updateMessage(globalInfo, messageData);
    return addMessage(
      messages,
      null,
      globalInfo,
      [nokMessageDto, nextActionBotMessage],
      true,
    );
  }
  return undefined;
};

export const addNoAnswerBotMessage = async (globalInfo, messages, message) => {
  const noAnswerNokMessage = new MessageDTO({
    writer: globalInfo.familyInfo.name,
    writerType: 'nok',
    text: '원하는 답변이 없어요',
    createdDate: dayjs().valueOf(),
  });
  const noAnswerBotMessage = new MessageDTO({
    writer: globalInfo.hospitalName,
    writerType: 'bot_no_answer',
    text: '원하는 답변이 없으시군요. 말씀하신 내용들을 수의사님께 전달해드리도록 하겠습니다.',
    createdDate: dayjs().add(1500, 'milliseconds').valueOf(),
  });
  updateChatBotData(globalInfo, {
    link: '',
    name: '',
    type: '',
    data: {
      chatBotKey: message.actions[0].data.chatBotKey,
      noAnswer: true,
    },
  });
  addMessage(
    messages,
    null,
    globalInfo,
    [noAnswerNokMessage, noAnswerBotMessage],
    true,
  );
};
export const updateChatBotData = async (
  globalInfo,
  messageAction: MessageDataActions,
) => {
  if (messageAction.data === undefined) {
    return;
  }
  const chatBotKey = messageAction.data.chatBotKey;
  if (!chatBotKey) {
    return;
  }
  return await firebase.firestore().runTransaction(async (transaction) => {
    const chatRef = firebase
      .firestore()
      .collection('Hospital')
      .doc(globalInfo.hospitalKey)
      .collection('Chat')
      .doc(globalInfo.chatKey);
    const chatBotRef = chatRef.collection('ChatBot').doc(chatBotKey);
    const chatBotData = (await transaction.get(chatBotRef)).data();
    const updatedChatBotData = _.cloneDeep(chatBotData);
    if (!updatedChatBotData.contentInfo) {
      updatedChatBotData.contentInfo = _.cloneDeep(updatedChatBotData);
      delete updatedChatBotData.contentInfo.createdDate;
      delete updatedChatBotData.contentInfo.familyInfo;
      delete updatedChatBotData.contentInfo.key;
      delete updatedChatBotData.contentInfo.hospitalInfo;
      delete updatedChatBotData.contentInfo.petInfo;
      delete updatedChatBotData.messageKey;
      delete updatedChatBotData.contentList;
      delete updatedChatBotData.totalDuration;
      delete updatedChatBotData.totalClickedCount;
      delete updatedChatBotData.totalTemplateCount;
      delete updatedChatBotData.totalHelpfulCount;
      delete updatedChatBotData.totalUnHelpfulCount;
      delete updatedChatBotData.totalTelClickedCount;
      delete updatedChatBotData.recognizedWordList;
      delete updatedChatBotData.userMessage;
      delete updatedChatBotData.message;
    }
    const contentIndex = updatedChatBotData.contentInfo.contentList.findIndex(
      (content) => content.key === messageAction.data.templateKey,
    );
    if (contentIndex === -1) {
      if (messageAction.data.noAnswer) {
        updatedChatBotData.contentInfo.noAnswer = messageAction.data.noAnswer;
      }
    } else {
      const content = updatedChatBotData.contentInfo.contentList[contentIndex];
      if (messageAction.data.duration) {
        content.duration = messageAction.data.duration;
        updatedChatBotData.contentInfo.totalDuration =
          updatedChatBotData.contentInfo.contentList.reduce(
            (totalDuration, content) => {
              if (content.duration) {
                return totalDuration + content.duration;
              }
              return totalDuration;
            },
            0,
          );
      }
      if (messageAction.data.isHelpful !== undefined) {
        content.isHelpful = messageAction.data.isHelpful;
        if (messageAction.data.isHelpful) {
          updatedChatBotData.contentInfo.totalHelpfulCount =
            updatedChatBotData.contentInfo.contentList.filter(
              (content) => content.isHelpful,
            ).length;
        } else {
          updatedChatBotData.contentInfo.totalUnHelpfulCount =
            updatedChatBotData.contentInfo.contentList.filter(
              (content) =>
                content.isHelpful !== undefined && !content.isHelpful,
            ).length;
        }
      }
      if (messageAction.data.isTelClick) {
        content.isTelClick = messageAction.data.isTelClick;
        updatedChatBotData.contentInfo.totalTelClickedCount =
          updatedChatBotData.contentInfo.contentList.filter(
            (content) => content.isTelClick,
          ).length;
      }
      if (messageAction.data) {
        content.isClick = true;
        updatedChatBotData.contentInfo.totalClickedCount =
          updatedChatBotData.contentInfo.contentList.filter(
            (content) => content.isClick,
          ).length;
      }
    }
    await transaction.set(chatBotRef, updatedChatBotData);
    return updatedChatBotData;
  });
};
const getActions = (contentsMessageTemplates, chatBotKey) => {
  return contentsMessageTemplates.map((contentsMessageTemplate) => {
    return {
      name: contentsMessageTemplate.text,
      link: contentsMessageTemplate.link,
      type: 'list',
      data: {
        chatBotKey: chatBotKey,
        templateKey: contentsMessageTemplate.key,
        description: contentsMessageTemplate.description
          ? contentsMessageTemplate.description
          : `"${
              checkBatchimEnding(contentsMessageTemplate.text)
                ? contentsMessageTemplate.text + '"과'
                : contentsMessageTemplate.text + '"와'
            } 관련된 콘텐츠를 준비했어요. 링크로 이동하여 확인해 보시겠어요?`,
      },
    };
  });
};
export const addDescriptionBotMessage = async (
  globalInfo,
  messageData,
  messageAction,
  messages,
) => {
  //Todo 챗봇 데이터 업데이트
  const data = messageAction.data;
  const description = data.description;
  const chatBotKey = data.chatBotKey;
  const link = messageAction.link.toString();
  const addMessageDtoList = [];
  const nokMessageDto = new MessageDTO({
    writer: globalInfo.familyInfo.name,
    writerType: 'nok',
    text: messageAction.name,
  });
  const messageDto = new MessageDTO({
    writer: `${globalInfo.hospitalName}`,
    writerType: 'bot_description',
    text: description,
    createdDate: dayjs().add(1, 'second').valueOf(),
  });
  addMessageDtoList.push(nokMessageDto);
  addMessageDtoList.push(messageDto);
  if (link) {
    messageDto.writerType += '_link';
    messageDto.actions = [
      {
        name: '자세히 알아보기',
        type: 'button',
        link: link,
        data: {
          read: false,
          contentsName: messageAction.name,
          templateKey: data.templateKey,
          chatBotKey: chatBotKey ? chatBotKey : '',
        },
      },
    ];
  } else {
    const helpfulMessageDto = new MessageDTO({
      writer: `${globalInfo.hospitalName}`,
      writerType: 'bot_react',
      text: `방금 읽으신 ${messageAction.name} 콘텐츠가 도움이 되셨나요?`,
      createdDate: dayjs().add(2, 'second').valueOf(),
      actions: [
        {
          name: '네😄',
          type: 'button',
          link: 'internal://bot_action',
          data: {
            isHelpful: true,
            messageKey: messageData.key,
            templateKey: data.templateKey,
            chatBotKey: chatBotKey ? chatBotKey : '',
          },
        },
        {
          name: '아니요😥',
          type: 'button',
          link: 'internal://bot_action',
          data: {
            isHelpful: false,
            messageKey: messageData.key,
            templateKey: data.templateKey,
            chatBotKey: chatBotKey ? chatBotKey : '',
          },
        },
      ],
    });
    addMessageDtoList.push(helpfulMessageDto);
  }
  messageAction.data.read = true;
  updateMessage(globalInfo, messageData);
  updateChatBotData(globalInfo, messageAction);
  addMessage(messages, null, globalInfo, addMessageDtoList, true);
};

export const updateTelClick = (globalInfo, messageAction, messageData) => {
  if (messageAction.data === undefined) {
    messageAction.data = {};
  }
  // messageAction.data.read = true;
  messageAction.data.isTelClick = true;
  updateMessage(globalInfo, messageData);
  updateChatBotData(globalInfo, messageAction);
};

export const getNextActionBotMessage = (
  hospitalName: string,
  petName: string,
  messageData: messageDataType,
  contentsName: string,
  templateKey: string,
  messageTemplates: any[],
  chatBotKey,
) => {
  if (isOldChatBotContentsData(messageData.actions)) {
    return undefined;
  }
  const templateKeys = messageData.actions
    .filter((action) => {
      return action.data.templateKey && action.data.read;
    })
    .map((action) => {
      return action.data.templateKey;
    });
  const readRecommendedTemplates = messageTemplates.filter(
    (messageTemplate) => {
      return templateKeys.includes(messageTemplate.key);
    },
  );
  const templateCategories = readRecommendedTemplates.map((messageTemplate) => {
    return messageTemplate.category;
  });
  const isLightContents = templateCategories.every((category) => {
    return category !== 'symptoms' && category !== 'disease';
  });
  const sumContentsPoint = readRecommendedTemplates.reduce(
    (sum, messageTemplate) => {
      return sum + messageTemplate.point;
    },
    0,
  );
  const messageDto = new MessageDTO({
    writer: `${hospitalName}`,
    writerType: 'bot_react',
    text: '',
    createdDate: dayjs().add(1, 's').valueOf(),
  });
  if (isLightContents) {
    messageDto.text += `방금 읽으신 ${contentsName} 콘텐츠가 도움이 되셨나요?`;
    messageDto.actions = [
      {
        name: '네😄',
        type: 'button',
        link: 'internal://bot_action',
        data: {
          isHelpful: true,
          messageKey: messageData.key,
          chatBotKey: chatBotKey ? chatBotKey : '',
          templateKey: templateKey,
        },
      },
      {
        name: '아니요😥',
        type: 'button',
        link: 'internal://bot_action',
        data: {
          isHelpful: false,
          messageKey: messageData.key,
          chatBotKey: chatBotKey ? chatBotKey : '',
          templateKey: templateKey,
        },
      },
    ];
  } else {
    messageDto.text = `${
      readRecommendedTemplates.length === 1
        ? `방금 읽으신 ${contentsName} 콘텐츠가 도움이 되셨나요?`
        : `${readRecommendedTemplates.length}개의 콘텐츠를 선택하셨군요`
    }\n`;
    if (readRecommendedTemplates.length === 1) {
      if (sumContentsPoint < 2) {
        messageDto.text += `더 궁금한 점이 있으시면 ${addSuffix(
          hospitalName,
          '으',
        )}로 문의 주세요😄`;
      } else if (sumContentsPoint === 2) {
        messageDto.text += `🤔${addSuffix(
          petName,
          '이',
        )}의 증상이 이와 비슷하다면, 정확한 진단을 위해 동물병원에 방문해보시는 것을 추천드려요~${addSuffix(
          hospitalName,
          '',
        )}에 바로 문의하시겠어요?`;
        messageDto.actions = [
          {
            name: '네,전화 문의할게요',
            type: 'button',
            link: 'tel://',
            data: {
              chatBotKey: chatBotKey ? chatBotKey : '',
              templateKey: templateKey,
            },
          },
        ];
      } else {
        messageDto.text += `🤔${addSuffix(
          petName,
          '이',
        )}의 증상이 이와 비슷하다면, 위험한 상태일 수 있으니 최대한 빨리 동물병원에 방문해보시는 것이 좋을 것 같아요😢${addSuffix(
          hospitalName,
          '',
        )}에 바로 문의하시겠어요?`;
        messageDto.actions = [
          {
            name: '네, 전화 문의할게요.',
            type: 'button',
            link: 'tel://',
            data: {
              chatBotKey: chatBotKey ? chatBotKey : '',
              templateKey: templateKey,
            },
          },
        ];
      }
    } else {
      messageDto.text += `🤔${addSuffix(
        petName,
        '이',
      )}의 증상이 이와 비슷하다면, 정확한 진단을 위해 동물병원에 방문해보시는 것을 추천드려요😥${addSuffix(
        hospitalName,
        '',
      )}에 바로 문의하시겠어요?`;
      messageDto.actions = [
        {
          name: '네, 전화 문의할게요.',
          type: 'button',
          link: 'tel://',
          data: {
            chatBotKey: chatBotKey ? chatBotKey : '',
            templateKey: templateKey,
          },
        },
      ];
    }
  }
  return messageDto;
};

const getNextActionNotHelpfulBotMessage = (
  hospitalName: string,
  messageAction: any,
) => {
  const templateKey = messageAction.data.templateKey;
  const chatBotKey = messageAction.data.chatBotKey;
  return new MessageDTO({
    writer: `${hospitalName}`,
    writerType: 'bot_react',
    text: `도움을 드리지 못해 아쉽네요. 더 유익한 콘텐츠를 제공해드리기 위해 최선을 다하겠습니다️! 궁금하신 내용은 아래 버튼을 눌러 전화로 문의해주세요.`,
    actions: [
      {
        name: '네, 전화 문의할게요.',
        type: 'button',
        link: 'tel://',
        data: {
          templateKey: templateKey,
          chatBotKey: chatBotKey,
        },
      },
    ],
    createdDate: dayjs().add(2, 's').valueOf(),
  });
};
const getNextActionNotHelpfulBotMessageByRecommend = (
  hospitalName: string,
  messageAction: any,
) => {
  const chatBotKey = messageAction.data.chatBotKey;
  const contentList = messageAction.data.contentList;
  console.log('contentlist', contentList);
  if (contentList && contentList.length > 0) {
    return new MessageDTO({
      writer: `${hospitalName}`,
      writerType: 'bot_react',
      text: `문의하신 내용과 관련된 다른 콘텐츠들을 더 찾아봤어요.`,
      actions: [...getActions(contentList, chatBotKey)],
      createdDate: dayjs().add(2, 's').valueOf(),
    });
  } else {
    return getNextActionNotHelpfulBotMessage(hospitalName, messageAction);
  }
};

const getNextActionHelpfulBotMessage = (hospitalName: string) => {
  return new MessageDTO({
    writer: `${hospitalName}`,
    writerType: 'bot_react',
    text: `도움이 되셨다니 기뻐요!😍 더 궁금한 점이 있으시면 언제든 ${
      checkBatchimEnding(hospitalName)
        ? hospitalName + '을'
        : hospitalName + '를'
    } 찾아주세요😄`,
    createdDate: dayjs().add(2, 's').valueOf(),
  });
};

export const addNextActionIsHelpfulBotMessage = async (
  messageAction,
  messageData,
  globalInfo,
  messages,
) => {
  //todo 챗봇 데이터 업데이트
  const isHelpful = messageAction.data.isHelpful;
  const link = messageAction.link;
  const nextActionBotMessage = isHelpful
    ? getNextActionHelpfulBotMessage(globalInfo.hospitalName)
    : link.includes('recommend')
    ? getNextActionNotHelpfulBotMessageByRecommend(
        globalInfo.hospitalName,
        messageAction,
      )
    : getNextActionNotHelpfulBotMessage(globalInfo.hospitalName, messageAction);
  messageAction.data.read = true;
  console.log('nextAction', nextActionBotMessage);
  await addMessage(
    messages,
    null,
    globalInfo,
    [
      new MessageDTO({
        text: messageAction.name,
        writerType: 'nok',
        writer: globalInfo.familyInfo.name,
      }),
    ],
    true,
  ).then(async () => {
    console.log('helpful', messageAction);
    updateChatBotData(globalInfo, messageAction);
    await updateMessage(globalInfo, messageData);
    await addMessage(messages, null, globalInfo, [nextActionBotMessage], true);
  });
};

const isNewChatBotContentsData = (actions) => {
  return actions.every((action) => {
    return action.data && action.data.templateKey;
  });
};
const isOldChatBotContentsData = (actions) => {
  return !isNewChatBotContentsData(actions);
};

const createChatBotData = (
  key: string,
  globalInfo: any,
  messageKey: string,
  recognizedWordList: string[],
  userMessage: string,
  message: string,
  contentList: any[],
): ChatBotData => {
  return {
    key: key,
    createdDate: dayjs().valueOf(),
    familyInfo: globalInfo.familyInfo,
    hospitalInfo: {
      name: globalInfo.hospitalName,
      key: globalInfo.hospitalKey,
    },
    petInfo: globalInfo.petInfo,
    contentInfo: {
      messageKey: messageKey,
      recognizedWordList: recognizedWordList,
      contentList: contentList.map((content) => {
        return {
          key: content.key,
          name: content.text,
          tags: content.tags,
        };
      }),
      totalHelpfulCount: 0,
      totalUnHelpfulCount: 0,
      totalClickedCount: 0,
      totalTelClickedCount: 0,
      totalDuration: 0,
      totalTemplateCount: contentList.length,
      userMessage: userMessage,
      message: message,
    },
  };
};
export const updatePetData = async (
  petData,
  hospitalKey,
  hospitalName,
  familyInfo,
  chatKey,
  tagList,
) => {
  petData.familyKey = familyInfo.key;
  await axios.put('pets', petData);
  const sendData = {
    petInfo: {
      key: petData.petKey,
      name: petData.petName,
      type: petData.petType,
      gender: petData.petGender,
      birthDay: petData.petBirthday,
      species: petData.petSpecies,
    },
    hospitalInfo: {
      key: hospitalKey,
      name: hospitalName,
    },
    familyInfo: familyInfo,
    familyKey: familyInfo.key,
    key: chatKey,
    tagList: tagList,
  };
  if (!familyInfo) {
    return;
  }
  return axios.patch('/chat', sendData);
};
export const createPetData = async (
  petData,
  hospitalKey,
  chatKey,
  familyKey,
) => {
  return await axios.post('pets/create', {
    ...petData,
    chatKey: chatKey,
    hospitalKey: hospitalKey,
    familyKey: familyKey,
  });
};
export const updatePetDataOnChatInfo = async (
  petData,
  hospitalKey,
  hospitalName,
  familyInfo,
  chatKey,
  tagList,
) => {
  const sendData = {
    petInfo: {
      key: petData.petKey,
      name: petData.petName,
      type: petData.petType,
      gender: petData.petGender,
      birthDay: petData.petBirthday,
      species: petData.petSpecies,
    },
    hospitalInfo: {
      key: hospitalKey,
      name: hospitalName,
    },
    familyInfo: familyInfo,
    key: chatKey,
    tagList: tagList,
  };
  return axios.patch('/chat', sendData);
};

export const getLastEvent = (cycleInfo) => {
  const lastEventInfo = cycleInfo.lastEventInfo;
  const followEvent = lastEventInfo.follow
    ? lastEventInfo.follow
    : lastEventInfo.register;
  const chatEvent = lastEventInfo.chat;
  const reservationEvent = cycleInfo.lastEventInfo.reservation;
  const lastEvent = [chatEvent, followEvent, reservationEvent].sort(
    (pre, next) => {
      const preEventDate = pre ? pre.eventDate : -1;
      const nextEventDate = next ? next.eventDate : -1;
      return nextEventDate - preEventDate;
    },
  )[0];
  if (lastEvent) {
    return lastEvent;
  } else {
    return cycleInfo.lastEventInfo.communication;
  }
};

export const getQNAType = (event) => {
  const type = event.type;
  if (type === 'reservation') {
    if (event.type === 'operation') {
      return 'operation';
    } else {
      return 'visit';
    }
  } else {
    const followAction = event.action;
    const followType = event.type;
    const followStep = event.step;
    if (followStep) {
      if (followStep !== 1) {
        return 'follow';
      } else {
        if (followType === 'operation') {
          return 'operation';
        } else {
          return 'visit';
        }
      }
    } else {
      if (followAction === 'normal' || followAction === 'follow') {
        return 'follow';
      } else {
        if (followType === 'operation') {
          return 'operation';
        } else {
          return 'visit';
        }
      }
    }
  }
};

export const addVaccineCheckMessage = async (
  messages,
  messageData,
  globalInfo,
) => {
  const messageDto = new MessageDTO({
    writer: globalInfo.hospitalName,
    writerType: 'bot_check_vaccine',
    text: '예방 접종기록 혹은 증명서가 필요하신가요?',
    actions: [
      {
        name: '네',
        type: 'button',
        link: 'internal://bot_check_vaccine',
      },
      {
        name: '아니요',
        type: 'button',
        link: 'internal://bot_check_vaccine',
        data: {
          message: messageData.text,
          key: messageData.key,
        },
      },
    ],
  });
  await addMessage(messages, null, globalInfo, [messageDto], true);
};

export const addTriggerMessage = async (
  triggerMessageTemplate,
  messages,
  messageData,
  globalInfo,
  type:
    | 'request_reservation'
    | 'request_vaccine'
    | 'request_opening_hours'
    | 'request_parking_and_location'
    | 'request_cost',
) => {
  const messageDto = new MessageDTO({
    writer: globalInfo.hospitalName,
    writerType:
      type === 'request_reservation'
        ? 'bot_check_reservation'
        : type === 'request_vaccine'
        ? 'bot_check_vaccine'
        : type === 'request_parking_and_location'
        ? 'bot_check_parking_and_location'
        : type === 'request_cost'
        ? 'bot_check_cost'
        : 'bot_check_opening_hours',
    text: replaceVariable(triggerMessageTemplate.text, {
      hospitalName: globalInfo.hospitalName,
      petName: globalInfo.petInfo.name || '미등록',
    }),
  });
  if (triggerMessageTemplate.qnaTemplateKey) {
    messageDto.actions = [
      {
        link: 'internal://reply_request',
        type: 'button',
        name: type === 'request_reservation' ? '예약 신청하기' : '더보기',
        data: {
          requestType: type,
          ...triggerMessageTemplate,
        },
      },
    ];
  }
  await addMessage(
    messages,
    null,
    globalInfo,
    [messageDto],
    true,
    messageDto.writerType,
  );
};
export const addVaccineCertificateMessage = async (
  messages,
  message,
  globalInfo,
) => {
  const firstMessageDto = new MessageDTO({
    writer: globalInfo.familyInfo.name,
    writerType: 'nok',
    text: message,
  });
  const petName = globalInfo.petInfo.name;
  const secondMessageDto = new MessageDTO({
    writer: globalInfo.hospitalName,
    writerType: 'bot_certificate_vaccine',
    createdDate: dayjs().add(1, 'second').valueOf(),
    text: `${addSuffix(
      petName,
      '이',
    )}의 접종내역은 아래 버튼을 눌러 확인해주세요.`,
    actions: [
      {
        name: '접종 내역',
        type: 'button',
        link: 'internal://bot_certificate_vaccine',
      },
    ],
  });
  await addMessage(
    messages,
    null,
    globalInfo,
    [firstMessageDto, secondMessageDto],
    true,
  );
};
export const isFullFilledPetData = (petData) => {
  return petData.petName;
  // const petType = petData.petType;
  // const petSpecies = petData.petSpecies;
  // const petGender = petData.petGender;
  // const petBirthday = petData.petBirthday;
  // if (!petBirthday || petBirthday === 0) {
  //   return false;
  // }
  // if (petType === undefined) {
  //   return false;
  // }
  // if (petType === 2 && !petSpecies) {
  //   return false;
  // }
  // if (!petSpecies) {
  //   return false;
  // }
  // if (!petGender) {
  //   return false;
  // }
  // return true;
};
export const addLocalMessageToFirebase = (
  globalInfo,
  messages,
  lastEvent,
  petName,
) => {
  const db = firebase.firestore();
  const hospitalKey = globalInfo.hospitalKey;
  const chatKey = globalInfo.chatKey;
  const hospitalRef = db.collection('Hospital').doc(hospitalKey);
  const chatRef = hospitalRef.collection('Chat').doc(chatKey);
  return firebase
    .firestore()
    .runTransaction(async (transaction) => {
      const bridgeMessage = new MessageDTO({
        writer: globalInfo.hospitalName,
        writerType: 'bot_pet_data',
        text: '',
      });
      if (lastEvent.category === 'chat' && lastEvent.type === 'active') {
        if (
          lastEvent.action !== 'visit' &&
          lastEvent.communicationKey &&
          lastEvent.cycleKey
        ) {
          const communicationRef = hospitalRef
            .collection('Cycle')
            .doc(lastEvent.cycleKey)
            .collection('Event')
            .doc(lastEvent.communicationKey);
          const communicationSnapshot = await transaction.get(communicationRef);
          if (communicationSnapshot.exists) {
            const communicationData = communicationSnapshot.data();
            const messageKey = communicationData.messageKey;
            if (messageKey) {
              const welcomeMessageRef = chatRef
                .collection('Message')
                .doc(messageKey);
              transaction.delete(welcomeMessageRef);
            }
          }
        }
        bridgeMessage.text = `대화가 시작되었어요. 이제 베티에게 ${addSuffix(
          petName,
          '이',
        )}의 건강 상태나 병원 이용에 궁금한점을 질문해주세요!`;
      } else if (lastEvent.category === 'follow') {
        bridgeMessage.text = `대화가 시작되었어요. 병원에서 ${addSuffix(
          petName,
          '이',
        )}의 상태 체크 요청이 왔어요!`;
      } else if (lastEvent.category === 'reservation') {
        bridgeMessage.text = `대화가 시작되었어요. 병원에서 ${addSuffix(
          petName,
          '이',
        )}의 방문 전 상태 체크 요청이 왔어요!`;
      } else if (lastEvent.category === 'communication') {
        bridgeMessage.text = `대화가 시작되었어요. 병원에서온 메시지를 확인해보세요!`;
      }
      let uploadMessages = [];
      uploadMessages = uploadMessages.concat(messages);
      if (bridgeMessage.text) {
        bridgeMessage.createdDate = lastEvent.createdDate;
        uploadMessages.push(bridgeMessage.toPlainObj());
      }
      for (let i = 0; i < uploadMessages.length; i++) {
        const uploadMessage = _.cloneDeep(uploadMessages[i]);
        const messageRef = chatRef.collection('Message').doc();
        uploadMessage.key = messageRef.id;
        if (lastEvent) {
          if (lastEvent.category !== 'communication') {
            uploadMessage.createdDate = lastEvent.createdDate - 6000 + i;
          } else {
            uploadMessage.createdDate = lastEvent.scheduledDate - 6000 + i;
          }
        }
        await transaction.set(messageRef, uploadMessage);
      }
      return true;
    })
    .catch((error) => {
      captureException(error);
      console.log('error', error);
      return false;
    });
};

export const addWhyQnaMessage = (
  globalInfo,
  messageData,
  userMessage,
  messages,
) => {
  const userMessageDto = new MessageDTO({
    writer: globalInfo.familyInfo.name,
    writerType: 'nok',
    text: userMessage,
  });
  const firstMessageDto = new MessageDTO({
    writer: globalInfo.hospitalName,
    writerType: 'bot_qna_description',
    text: '간단한 문진에 미리 답변해주시면, 담당 수의사가 미리 확인하여 더 정확하고 상세한 진료를 도와드릴 수 있습니다.',
    createdDate: dayjs().add(1300, 'milliseconds').valueOf(),
  });
  const secondMessageDto = new MessageDTO({
    writer: globalInfo.hospitalName,
    writerType: 'bot_qna_description',
    text: '또한 안내사항을 미리 전달해드릴 수 있고, 문진에 작성하신 요청사항을 확인하여 진료시 반영해드릴 수 있습니다.',
    createdDate: dayjs().add(2600, 'milliseconds').valueOf(),
  });
  const thirdMessageDto = new MessageDTO({
    writer: globalInfo.hospitalName,
    writerType: 'bot_qna_description',
    text: '문진을 작성해주세요.',
    createdDate: dayjs().add(3900, 'milliseconds').valueOf(),
    actions: [
      {
        link: 'internal://qna_request',
        type: 'button',
        name: '문진 작성하기',
        data: messageData.actions[0].data,
      },
      // {
      //   link: 'internal://qna_skip',
      //   type: 'button',
      //   name: '문진 생략하기',
      //   data: messageData.actions[1].data,
      // },
    ],
  });
  return addMessage(
    messages,
    null,
    globalInfo,
    [userMessageDto, firstMessageDto, secondMessageDto, thirdMessageDto],
    true,
  );
};
