import dayjs from 'dayjs';
import React, { useState } from 'react';

import VetfluxDropdowns from '../../chatting/components/VetfluxDropdowns';

const TimeQna = ({ time, onTimeClick }) => {
  const [isShownTimeMenu, setShownTimeMenu] = useState(false);
  const handleToggleTimeMenu = () => {
    setShownTimeMenu(!isShownTimeMenu);
  };
  const handleTimeClick = (event) => {
    const time = event.currentTarget.value;
    setShownTimeMenu(!isShownTimeMenu);
    onTimeClick(time);
  };
  return (
    <div className={'w-full px-5 removeHighlight'}>
      <VetfluxDropdowns
        defaultValue={time}
        list={hours}
        handleListItemClick={handleTimeClick}
        handleListToggle={handleToggleTimeMenu}
        isShownList={isShownTimeMenu}
        className={'mt-2'}
        optionDirection={'bottom'}
        menuStyle={'text-left'}
      />
    </div>
  );
};
const hours = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
];

export default TimeQna;
