import firebase from 'firebase';

export const getVaccineData = (hospitalKey: string, petKey: string) => {
  return firebase
    .firestore()
    .collection('Pet')
    .doc(petKey)
    .collection('Vaccine')
    .get();
};

export const getVisitData = (hospitalKey: string, chatKey: string) => {
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('ActivityLog')
    .where('visit.chatKey', '==', chatKey)
    .get();
};
