import dayjs from 'dayjs';

import BaseDto from '../../../base/baseDto';
import { QNA } from '../../../qna/component/QNADataType';

export class MessageDTO extends BaseDto {
  createdDate = dayjs().valueOf();
  files = null;
  images = null;
  videos = null;
  key = '';
  text = '';
  writer = '';
  writerType = '';
  qnaInfos?: QNA[];
  actions?: actionsType[];
  constructor(initData: {
    writer: string;
    writerType: string;
    text: string;
    key?: string;
    createdDate?: number;
    files?: filesType[];
    images?: imagesType[];
    videos?: videosType[];
    actions?: actionsType[];
    qnaInfos?: QNA[];
  }) {
    super();
    this.files = initData.files;
    this.images = initData.images;
    this.videos = initData.videos;
    this.text = initData.text;
    this.key = initData.key;
    this.writer = initData.writer;
    this.writerType = initData.writerType;
    this.actions = initData.actions;
    this.qnaInfos = initData.qnaInfos;
    if (initData.createdDate) {
      this.createdDate = initData.createdDate;
    }
  }
}

interface actionsType {
  link: string;
  name: string;
  type: string;
  data?: any;
}

interface imagesType {
  extension: string;
  link: string;
  name: string;
}

interface filesType {
  extension: string;
  link: string;
  name: string;
}

interface videosType {
  extension: string;
  link: string;
  name: string;
  thumbnail: string;
}
