import React from 'react';
import CheckIcon from 'src/features/icons/CheckIcon';
import XIcon from 'src/features/icons/XIcon';
import { getMobileOS } from 'src/features/util/userAgentChecker';
export const FunctionBlockModal = ({
  isShownA2HSModal,
  onNextClick,
  onCancelClick,
}) => {
  return (
    <div className='fixed z-[999] w-full h-full left-0 top-0 bg-[#00000045] flex flex-col justify-center items-center px-6'>
      <div
        className={`flex flex-col items-center p-5 w-full bg-white rounded-3xl text-neutral-dark shadow-xl max-h-[calc(100%-2.5rem)] overflow-y-auto`}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM9.00033 4.00002H11.0003V12H9.00033V4.00002ZM11.0003 14H9.00033V16H11.0003V14Z'
            fill='#B0AEBC'
          />
        </svg>

        <p className='w-full text-base font-semibold mt-2 text-center'>
          홈 화면에 즐겨찾기를 추가해야
          <br />
          원활한 사용이 가능해요
        </p>

        <div className='mt-5 w-full text-xs font-semibold text-neutral-dark relative pr-2'>
          <div className='absolute right-0 h-full w-[5.5rem] bg-primary rounded-lg z-0'></div>
          <div className='flex flex-col w-full z-10'>
            <div className='flex py-3 z-10'>
              <div className='flex-2'></div>
              <div className='flex-1 flex justify-center'>기본 </div>
              <div className='flex-1 flex justify-center text-white'>
                즐겨찾기
              </div>
            </div>
            <div className='flex py-3 border-t border-secondary-dark z-10'>
              <div className='flex-2'>메시지 수신/발신</div>
              <div className='flex-1 flex justify-center'>
                <CheckIcon />
              </div>
              <div className='flex-1 flex justify-center'>
                <CheckIcon />
              </div>
            </div>
            <div className='flex py-3 border-t border-secondary-dark z-10'>
              <div className='flex-2'>푸시알림</div>
              <div className='flex-1 flex justify-center'>
                <XIcon />
              </div>
              <div className='flex-1 flex justify-center'>
                <CheckIcon />
              </div>
            </div>
            <div className='flex py-3 border-t border-secondary-dark z-10'>
              <div className='flex-2'>사진 및 동영상 전송</div>
              <div className='flex-1 flex justify-center'>
                <XIcon />
              </div>
              <div className='flex-1 flex justify-center'>
                <CheckIcon />
              </div>
            </div>
            <div className='flex py-3 border-t border-secondary-dark z-10'>
              <div className='flex-2'>공지자료(진료) 기록 조회</div>
              <div className='flex-1 flex justify-center'>
                <XIcon />
              </div>
              <div className='flex-1 flex justify-center'>
                <CheckIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-8 w-full'>
        <div className={'w-full flex items-center justify-center'}>
          <button
            onClick={onNextClick}
            className={`font-semibold w-full text-base text-white bg-primary py-3 hover:bg-primary-600 hover:border-primary-600 shadow-md rounded-lg`}
          >
            {getMobileOS() === 'Android'
              ? '네, 추가할게요'
              : getMobileOS() === 'iOS'
              ? isShownA2HSModal
                ? '가이드로 돌아가기'
                : '가이드로 확인하기'
              : '나가기'}
          </button>
        </div>
        <button
          className={'text-white text-base font-semibold mt-2 w-full p-3'}
          onClick={onCancelClick}
        >
          다음에 할게요
        </button>
      </div>
    </div>
  );
};
