export const CheckBox = ({ checked }: { checked: boolean }) => {
  return (
    <>
      {checked ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='17'
          viewBox='0 0 18 17'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.1866 0.772989C17.5882 1.1522 17.6062 1.78511 17.227 2.18663L8.72703 11.1866C8.35797 11.5774 7.74602 11.6065 7.34151 11.2526L3.34151 7.75258C2.92587 7.3889 2.88375 6.75713 3.24744 6.3415C3.61112 5.92586 4.24288 5.88374 4.65852 6.24743L7.93401 9.11348L15.773 0.813379C16.1522 0.411861 16.7851 0.393778 17.1866 0.772989Z'
            fill='#6C5CE7'
          />
          <path
            d='M14.3777 0.834357C13.9652 0.620696 13.4967 0.5 13 0.5H3C1.34315 0.5 0 1.84315 0 3.5V13.5C0 15.1569 1.34315 16.5 3 16.5H13C14.6569 16.5 16 15.1569 16 13.5V4.94225L14 7.0599V13.5C14 14.0523 13.5523 14.5 13 14.5H3C2.44772 14.5 2 14.0523 2 13.5V3.5C2 2.94772 2.44772 2.5 3 2.5H12.8046L14.3777 0.834357Z'
            fill='#6C5CE7'
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='17'
          viewBox='0 0 16 17'
          fill='none'
        >
          <rect
            x='1'
            y='1.5'
            width='14'
            height='14'
            rx='2'
            stroke='#B0AEBC'
            strokeWidth='2'
          />
        </svg>
      )}
    </>
  );
};
