import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import firebase from 'firebase';
import _, { isEqual, now } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { isArrayEquals } from '../../../util/isArrayEquals';
import { MemoizedLeftMessageBox } from './LeftMessageBox';
import { MemoizedLoadingMessageBox } from './LoadingMessageBox';
import MessageSkeleton from './MessageSkeleton';
import { messageDataType } from './messageType';
import NoMessageContainer from './NoMessageContainer';
import { MemoizedRightMessageBox } from './RightMessageBox';

const ChattingBoxModule = ({
  mode,
  darkMode,
  messageList,
  isLoaded,
  lastReadTimeInfo,
  isChatBotLoad,
}: {
  mode: number;
  darkMode?: number;
  messageList: messageDataType[];
  isLoaded: boolean;
  lastReadTimeInfo: any;
  isChatBotLoad: boolean;
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Seoul');
  dayjs.extend(relativeTime);
  dayjs.extend(isToday);
  const chatRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef(null);
  const [isFirstScroll, setFirstScroll] = useState(true);
  const [isScrollTop, setScrollTop] = useState(false);
  const [isScrollBottom, setScrollBottom] = useState(true);
  const [inVisibleMessage, setInVisibleMessage] = useState(null);
  const [isShownInVisibleMessage, setIsShownInVisibleMessage] = useState(false);
  const [innerMessageList, setInnerMessageList] = useState(null);
  const vetfluxTypes = [
    'vetflux',
    'waiting',
    'bot_waiting',
    'bot_contents',
    'bot_react',
    'bot_description',
    'bot_description_link',
    'bot_pet_data',
    'bot_no_answer',
    'bot_chat_active',
    'bot_message_alim_talk',
    'bot_multiple_message',
    'bot_check_vaccine',
    'bot_check_reservation',
    'bot_check_opening_hours',
    'bot_certificate_vaccine',
    'bot_notification',
    'bot_qna_description',
    'bot_recommend_guide',
    'bot_guide_action',
    'bot_first_guide',
    'bot_first_message',
  ];
  const handleScrollReset = () => {
    const { clientHeight } = chatRef.current;
    if (isFirstScroll) {
      if (chatRef.current.children[chatRef.current.children.length - 1]) {
        if (clientHeight === 0) {
          chatRef.current.children[
            innerMessageList.length - 1
          ].scrollIntoView();
          setFirstScroll(false);
        } else {
          chatRef.current.children[
            innerMessageList.length - 1
          ].scrollIntoView();
          setFirstScroll(false);
        }
      }
    } else {
      if (chatRef.current.children[chatRef.current.children.length - 1]) {
        chatRef.current.children[
          chatRef.current.children.length - 1
        ].scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  };

  const handleScrollEvent = () => {
    if (
      chatRef.current.scrollTop + chatRef.current.offsetHeight + 20 >=
      chatRef.current.scrollHeight
    ) {
      setIsShownInVisibleMessage(false);
      setScrollBottom(true);
    } else {
      setScrollBottom(false);
    }
  };

  const handleInVisibleMessageClick = (event) => {
    event.preventDefault();
    if (chatRef && chatRef.current.children[innerMessageList.length - 1]) {
      chatRef.current.children[innerMessageList.length - 1].scrollIntoView();
    }
    // handleScrollReset();
  };

  useEffect(() => {
    if (chatRef.current && innerMessageList && innerMessageList.length !== 0) {
      if (
        _.isEqual(
          inVisibleMessage,
          innerMessageList[innerMessageList.length - 1],
        )
      ) {
        return;
      }
      if (isScrollBottom) {
        handleScrollReset();
      } else {
        if (
          _.isEqual(
            inVisibleMessage,
            innerMessageList[innerMessageList.length - 1],
          )
        ) {
          return;
        }
        if (
          innerMessageList[innerMessageList.length - 1].writerType === 'nok'
        ) {
          handleScrollReset();
        } else {
          setIsShownInVisibleMessage(true);
        }
      }
      setInVisibleMessage(innerMessageList[innerMessageList.length - 1]);
    }
  }, [innerMessageList, isScrollBottom, inVisibleMessage]);

  useEffect(() => {
    let interval;
    if (messageList) {
      const newMessageList = _.cloneDeep(messageList).sort(
        (a: { createdDate: number }, b: { createdDate: number }) =>
          a.createdDate - b.createdDate,
      );
      interval = setInterval(() => {
        if (!isArrayEquals(innerMessageList, newMessageList)) {
          const visibleMessages = newMessageList.filter(
            (message) => message.createdDate <= now(),
          );
          setInnerMessageList(visibleMessages);
        }
      }, 200);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [innerMessageList, messageList]);

  const getPreMsgCard = (index) => {
    return index === 0 ? undefined : innerMessageList[index - 1];
  };
  const getNextMsgCard = (index) => {
    return index + 1 === innerMessageList.length
      ? undefined
      : innerMessageList[index + 1];
  };
  return (
    <>
      <style jsx global>{`
        .chatViewMain::-webkit-scrollbar {
          display: none;
        }
        .removeHighlight {
          -webkit-tap-highlight-color: transparent;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .removeHighlight:focus {
          outline: none !important;
        }
      `}</style>

      <div
        ref={chatRef}
        className={'w-full h-full overflow-y-scroll  bg-white chatViewMain'}
        id='chat-container'
        data-drag-type='chat'
        onScroll={handleScrollEvent}
      >
        {!isLoaded || !innerMessageList ? (
          <MessageSkeleton />
        ) : innerMessageList.length !== 0 ? (
          <>
            {innerMessageList.map((msgCard, index) =>
              vetfluxTypes.includes(msgCard.writerType) ||
              msgCard.writerType.includes('bot') ? (
                mode === 0 ? (
                  <MemoizedRightMessageBox
                    mode={mode}
                    msgCard={msgCard}
                    key={msgCard.key}
                    preMsgCard={getPreMsgCard(index)}
                    nextMsgCard={getNextMsgCard(index)}
                    read={
                      lastReadTimeInfo?.vetflux
                        ? lastReadTimeInfo.vetflux >= msgCard.createdDate
                        : false
                    }
                  />
                ) : (
                  <MemoizedLeftMessageBox
                    mode={mode}
                    msgCard={msgCard}
                    key={msgCard.key}
                    preMsgCard={getPreMsgCard(index)}
                    nextMsgCard={getNextMsgCard(index)}
                  />
                )
              ) : mode === 0 ? (
                <MemoizedLeftMessageBox
                  mode={mode}
                  msgCard={msgCard}
                  key={msgCard.key}
                  preMsgCard={getPreMsgCard(index)}
                  nextMsgCard={getNextMsgCard(index)}
                />
              ) : (
                <MemoizedRightMessageBox
                  mode={mode}
                  msgCard={msgCard}
                  key={msgCard.key}
                  preMsgCard={getPreMsgCard(index)}
                  nextMsgCard={getNextMsgCard(index)}
                  read={
                    lastReadTimeInfo?.vetflux
                      ? lastReadTimeInfo.vetflux >= msgCard.createdDate
                      : false
                  }
                />
              ),
            )}
            {isChatBotLoad && <MemoizedLoadingMessageBox />}
          </>
        ) : (
          <NoMessageContainer />
        )}
        {isShownInVisibleMessage && inVisibleMessage && (
          <div className={'absolute bottom-0 w-full px-3 mb-[5rem] z-50'}>
            <button
              className={
                'shadow-md bg-secondary-light w-full text-left rounded-xl p-3 removeHighlight'
              }
              onClick={handleInVisibleMessageClick}
            >
              <p className={'text-neutral-dark text-sm'}>
                {inVisibleMessage.writer}
              </p>
              <p className={'text-neutral-dark text-sm mt-2'}>
                {inVisibleMessage.text}
              </p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ChattingBoxModule;
