import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import dayjs from 'dayjs';
import firebase from 'firebase/app';

import { FamilyInfo, Info, PetInfo } from '../../base/baseInfo';
export const uploadQna = (data): Promise<any> => {
  return axios.post('qna', data);
};

export const skipQna = (
  hospitalKey: string,
  cycleKey: string,
  eventKey: string,
): Promise<any> => {
  return axios.post('qna/skip', {
    hospitalKey: hospitalKey,
    cycleKey: cycleKey,
    eventKey: eventKey,
  });
};
export const getQNATemplate = async (hospitalKey: string, qnaKey: string) => {
  return await firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('QnaTemplate')
    .doc(qnaKey)
    .get()
    .then((documentSnapshot) => {
      return documentSnapshot.data();
    });
};

export const getReplyTriggerMessageTemplate = (hospitalKey) => {
  if (!hospitalKey) {
    return;
  }
  return firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('MessageTemplate')
    .where('keyword', 'in', [
      'reply_request_reservation',
      'reply_request_vaccine',
      'reply_request_opening_hours',
      'reply_no_contents',
      'reply_chat_message',
      'reply_request_parking_and_location',
      'reply_request_cost',
      'reply_request_notice',
    ])
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        return querySnapshot.docs.map((doc) => {
          return doc.data();
        });
      } else {
        return undefined;
      }
    })
    .catch((error) => {
      captureException(error);
      return undefined;
    });
};
export const getDefultQNATemplateByType = async (
  hospitalKey: string,
  type: string,
) => {
  return await firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('QnaTemplate')
    .where('isDefault', '==', true)
    .where('type', '==', type)
    .get()
    .then((snapshot) => {
      const list = [];
      snapshot.forEach((val) => {
        list.push(val.data());
      });
      if (list.length > 0) {
        return list[0];
      } else {
        return undefined;
      }
    });
};

export const getQNAData = async (
  hospitalKey: string,
  chatKey: string,
  qnaKey: string,
) => {
  return await firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Chat')
    .doc(chatKey)
    .collection('QNA')
    .doc(qnaKey)
    .get()
    .then((documentSnapshot) => {
      return documentSnapshot.data();
    });
};
export const addNotification = (data: {
  familyInfo: FamilyInfo;
  hospitalInfo: Info;
  petInfo: PetInfo;
  key: string;
  messageType: 'talk' | 'fcm';
  chatKey: string;
  createdDate: number;
  text: string;
  messageStatus?: 'success' | 'pending' | 'fail';
  sendDate?: number;
  smsId?: string;
  to: string;
  qnaInfoList: any;
  qnaTemplateKey: string;
  qnaTemplateType: string;
  qnaKey: string;
  messageKey: string;
  purposeType: string;
}) => {
  const notificationRef = firebase
    .firestore()
    .collection('Hospital')
    .doc(data.hospitalInfo.key)
    .collection('Notification')
    .doc();
  data.key = notificationRef.id;
  data.messageStatus = 'pending';
  data.sendDate = 0;
  data.smsId = '';
  return notificationRef.set(data);
};

const getReservationForDate = async (hospitalKey: string, date: string) => {
  const startOfDay = dayjs(date, 'YYYY.MM.DD').startOf('day').valueOf();
  const endOfDay = dayjs(date, 'YYYY.MM.DD').endOf('day').valueOf();

  const snapshot = await firebase
    .firestore()
    .collection('Hospital')
    .doc(hospitalKey)
    .collection('Reservation')
    .where('eventDate', '>=', startOfDay)
    .where('eventDate', '<=', endOfDay)
    .get();

  // 예약 데이터를 배열로 반환
  const reservations: any[] = [];
  snapshot.forEach((doc) => {
    reservations.push(doc.data());
  });

  console.log('reservations', reservations);
  return reservations;
};

const parseReservationTime = (reservations: any[], divide: number) => {
  const timeArray: string[] = [];

  reservations.forEach((reservation) => {
    const startTime = dayjs(reservation.eventDate);
    const endTime = dayjs(reservation.endDate);

    let currentTime = startTime;

    while (currentTime.isBefore(endTime)) {
      const formattedTime = currentTime.format('HH:mm');

      if (!timeArray.includes(formattedTime)) {
        timeArray.push(formattedTime);
      }

      currentTime = currentTime.add(divide, 'minute');
    }
  });

  return timeArray;
};

export const getTimeOfReservationListForDate = async (
  hospitalKey: string,
  date: string,
  divide: number,
) => {
  const reservations = await getReservationForDate(hospitalKey, date);
  return parseReservationTime(reservations, divide);
};

export const getOperatingHours = async (hospitalKey: string) => {
  try {
    const response = await axios.get(`/business-hours/${hospitalKey}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response.data;
    }
  }
};
