import { captureException } from '@sentry/core';
import imageCompression from 'browser-image-compression';
import dayjs from 'dayjs';
import firebase from 'firebase';
import * as HME from 'h264-mp4-encoder';

export const checkSupportExtension = (file: File) => {
  const imageExtensions: string[] = [
    'jpg',
    'jpeg',
    'png',
    'bmp',
    'tif',
    'tiff',
    'heif',
  ];
  const videoExtensions: string[] = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
    'webm',
    'mpeg-2',
    'hevc',
  ];
  const fileType: string[] = [
    'xlsx',
    'xls',
    'pdf',
    'hwp',
    'docs',
    'keynote',
    'numbers',
    'ppt',
    'pptx',
    'xps',
  ];
  const extension = getFileExtension(file).toLowerCase();
  const result = {
    isSupport: false,
    fileType: '',
  };
  if (imageExtensions.includes(extension)) {
    result.isSupport = true;
    result.fileType = 'image';
  } else if (videoExtensions.includes(extension)) {
    result.isSupport = true;
    result.fileType = 'video';
  } else if (fileType.includes(extension)) {
    result.isSupport = true;
    result.fileType = 'file';
  }
  return result;
};
export const getFileExtension = (file: File) => {
  const selectFileSplit = file.name.split('.');
  return selectFileSplit[selectFileSplit.length - 1];
};

export const fileUpload = (
  uploadPath: string,
  file: File,
  onCompleteListener: (isSuccess: boolean, downloadUrl: string) => void,
  onProgressListener?: (progress) => void,
  metaData?,
) => {
  const storageRef = firebase.storage().ref();
  const uploadTask = storageRef.child(uploadPath).put(file, metaData);
  try {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (
          (snapshot.bytesTransferred / snapshot.totalBytes) *
          100
        ).toFixed(1);
        if (onProgressListener) {
          onProgressListener(progress);
        }
      },
      (error) => {
        onCompleteListener(false, '');
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            onCompleteListener(true, downloadURL);
          })
          .catch((error) => {
            captureException(error);
            onCompleteListener(false, '');
          });
      },
    );
  } catch (e) {
    captureException(e);
    return undefined;
  }

  return uploadTask;
};
const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};
export const compressImage = (file: File): Promise<File> => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  return imageCompression(file, options);
};
export const createThumbnail = async (file: File): Promise<File> => {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
  const video: HTMLVideoElement = document.createElement('video');
  video.setAttribute('src', URL.createObjectURL(file));
  video.load();
  return new Promise<File>((resolve, reject) => {
    const timeUpdateListener = async (event) => {
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const canvasBase64: string = canvas.toDataURL('image/jpg', 0.1);
      const blobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
      };
      const blobImageFile = await (await fetch(canvasBase64)).blob();
      const returnFile = blobToFile(
        blobImageFile,
        `thumb_${new Date().valueOf()}_${file.name}.jpg`,
      );
      console.log(typeof returnFile);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };
      await imageCompression(returnFile, options)
        .then((file) => {
          video.removeEventListener('timeupdate', timeUpdateListener);
          video.removeEventListener('loadeddata', loadedDataListener);
          resolve(file);
        })
        .catch((error) => {
          captureException(error);
          video.removeEventListener('timeupdate', timeUpdateListener);
          video.removeEventListener('loadeddata', loadedDataListener);
          reject(error);
        });
    };
    const loadedDataListener = (event) => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = 1;
      video.addEventListener('timeupdate', timeUpdateListener);
    };
    video.addEventListener('loadeddata', loadedDataListener);
  });
};
