import React from 'react';

interface MobileModalProps {
  title?: string;
  cancelClickButton?: {
    text: string;
    onClick: (event: any) => void;
  };
  nextClickButton?: {
    text: string;
    onClick: (event: any) => void;
  };
  children: React.ReactNode;
}
export const MobileAlert = ({
  title,
  cancelClickButton,
  nextClickButton,
  children,
}: MobileModalProps) => {
  return (
    <div className='fixed z-50 w-full h-full left-0 top-0 bg-[#00000045]'>
      <div
        className={`relative z-40 left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] max-h-[calc(100%-2.5rem)] flex justify-center h-full items-center`}
      >
        <div
          className={`flex flex-col relative items-center bg-white rounded-3xl text-neutral-dark shadow-xl max-h-[calc(100%-2.5rem)] w-4/5 px-6 pt-6 pb-7 gap-4`}
        >
          <div className='relative bg-secondary-normal rounded-full p-2 mr-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='20'
              viewBox='-21 0 512 512'
              width='20'
            >
              <path
                fill='#6C5CE7'
                d='m453.332031 229.332031c-8.832031 0-16-7.167969-16-16 0-61.269531-23.847656-118.847656-67.15625-162.175781-6.25-6.25-6.25-16.382812 0-22.632812s16.382813-6.25 22.636719 0c49.34375 49.363281 76.519531 115.007812 76.519531 184.808593 0 8.832031-7.167969 16-16 16zm0 0'
              />
              <path
                fill='#6C5CE7'
                d='m16 229.332031c-8.832031 0-16-7.167969-16-16 0-69.800781 27.179688-135.445312 76.542969-184.789062 6.25-6.25 16.386719-6.25 22.636719 0s6.25 16.386719 0 22.636719c-43.328126 43.304687-67.179688 100.882812-67.179688 162.152343 0 8.832031-7.167969 16-16 16zm0 0'
              />
              <path
                fill='#6C5CE7'
                d='m234.667969 512c-44.117188 0-80-35.882812-80-80 0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 26.476562 21.523437 48 48 48 26.472656 0 48-21.523438 48-48 0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 44.117188-35.882813 80-80 80zm0 0'
              />
              <path
                fill='#6C5CE7'
                d='m410.667969 448h-352c-20.589844 0-37.335938-16.746094-37.335938-37.332031 0-10.925781 4.757813-21.269531 13.058594-28.375 32.445313-27.414063 50.941406-67.261719 50.941406-109.480469v-59.480469c0-82.34375 66.988281-149.332031 149.335938-149.332031 82.34375 0 149.332031 66.988281 149.332031 149.332031v59.480469c0 42.21875 18.496094 82.066406 50.730469 109.332031 8.511719 7.253907 13.269531 17.597657 13.269531 28.523438 0 20.585937-16.746094 37.332031-37.332031 37.332031zm-176-352c-64.707031 0-117.335938 52.628906-117.335938 117.332031v59.480469c0 51.644531-22.632812 100.414062-62.078125 133.757812-.746094.640626-1.921875 1.964844-1.921875 4.097657 0 2.898437 2.433594 5.332031 5.335938 5.332031h352c2.898437 0 5.332031-2.433594 5.332031-5.332031 0-2.132813-1.171875-3.457031-1.878906-4.054688-39.488282-33.386719-62.121094-82.15625-62.121094-133.800781v-59.480469c0-64.703125-52.628906-117.332031-117.332031-117.332031zm0 0'
              />
              <path
                fill='#6C5CE7'
                d='m234.667969 96c-8.832031 0-16-7.167969-16-16v-64c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v64c0 8.832031-7.167969 16-16 16zm0 0'
              />
            </svg>
          </div>
          {title && (
            <p className='w-full text-xl font-bold pb-2 text-center'>{title}</p>
          )}
          <div className='flex-1 overflow-y-auto'>{children}</div>
          {nextClickButton && (
            <div className={'w-full flex items-center justify-center'}>
              <button
                onClick={nextClickButton.onClick}
                className={`text-sm text-white bg-primary min-w-[120px] px-6 py-3.5 border border-primary hover:bg-primary-600 hover:border-primary-600 shadow-md leading-none transition rounded-md w-full`}
                // disabled={disabled}
              >
                {nextClickButton.text}
              </button>
            </div>
          )}
          {cancelClickButton && (
            <button
              className={'text-neutral-light text-xs'}
              onClick={cancelClickButton.onClick}
            >
              {cancelClickButton.text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
