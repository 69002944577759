import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useState } from 'react';

import VetFluxDatePicker from '../../reservation/component/VetFluxDatePicker';

const DateQna = ({ date, onDateClick }) => {
  dayjs.extend(customParseFormat);
  const handleDatePickerData = (value) => {
    onDateClick(dayjs(value).format('YYYY.MM.DD'));
  };
  const [innerDate, setInnerDate] = useState(dayjs().valueOf());
  useEffect(() => {
    if (date) {
      setInnerDate(dayjs(date, 'YYYY.MM.DD').valueOf());
    } else {
      setInnerDate(dayjs().valueOf());
    }
  }, [date]);
  return (
    <div className={'w-full h-full'}>
      <VetFluxDatePicker
        defaultTime={innerDate}
        handleDateData={handleDatePickerData}
        datePickRangeOptions={'normal'}
        className={'w-full'}
      />
    </div>
  );
};

export default DateQna;
