import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useState } from 'react';
import { VetFluxArrowIcon } from 'src/features/icons/VetFluxArrowIcon';
import { getDayFromNumber } from 'src/features/util/formatDay';

import {
  BusinessHourTime,
  BusinessHourTypeEnum,
  DayEnum,
} from '../../../types/reservationType';
import VetFluxDatePicker from '../../reservation/component/VetFluxDatePicker';
import VetfluxTimeDropdown from '../../reservation/component/VetfluxTimeDropdown';
import {
  getOperatingHours,
  getTimeOfReservationListForDate,
} from '../service/QNAService';

const ReservationShareQna = ({
  hospitalKey,
  date,
  onDateClick,
  availableTimeSlots = [],
  timeSplit,
}) => {
  dayjs.extend(customParseFormat);
  const [innerDate, setInnerDate] = useState(null);
  const [innerTime, setInnerTime] = useState(null);
  const [isShownDatePicker, setIsShownDatePicker] = useState(false);
  const [isShownTimePicker, setIsShownTimePicker] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [excludedTimeList, setExcludedTimeList] = useState([]);
  const [breakTimes, setBreakTimes] = useState<BusinessHourTime[]>([]);

  const getBreakTimes = async () => {
    const response = await getOperatingHours(hospitalKey);
    const findData = response.find(
      (res) => res.type === BusinessHourTypeEnum.BREAK_TIME,
    );
    if (!findData) {
      setBreakTimes([]);
      return;
    }
    setBreakTimes(findData.times);
  };

  const handleDatePickerData = (value) => {
    if (innerDate) {
      onDateClick(null);
    }

    setInnerDate(dayjs(value).valueOf());
    setIsShownDatePicker(false);
    setIsShownTimePicker(true);
    setInnerTime(null);

    setIsLoad(false);
    getTimeOfReservationListForDate(
      hospitalKey,
      dayjs(value).format('YYYY.MM.DD'),
      30,
    ).then((res) => {
      setExcludedTimeList(res);
      setIsLoad(true);
    });
  };

  const getOperatingHoursByDay = () => {
    const day = Object.keys(DayEnum).find(
      (_, index) => index === dayjs(innerDate).get('day'),
    );
    const findData = availableTimeSlots.find(
      (slot) => slot.day === DayEnum[day],
    );

    return (
      findData ?? {
        startTime: '',
        endTime: '',
      }
    );
  };

  const getBreakTimeByDay = () => {
    const day = Object.keys(DayEnum).find(
      (_, index) => index === dayjs(innerDate).get('day'),
    );
    const findData = breakTimes.find((time) => time.day === DayEnum[day]);

    return findData ?? { startTime: '', endTime: '' };
  };

  useEffect(() => {
    if (date) {
      const newDate = date.replaceAll('.', '-');
      setInnerDate(dayjs(newDate, 'YYYY.MM.DD').valueOf());
      setInnerTime(dayjs(newDate).format('HH:mm'));
    } else {
      if (date === '') {
        setIsShownDatePicker(true);
        setInnerDate(null);
        setInnerTime(null);
      } else {
        if (!isLoad) return;
        setIsShownDatePicker(true);
      }
    }
  }, [date]);

  useEffect(() => {
    if (!hospitalKey || hospitalKey === '') return;
    getBreakTimes();
  }, [hospitalKey]);

  return (
    <div className={'w-full text-neutral-dark'}>
      <section title='date'>
        <button
          className='p-5 flex w-full justify-between'
          onClick={() => {
            setIsShownDatePicker(!isShownDatePicker);
          }}
        >
          <div className='flex items-center'>
            <div className='mr-4'>
              {innerDate ? (
                <img src={'/CheckIcon.svg'} alt='check' />
              ) : (
                <img src={'/ReservationIcon.svg'} alt='reservation' />
              )}
            </div>
            <span className='text-neutral-dark font-semibold text-xl'>
              {dayjs(
                innerDate ? innerDate : dayjs().add(1, 'day').valueOf(),
              ).format('MM월 DD일')}{' '}
              {getDayFromNumber(
                dayjs(
                  innerDate ? innerDate : dayjs().add(1, 'day').valueOf(),
                ).day(),
              )}
            </span>
          </div>
          <VetFluxArrowIcon
            width={24}
            height={24}
            rotate={isShownDatePicker ? 'rotate-180' : 'rotate-0'}
          />
        </button>
        <div className={`${isShownDatePicker ? 'block px-5' : 'hidden'}`}>
          <div className='w-full h-[.0625rem] bg-secondary-normal' />
          <VetFluxDatePicker
            defaultTime={
              innerDate ? innerDate : dayjs().add(1, 'day').valueOf()
            }
            handleDateData={handleDatePickerData}
            datePickRangeOptions={'after'}
            className={'w-full mt-3'}
            maxYearRange={1}
            isFirstClick={!innerDate ? true : false}
            availableDays={availableTimeSlots.map(({ day }) => day)}
          />
        </div>
        <div className='w-full h-2 bg-secondary-normal my-5' />
      </section>

      <section title='time'>
        <button
          className='p-5 flex w-full justify-between'
          onClick={() => {
            if (!innerDate) return alert('날짜를 먼저 선택해주세요');
            setIsShownTimePicker(!isShownTimePicker);
          }}
        >
          <div className='flex items-center'>
            <div className='mr-4'>
              {innerTime ? (
                <img src={'/CheckIcon.svg'} alt='check' />
              ) : (
                <img src={'/ReservationIcon.svg'} alt='reservation' />
              )}
            </div>
            <span className='text-neutral-dark font-semibold text-xl'>
              예약 시간
            </span>
          </div>
          <VetFluxArrowIcon
            width={24}
            height={24}
            rotate={isShownTimePicker ? 'rotate-180' : 'rotate-0'}
          />
        </button>
        <div
          className={`${isShownTimePicker ? 'block' : 'hidden'} min-w-[18rem]`}
        >
          <div className='w-full h-[.0625rem] bg-secondary-normal' />
          <div className='flex justify-center px-5 py-3'>
            {isLoad && (
              <VetfluxTimeDropdown
                startTime={getOperatingHoursByDay().startTime}
                endTime={getOperatingHoursByDay().endTime}
                timeSplit={timeSplit}
                onTimeSelect={(time) => {
                  setIsShownTimePicker(false);
                  setInnerTime(time);
                  onDateClick(
                    `${dayjs(innerDate).format('YYYY.MM.DD')} ${time}`,
                  );
                }}
                selectedTime={innerTime}
                disableStartTime={getBreakTimeByDay().startTime}
                disableEndTime={getBreakTimeByDay().endTime}
              />
            )}
          </div>
        </div>
        <div className='w-full h-2 bg-secondary-normal' />
      </section>

      <section
        title='result'
        className={`${innerDate && innerTime ? 'block p-5' : 'hidden'}`}
      >
        <div className='pb-2'>
          <div>방문 희망 시간</div>
          <div className='px-4 py-3 bg-secondary-light rounded-lg shadow mt-[.625rem]'>
            <span className='font-semibold mr-1'>
              {dayjs(innerDate)?.format('MM.DD')}(
              {getDayFromNumber(dayjs(innerDate)?.day())})
            </span>
            <span>{innerTime}</span>
          </div>
        </div>
        <div className='w-full h-[.0625rem] bg-secondary-normal my-3' />
        <div className='py-3'>
          <div className='font-semibold'>유의사항</div>
          <div className='text-sm text-neutral-normal mt-1'>
            응급 환자 발생에 따라 진료 순서가 바뀌거나 대기 시간이 발생할 수
            있는 점 양해 부탁드립니다.
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReservationShareQna;
