import React from 'react';
import Lottie, { Options } from 'react-lottie';

import Cat from './loading.json';

const options: Options = {
  animationData: Cat,
  loop: true,
  autoplay: true,
  rendererSettings: {
    className: 'loading w-10 h-10',
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loading = ({ text = '웹채팅 기다리는중...' }: { text?: string }) => {
  return (
    <>
      <div className='fixed z-[999] inset-0 flex flex-col items-center justify-center min-h-screen'>
        <div className={'absolute w-full h-full bg-black opacity-50'} />
        <Lottie
          options={options}
          isStopped={false}
          isClickToPauseDisabled={true}
          speed={1.5}
          style={{
            width: '15rem',
            height: '15rem',
            marginTop: '-7.5rem',
          }}
        />
        <p className='z-20 pl-4 -my-8 text-xl text-center text-white'>{text}</p>
      </div>
    </>
  );
};

export default Loading;
