import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export const TextQna = ({ answer, onChange }) => {
  const [inputValue, setInputValue] = useState(answer);
  const onChangeInput = (event) => {
    const value = event.currentTarget.value;
    setInputValue(value);
    onChange(value);
  };
  useEffect(() => {
    setInputValue(answer);
  }, [answer]);
  return (
    <>
      <div className={'w-full px-5'}>
        <TextareaAutosize
          className={
            'w-full text-base text-neutral-dark border border-neutral-light rounded-md focus:outline-none focus:border-primary p-3'
          }
          value={inputValue}
          onChange={onChangeInput}
          maxRows={7}
          minRows={3}
        />
      </div>
    </>
  );
};
