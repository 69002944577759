import dayjs from 'dayjs';
import localeKo from 'dayjs/locale/ko';
import _ from 'lodash';
import Image from 'next/image';
import React, { useRef } from 'react';
import { Slide, toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import useLongPress from '../../../../app/hooks';
import { doCopy } from '../../../util/doCopy';
import Linkify from '../../../util/Linkify';
import { createQnaMessage } from '../../service/ChattingService';
import FileBox from './FileBox';
import ImageBox from './ImageBox';
import { messageComponentType } from './messageType';
import placeHolder from './placeHolder.png';
import ReadIcon from './ReadIcon';
import { Time } from './Time';
import VideoBox from './VideoBox';

const RightMessageBox = ({
  preMsgCard,
  msgCard,
  nextMsgCard,
  read,
}: messageComponentType) => {
  const toastId = useRef(null);
  const toastOption: ToastOptions = {
    position: 'top-center',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    closeButton: false,
    draggable: true,
    transition: Slide,
  };
  const handleClickWindowEvent = {
    messageClick: () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      return window?.message.onClick(JSON.stringify(msgCard));
    },
    qnaClick: (event) => {
      const index = event.currentTarget.dataset.index;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      return window?.qna.onClick(index, JSON.stringify(msgCard));
    },
  };
  const onLongPress = () => {
    doCopy(document, msgCard.text).then(() => {
      toastId.current = toast(`메시지가 복사 되었습니다.`, toastOption);
    });
  };
  const onClick = () => {
    console.log('click');
  };
  const longPressEvent = useLongPress(onLongPress, onClick, {
    shouldPreventDefault: true,
    delay: 500,
  });
  return (
    <div className='mt-5 removeHighlight'>
      <div className={'flex flex-1 pb-1 justify-end mr-4'}>
        <div className={'group flex flex-1 flex-col pt-1 items-end '}>
          <div className={'w-full flex flex-row items-end flex-row-reverse'}>
            <div
              className={
                'max-w-3/4 ' +
                (msgCard.images ||
                msgCard.videos ||
                msgCard.actions ||
                msgCard.files
                  ? 'w-full '
                  : '') +
                ' rounded-t-2xl rounded-l-2xl bg-primary-light'
              }
            >
              <ul
                className={
                  'break-all whitespace-pre-wrap text-base ' +
                  (!msgCard.images && !msgCard.videos
                    ? 'p-3 overflow-hidden'
                    : 'rounded-t-2xl rounded-l-2xl overflow-hidden') +
                  ' text-neutral-dark'
                }
              >
                {msgCard.images &&
                  msgCard.images.map((msgObject, index) => {
                    return (
                      <ImageBox
                        key={msgObject.link}
                        isLeft={false}
                        imageUrl={msgObject.link}
                        onClick={handleClickWindowEvent.messageClick}
                      />
                    );
                  })}
                {msgCard.videos &&
                  msgCard.videos.map((msgObject, index) => {
                    return (
                      <VideoBox
                        key={msgObject.link}
                        video={msgObject}
                        onClick={handleClickWindowEvent.messageClick}
                      />
                    );
                  })}
                {msgCard.files &&
                  msgCard.files.map((msgObject, index) => {
                    return (
                      <FileBox
                        key={msgObject.link}
                        isLeft={false}
                        file={msgObject}
                        onClick={handleClickWindowEvent.messageClick}
                      />
                    );
                  })}
                {msgCard.videos || msgCard.images ? (
                  ''
                ) : (
                  <Linkify
                    text={msgCard.text}
                    msgCard={msgCard}
                    position={'right'}
                  />
                )}
                {msgCard.qnaInfos && (
                  <Linkify
                    text={createQnaMessage(msgCard.qnaInfos)}
                    msgCard={msgCard}
                    position={'right'}
                  />
                )}
                {msgCard.actions && (
                  <ul>
                    {msgCard.actions[0].type !== 'list' &&
                      msgCard.actions.map((action, index) => {
                        return (
                          <li
                            key={`0000${index}${action.link}`}
                            className={
                              'shadow-md bg-white mt-4 rounded-lg text-base px-4 py-3 text-primary'
                            }
                          >
                            <div className={'flex flex-row items-center'}>
                              <button
                                className={
                                  'w-full disabled:text-neutral-normal'
                                }
                                data-index={index.toString()}
                                onClick={handleClickWindowEvent.qnaClick}
                              >
                                <a>{action.name}</a>
                              </button>
                              {action.data && action.data.read && (
                                <div>
                                  <ReadIcon />
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </ul>
            </div>
            {read && (
              <div
                className={
                  'flex p-1 text-xs text-neutral-light mr-1 rounded-full  w-[1.7rem] h-[1.2rem] items-center'
                }
              >
                {/* <ReadIcon color={'white'} />bg-primary  */}
                <span className='text-neutral-normal text-xs whitespace-nowrap mb-1'>
                  읽음
                </span>
              </div>
            )}
          </div>
          {msgCard.writerType !== 'nok_pet_data' && (
            <Time
              isLeft={false}
              preMsgCard={preMsgCard}
              msgCard={msgCard}
              nextMsgCard={nextMsgCard}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const areEqual = (
  prevProps: messageComponentType,
  nextProps: messageComponentType,
) => {
  return _.isEqual(prevProps, nextProps);
};

export const MemoizedRightMessageBox = React.memo(RightMessageBox, areEqual);
