import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { createRipples } from 'react-ripples';
const MyRipples = createRipples({
  color: 'rgba(0, 0, 0, .2)',
  during: 600,
});
export const PointQna = ({
  itemList,
  selectedList,
  onItemClick,
}: {
  itemList: any[];
  selectedList?: any[];
  onItemClick: (index) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const onPointClick = (point) => {
    console.log(point);
    setSelectedIndex(Number(point));
    onItemClick(Number(point));
  };
  useEffect(() => {
    if (selectedList) {
      setSelectedIndex(selectedList[0]);
    } else {
      setSelectedIndex(-1);
    }
  }, [selectedList]);
  return (
    <div className={'mx-5'}>
      <div className={'mt-3 flex flex-row justify-between'}>
        <span className={'flex-1 text-left text-neutral-normal text-sm'}>
          매우 안좋음
        </span>
        <span className={'flex-1 text-center text-neutral-normal text-sm'}>
          보통
        </span>
        <span className={'flex-1 text-right text-neutral-normal text-sm'}>
          매우 좋음
        </span>
      </div>
      <div
        className='w-full h-[3rem] flex flex-row justify-between mt-2 border border-secondary-dark rounded-md overflow-hidden removeHighlight'
        // onChange={(event, value) => {
        //   onPointClick(value[0]);
        // }}
      >
        {itemList.map((answer, index) => (
          <MyRipples className={'w-full h-full'} key={index.toString()}>
            <button
              className={`flex-1 ${
                selectedIndex === index
                  ? 'bg-secondary-normal text-primary'
                  : 'bg-white text-neutral-normal'
              } ${index === 0 ? '' : 'border-l'} hover:bg-secondary-light`}
              onClick={() => {
                onPointClick(index);
              }}
            >
              {index + 1}
            </button>
          </MyRipples>
        ))}
      </div>
    </div>
  );
};
