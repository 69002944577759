import React, { useState } from 'react';

/* utils */
import {
  getIsSameOrAfterTime,
  getIsSameOrBeforeTime,
  isValidReservationHour,
  isValidReservationHourForTimeSplitHour,
} from '../../../utils/dateTimeUtil';
/* components */
import Dropdown from './Dropdown';

interface VetfluxTimeDropdownProps {
  startTime: string;
  endTime: string;
  timeSplit: string;
  onTimeSelect: (time: string) => void;
  selectedTime: string;
  disableStartTime?: string;
  disableEndTime?: string;
}

function VetfluxTimeDropdown(props: VetfluxTimeDropdownProps) {
  const {
    startTime,
    endTime,
    timeSplit,
    onTimeSelect,
    selectedTime,
    disableStartTime = '',
    disableEndTime = '',
  } = props;
  const [selectHour, setSelectHour] = useState<string>('');
  const [selectMinute, setSelectMinute] = useState<string>('');

  const getMinuteDropdownItems = () => {
    const minuteItems: string[] = (() => {
      switch (timeSplit) {
        case '10분':
          return ['00', '10', '20', '30', '40', '50'];
        case '30분':
          return ['00', '30'];
        case '1시간':
        default:
          return [];
      }
    })();

    if (!isNaN(Number(selectHour))) {
      const endHour = Number(endTime.split(':')[0]);
      const endMinute = Number(endTime.split(':')[1]);
      const selectedHour = Number(selectHour);

      if (selectedHour >= endHour) {
        return minuteItems.filter((minute) => Number(minute) <= endMinute);
      }
    }

    return minuteItems;
  };

  const handleSelectHour = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { id } = event.currentTarget;
    setSelectHour(id);
    setSelectMinute('');
    if (timeSplit === '1시간') {
      onTimeSelect(`${id}:00`);
    }
  };

  const handleSelectMinute = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { id } = event.currentTarget;
    setSelectMinute(id);
    onTimeSelect(`${selectHour}:${id}`);
  };

  return (
    <div
      className={
        'w-full py-3 px-5 grid grid-cols-2 gap-1.5 items-start justify-center'
      }
    >
      <Dropdown
        value={selectHour === '' ? '-' : `${selectHour}시`}
        isDisable={false}
        items={Array.from(
          {
            length:
              Number(endTime.split(':')[0]) -
              Number(startTime.split(':')[0]) +
              1,
          },
          (_, i) =>
            String(i + Number(startTime.split(':')[0])).padStart(2, '0'),
        )
          .map((hour) => ({
            text: `${hour}시`,
            value: hour,
            isDisable: false,
          }))
          .filter(({ value }) =>
            timeSplit === '1시간'
              ? !isValidReservationHourForTimeSplitHour(
                  value,
                  disableStartTime,
                  disableEndTime,
                )
              : !isValidReservationHour(
                  value,
                  disableStartTime,
                  disableEndTime,
                ),
          )}
        onSelectItem={handleSelectHour}
      />
      <Dropdown
        value={selectMinute === '' ? '-' : `${selectMinute}분`}
        isDisable={selectHour === ''}
        items={getMinuteDropdownItems()
          .map((value) => ({
            text: `${value}분`,
            value,
            isDisable: false,
          }))
          .filter(({ value }) =>
            disableStartTime === '' || !disableStartTime
              ? true
              : !(
                  getIsSameOrAfterTime(
                    `${selectHour}:${value}`,
                    disableStartTime,
                  ) &&
                  getIsSameOrBeforeTime(
                    `${selectHour}:${value}`,
                    disableEndTime,
                  )
                ),
          )}
        onSelectItem={handleSelectMinute}
      />
    </div>
  );
}

export default VetfluxTimeDropdown;
