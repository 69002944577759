import { captureException } from '@sentry/core';

export const doCopy = async (document, text) => {
  return new Promise((resolve, reject) => {
    if (navigator.clipboard) {
      // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          return resolve('success');
        })
        .catch((error) => {
          captureException(error);
          return reject(error);
        });
    } else {
      if (!document.queryCommandSupported('copy')) {
        return reject({});
      }
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.top = '0';
      textarea.style.left = '0';
      textarea.style.position = 'fixed';

      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      return resolve('success');
    }
  });
};
