export const getDayFromNumber = (dayNum: number) => {
  switch (Number(dayNum)) {
    case 0:
      return '일';
    case 1:
      return '월';
    case 2:
      return '화';
    case 3:
      return '수';
    case 4:
      return '목';
    case 5:
      return '금';
    case 6:
      return '토';
    default:
      return '';
  }
};

export const formatTime = (hour, minute) => {
  if (hour === undefined || minute === undefined) return ['00', '00'];
  const formattedHour = hour < 10 ? '0' + hour : '' + hour;
  const formattedMinute = minute < 10 ? '0' + minute : '' + minute;

  return [formattedHour, formattedMinute];
};

export const formatTimeToHourMinute = (hour, minute) => {
  const [formattedHour, formattedMinute] = formatTime(hour, minute);
  return `${formattedHour}:${formattedMinute}`;
};
