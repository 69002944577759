import React from 'react';

const SelectRadioButton = ({
  list,
  selectedValue,
  onClickEvent,
  readOnly = false,
  className = '',
}: {
  list: string[] | number[];
  selectedValue: string | number;
  onClickEvent: (event: React.MouseEvent<HTMLButtonElement>) => void;
  readOnly?: boolean;
  className?: string;
}) => {
  return (
    <div className={`w-full flex flex-wrap ${className}`}>
      {list.map((value, index) => {
        return (
          <div key={index}>
            <button
              data-index={index}
              data-value={value}
              className={`rounded-lg mr-4 mb-4 border max-h-[16rem] z-10 text-center p-[.875rem] ${
                selectedValue === value
                  ? ` ${
                      readOnly
                        ? 'bg-[#f6f8fe] text-neutral-dark'
                        : 'bg-white text-primary border-primary'
                    }`
                  : `text-neutral-light border-secondary-dark ${
                      readOnly ? '' : 'bg-white'
                    }`
              }`}
              onClick={(event) => {
                event.preventDefault();
                if (!readOnly) {
                  onClickEvent(event);
                }
              }}
            >
              {value}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default SelectRadioButton;
