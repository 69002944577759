import Image from 'next/image';
import React from 'react';

const ImageBox = ({ isLeft = true, imageUrl = '', onClick }) => {
  return (
    <>
      <li key={imageUrl} className='relative w-full'>
        <button onClick={onClick} className={`w-full block overflow-hidden`}>
          <Image
            loading='lazy'
            className={isLeft ? 'bg-[#f5f6fa]' : 'bg-[#f5f6fa]'}
            src={imageUrl}
            width={400}
            height={300}
            alt={''}
            layout='responsive'
            objectFit={'cover'}
            unoptimized={true}
            quality={1}
          />
        </button>
      </li>
    </>
  );
};
export default ImageBox;
