import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';

import * as LunarCalendar from '../utils/LunarCalendar';
export const getDayOfWeekName = (dayOfWeek) => {
  switch (dayOfWeek) {
    case 0:
      return '일';
    case 1:
      return '월';
    case 2:
      return '화';
    case 3:
      return '수';
    case 4:
      return '목';
    case 5:
      return '금';
    case 6:
      return '토';
  }
};
const holidays = [
  {
    name: '새해',
    date: {
      month: 1,
      dateOfMonth: 1,
      isLunar: false,
    },
    substituteHolidayList: undefined,
  },
  {
    name: '설날',
    date: {
      month: 1,
      dateOfMonth: 1,
      isLunar: true,
    },
    substituteHolidayList: [0],
  },
  {
    name: '3.1절',
    date: {
      month: 3,
      dateOfMonth: 1,
      isLunar: false,
    },
    substituteHolidayList: [6, 0],
  },
  {
    name: '부처님오신날',
    date: {
      month: 4,
      dateOfMonth: 8,
      isLunar: true,
    },
    substituteHolidayList: [6, 0],
  },
  {
    name: '어린이날',
    date: {
      month: 5,
      dateOfMonth: 5,
      isLunar: false,
    },
    substituteHolidayList: [6, 0],
  },
  {
    name: '현충일',
    date: {
      month: 6,
      dateOfMonth: 6,
      isLunar: false,
    },
    substituteHolidayList: undefined,
  },
  {
    name: '광복절',
    date: {
      month: 8,
      dateOfMonth: 15,
      isLunar: false,
    },
    substituteHolidayList: [6, 0],
  },
  {
    name: '추석',
    date: {
      month: 8,
      dateOfMonth: 15,
      isLunar: true,
    },
    substituteHolidayList: [0],
  },
  {
    name: '개천절',
    date: {
      month: 10,
      dateOfMonth: 3,
      isLunar: false,
    },
    substituteHolidayList: [6, 0],
  },
  {
    name: '한글날',
    date: {
      month: 10,
      dateOfMonth: 9,
      isLunar: false,
    },
    substituteHolidayList: [6, 0],
  },
  {
    name: '성탄절',
    date: {
      month: 12,
      dateOfMonth: 25,
      isLunar: false,
    },
    substituteHolidayList: [6, 0],
  },
];
export const getHolidayForMonthFromApi = async (
  year: number,
  month: number,
) => {
  return await axios
    .get(
      `/hospital/getHolidayForMonth?year=${year}&month=${
        month < 10 ? `0${month}` : month
      }`,
    )
    .then((response) => {
      return response.data;
    });
};
export const getHolidayForMonth = async (year: number, month: number) => {
  try {
    let result = [];
    const holidayListFromLocal = getHolidayForMonthFromLocal(year, month);
    result = result.concat(holidayListFromLocal);
    if (year > 2021 && year < 2025) {
      const cacheName = 'neulpet-date-holiday';
      const holidayListFromCache = sessionStorage.getItem(cacheName);
      const array = JSON.parse(holidayListFromCache);
      if (array) {
        const cacheList = array.filter((element) => {
          return element.year === year && element.month === month;
        });
        if (cacheList && cacheList.length > 0) {
          result = result.concat(cacheList);
        } else {
          const holidayListFromApi = await getHolidayForMonthFromApi(
            year,
            month,
          );
          sessionStorage.setItem(
            cacheName,
            JSON.stringify(array.concat(holidayListFromApi)),
          );
          result = result.concat(holidayListFromApi);
        }
      } else {
        const holidayListFromApi = await getHolidayForMonthFromApi(year, month);
        sessionStorage.setItem(cacheName, JSON.stringify(holidayListFromApi));
        result = result.concat(holidayListFromApi);
      }
    }
    return _.uniqWith(result, (pre, next) => {
      return (
        pre.year === next.year &&
        pre.month === next.month &&
        pre.dateOfMonth === next.dateOfMonth
      );
    });
  } catch (e) {
    console.log('Get Holiday Error ! :', e);
  }
};
export const getHolidayForMonthFromLocal = (year: number, month: number) => {
  //음력 확인 후 월 계산 추가
  const holidayList = holidays.filter((holiday) => {
    if (holiday.date.isLunar) {
      // const isLeapMonth = LunarCalendar.isLeapYear(year) && month === 2;
      const solarDate = LunarCalendar.getSolar(
        year,
        holiday.date.month,
        holiday.date.dateOfMonth,
      );
      return solarDate.solMonth === month;
    } else {
      return holiday.date.month === month;
    }
  });
  return holidayList.reduce((arr, holiday) => {
    if (holiday.date.isLunar) {
      // const isLeapMonth = LunarCalendar.isLeapYear(year) && month === 2;
      // console.log('isLeap', isLeapMonth);
      const solarDate = LunarCalendar.getSolar(
        year,
        holiday.date.month,
        holiday.date.dateOfMonth,
      );
      const data = {
        name: holiday.name,
        year: solarDate.solYear,
        month: solarDate.solMonth,
        dateOfMonth: solarDate.solDay,
        dayOfWeek: solarDate.dayOfWeekNum,
        dateString: `${solarDate.solYear}-${solarDate.solMonth}-${solarDate.solDay}`,
      };
      arr.push(data);
      if (holiday.name === '설날' || holiday.name === '추석') {
        const holidayDayjs = dayjs(
          `${data.year}-${data.month}-${data.dateOfMonth}`,
        );
        const preHoliday = holidayDayjs.subtract(1, 'd');
        const preHolidayData = {
          name: `${holiday.name} 연휴`,
          year: preHoliday.year(),
          month: preHoliday.month() + 1,
          dateOfMonth: preHoliday.date(),
          dayOfWeek: preHoliday.day(),
          dateString: preHoliday.format('YYYY-MM-DD'),
        };
        const nextHoliday = holidayDayjs.add(1, 'd');
        const nextHolidayData = {
          name: `${holiday.name} 연휴`,
          year: nextHoliday.year(),
          month: nextHoliday.month() + 1,
          dateOfMonth: nextHoliday.date(),
          dayOfWeek: nextHoliday.day(),
          dateString: nextHoliday.format('YYYY-MM-DD'),
        };
        arr.push(preHolidayData);
        arr.push(nextHolidayData);
      }
      return arr;
    }
    const date = dayjs(
      `${year}-${holiday.date.month}-${holiday.date.dateOfMonth}`,
    );
    const data = {
      name: holiday.name,
      year: year,
      month: holiday.date.month,
      dateOfMonth: holiday.date.dateOfMonth,
      dayOfWeek: date.day(),
      dateString: date.format('YYYY-MM-DD'),
    };
    arr.push(data);
    return arr;
  }, []);
};
